//= ./src/pages/PageConfigMessages.jsx

// eslint-disable-next-line

// import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
//
import { TableTemplate } from "../components/common/TableTemplate";
import { f_nr_to_padzero_string, fa_sleep_ms_promise, gen_colors } from "../lib/functions";

// - - - - - - = = = - - - - - -
const mod_name = "PageConfigMessages";
const func_name = `${mod_name}:anonymous`; // <== default, needs to be locally overwritten

// Icons =>        https://www.primefaces.org/primereact/icons/
// CSS =>          https://www.primefaces.org/primeflex/
// Componenets =>  https://www.primefaces.org/primereact/calendar/

export const PageConfigMessages = (props) => {
  //
  // - - - - - - = = = - - - - - -
  // LOCAL STATE:
  const [v_state_items, f_setstate_items] = useState([]); // useState(null);
  const [v_state_api_error, f_setstate_api_error] = useState("");
  //
  // LOCAL CONST:
  const api_base_url = window._env_.API_BASE_URL || "";
  const api_api_uri = "/";
  const api_resource_name = "configmessages";
  const api_url = api_base_url + api_api_uri + api_resource_name;
  //
  // EXPORTED MODEL:
  const model = {
    api_url: api_url,
    api_timeout_ms: 1000,
    api_resource_name: api_resource_name,
    item_name_singular: "configmessage",
    item_name_plural: "configmessages",
    table_title: "Manage Config Messages",
    dialog_title: "Config Message details",
    dialog_width: "650px",
    msg_empty: "No configmessage found.",
    msg_confirm_dialog_header: "Confirm",
    msg_are_you_sure_delete_single_item: "Are you sure you want to delete",
    msg_are_you_sure_delete_multi_item: "Are you sure you want to delete the selected configmessage?",
    fields: [
      {
        field: "id",
        label: "ID",
        dialog_type: "id",
        focus: false,
        table_type: "string",
        required: false,
        sortable: true,
        wrap: false,
      },
      {
        field: "time",
        label: "Time created",
        dialog_type: "string",
        focus: true,
        table_type: "epoch_to_nicedatetime",
        required: false,
        sortable: true,
        wrap: true,
      },
      {
        field: "anchors",
        label: "Target Anchor Set",
        dialog_type: "select-multiple-api-list",
        api_list_endpoint: api_base_url + api_api_uri + "v3/anchor?select=tpid,display_name",
        api_list_resource_name: "anchors",
        api_list_field_name: "tpid",
        api_list_field_desc: "display_name",
        api_list_field_key: "tpid",
        focus: false,
        // table_type: "array-string-space",
        table_type: "array-tpid",
        required: true,
        sortable: false,
        wrap: false,
      },
      {
        field: "configtype_id",
        label: "Config Type (hexstring)",
        dialog_type: "select-one-api-list",
        api_list_endpoint: api_base_url + api_api_uri + "configtypes",
        api_list_resource_name: "configtypes",
        api_list_field_name: "display_name",
        api_list_field_key: "id",
        focus: false,
        // table_type: "string",
        table_type: "object-path", // _.get(field_obj, 'key1.key2', 'optionalDefaultValue');
        object_path: "configtype.display_name",
        required: true,
        sortable: true,
        wrap: false,
      },
      {
        field: "time_status",
        label: "Time of Status Update",
        dialog_type: "string",
        focus: false,
        table_type: "epoch_to_nicedatetime",
        required: false,
        sortable: true,
        wrap: true,
      },
      {
        field: "status",
        label: "Message Send Status",
        dialog_type: "string",
        focus: false,
        table_type: "string",
        required: false,
        sortable: true,
        wrap: false,
      },
    ],
    table_fields_order: ["time", "anchors", "configtype_id", "time_status", "status"],
    dialog_fields_order: ["anchors", "configtype_id"],
    main_field: "configtype_id",
    initial_sort_field: "time",
    data_key: "id",
    rows: 10,
    rows_per_page_options: [5, 10, 25, 50, 100],
    operations: ["new", "edit", "delete"], // all options: 'new', 'view', 'edit', 'duplicate', 'delete' // NVEDD
    show_showid_button: true,
    show_openapi_button: true,
    show_refresh_button: true,
    refresh_button_label: "Reload Table",
    auto_refresh_secs: 0,
    auto_refresh_options: [0, 1, 3, 5, 10, 30, 60],
    toast_life_ms: 5000,
    new_item_template: {
      time: "",
    },
    image_path: "assets/demo/images/item",
    //
    // fa_model_get_items: undefined,
    // fa_model_set_item: undefined,
    //
  }; // \const model = {}

  // - - - - - - = = = - - - - - -
  // API logic:

  // const f_get_query_method_path_from_req = (req) => {
  //   // const parsed_url = url.parse(req.url, true);
  //   // const parsed_url = url.parse(req.originalUrl, true);
  //   // const path = parsed_url.path; // parsed_url.path =is_same_as?= req.url
  //   const path = req.url || "?";
  //   const method = req.method ? req.method.toUpperCase() : "?";
  //   const query_method_path = `${method} ${path}`;
  //   return query_method_path;
  // };

  // const f_get_err_message_from_axios_error = (error) => {
  //   let err_message = "Unknown error (code:PCDF)";
  //   if (error.response) {
  //     const data_response = (error.response.data && (error.response.data.response || error.response.data.error)) || "<None>";
  //     // err_message = `Error API status-code ${error.response.status}`;
  //     err_message = `Error API status-code ${error.response.status} response-msg: ${data_response}`;
  //   } else if (error.request) {
  //     const query_method_path = f_get_query_method_path_from_req(error.request);
  //     err_message = `No response from API on request ${query_method_path}`;
  //   } else {
  //     if (error.message) {
  //       err_message = error.message;
  //     }
  //   }
  //   return err_message;
  // };

  // const fa_model_get_items = async function () {
  //   //
  //   let get_items = undefined;
  //   //
  //   // THE .then() WAY:
  //   // axios
  //   //   .get(model.api_url, {}, { timeout: model.api_timeout_ms })
  //   //   .then((response) => {
  //   //     get_items = response.data && response.data[api_resource_name] ? response.data[api_resource_name] : null;
  //   //     f_setstate_items(get_items); // ==> this will cause a re-render, and the Controlled-Child-Component (CCC) is informed of 'v_state_items' via props : v_state_items={v_state_items}
  //   //   })
  //   //   .catch((err) => {});
  //   //
  //   // THE await WAY:
  //   try {
  //     //
  //     // await fa_sleep_ms_promise(2000);
  //     // throw new Error("test API failure");
  //     //
  //     const response = await axios.get(model.api_url, {}, { timeout: model.api_timeout_ms });
  //     get_items = response.data && response.data[api_resource_name] ? response.data[api_resource_name] : [];
  //     //
  //     f_setstate_items(get_items); // ==> this will cause a re-render, and the Controlled-Child-Component (CCC) is informed of 'v_state_items' via props : v_state_items={v_state_items}
  //     //
  //   } catch (error) {
  //     //
  //     // // - - - - - - = = = - - - - - -
  //     // // from: https://axios-http.com/docs/handling_errors
  //     // if (error.response) {
  //     //   // The request was made and the server responded with a status code that falls out of the range of 2xx
  //     //   console.log(error.response.data); // = body of HTTP-response
  //     //   console.log(error.response.status); // = HTTP status code
  //     //   console.log(error.response.headers);
  //     // } else if (error.request) {
  //     //   // The request was made but no response was received
  //     //   // `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
  //     //   // info: https://nodejs.org/api/http.html
  //     //   console.log(error.request);
  //     // } else {
  //     //   // Something happened in setting up the request that triggered an Error
  //     //   console.log("Error", error.message);
  //     // }
  //     // console.log(error.config);
  //     // console.log(error.toJSON()); // <== Using toJSON you get an object with more information about the HTTP error.
  //     // // - - - - - - = = = - - - - - -
  //     //
  //     // let err_message = "Unknown error (code:PCDF)";
  //     // if (error.response) {
  //     //   err_message = `Error API status-code ${error.response.status}`;
  //     // } else if (error.request) {
  //     //   const query_method_path = f_get_query_method_path_from_req(error.request);
  //     //   err_message = `No response from API on request ${query_method_path}`;
  //     // } else {
  //     //   if (error.message) {
  //     //     err_message = error.message;
  //     //   }
  //     // }
  //     const err_message = f_get_err_message_from_axios_error(error);
  //     //
  //     // throw new Error(f_setstate_api_error("Whoops"));
  //     throw new Error(err_message);
  //   }
  //   //
  // };

  // const fa_model_set_item = async function ({ action, item }) {
  //   const func_name = "fa_model_set_item";
  //   //
  //   let updated_item = undefined;
  //   // console.log(`${func_name} action=${action}`);
  //   //
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       let this_item = item || {};
  //       let response = undefined;
  //       let api_url_item_id = this_item && this_item.id ? model.api_url + "/" + this_item.id : "";
  //       switch (action) {
  //         case "new":
  //           response = await axios.post(model.api_url, this_item, { timeout: model.api_timeout_ms });
  //           break;
  //         case "update":
  //           if (!api_url_item_id) throw new Error("no ID present in item");
  //           response = await axios.put(api_url_item_id, this_item, { timeout: model.api_timeout_ms });
  //           break;
  //         case "deleteOne":
  //           if (!api_url_item_id) throw new Error("no ID present in item");
  //           response = await axios.delete(api_url_item_id, this_item, { timeout: model.api_timeout_ms });
  //           break;
  //         case "deleteMany":
  //           throw new Error("'deleteMany' not implemented");
  //         // response = await axios.post(model.api_url, item, { timeout: model.api_timeout_ms });
  //         // break;
  //         default:
  //           throw new Error("unknown action");
  //       }
  //       //
  //       await fa_model_get_items();
  //       //
  //       updated_item = response && response.data && response.data[api_resource_name] ? response.data[api_resource_name] : {};
  //       resolve(updated_item);
  //       //
  //     } catch (error) {
  //       const err_message = f_get_err_message_from_axios_error(error);
  //       // reject(err_message);
  //       reject(new Error(err_message));
  //     }
  //   });
  //   //
  // };

  // model.fa_model_get_items = fa_model_get_items;
  // model.fa_model_set_item = fa_model_set_item;

  // - - - - - - = = = - - - - - -
  // Render logic:
  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <TableTemplate v_state_items={v_state_items} f_setstate_items={f_setstate_items} model={model} v_state_api_error={v_state_api_error} />
        </div>
      </div>
    </div>
    // - - - - - - = = = - - - - - -
  ); // \return ()
  // - - - - - - = = = - - - - - -
};

//-eof
