//= pages/Tokens.js

// eslint-disable-next-line
import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import { Rating } from 'primereact/rating';
import { Button } from "primereact/button";
// import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";
// import { ProgressBar } from 'primereact/progressbar';
import { Checkbox } from "primereact/checkbox";

import axios from "axios"; // npm i axios --save
import momentjs from "moment"; // npm i moment --save

import { f_max_age_ms, f_get_max_age_both } from "../config/config";

const convert_seconds_to_DHMS = function (secs) {
  // DHMS = Days, Hourse, Minutes, Seconds
  // let seconds = parseInt(secs, 10); // in case of string
  let seconds = secs;
  let days = Math.floor(seconds / (3600 * 24));
  seconds -= days * 3600 * 24;
  let hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;
  let minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;
  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
};

function nr_to_padzero_string(nr, digits) {
  var str = "00000" + nr;
  return str.substr(-1 * digits);
}

const date_to_nicetime = (date) => {
  return momentjs(date).format("YYYY-MMM-DD HH:mm:ss");
};

let max_age = f_max_age_ms();

export const TokensPage = () => {
  // const api_url = "";
  // const api_url = ".";
  // const api_url = "http://ubuntu-vm-imac19.i.dgt-bv.com:8052";
  const api_url = window._env_.API_BASE_URL;
  //
  const url_anchors = api_url + "/anchors";
  const url_tokens = api_url + "/tokens";

  const [token_table, set_token_table] = useState(null);
  const [token_filter, set_token_filter] = useState("");
  const [token_loading, set_token_loading] = useState(true);
  const [token_show_id, set_token_show_id] = useState(false);
  const [token_checkbox_show_id, set_token_checkbox_show_id] = useState([]);

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/now
  // "returns the number of milliseconds elapsed since January 1, 1970 00:00:00 UTC."
  const [interval_time, set_interval_time] = useState(Date.now());
  useEffect(() => {
    //
    update_api_tokens();
    //
    const interval = setInterval(() => {
      set_interval_time(Date.now());
      update_api_tokens();
    }, 1000);
    //
    // to run a func at unmount, return a func here:
    return () => {
      clearInterval(interval);
    };
    //
    // eslint-disable-next-line
  }, []);

  //   useEffect(() => {
  //     //
  //     axios.get(url_anchors, {}, {timeout: 3000})
  //     .then(data => { set_anchor_table(data.data.anchors); set_anchor_loading(false); })
  //     .catch(error => { set_anchor_table(null); set_anchor_loading(false) });
  //     //
  //     axios.get(url_tokens, {}, {timeout: 3000})
  //     .then(data => { set_token_table(data.data.tokens); set_token_loading(false); })
  //     .catch(error => { set_token_table(null); set_token_loading(false) });
  //     //
  //     axios.get(url_timepoints, {}, {timeout: 3000})
  //     .then(data => { set_timepoint_table(data.data.timepoints); set_timepoint_loading(false); })
  //     .catch(error => { set_timepoint_table(null); set_timepoint_loading(false) });
  //     //
  //     axios.get(url_maps, {}, {timeout: 3000})
  //     .then(data => { set_map_table(data.data.maps); set_map_loading(false); })
  //     .catch(error => { set_map_table(null); set_map_loading(false) });
  //     //
  // }, []);

  // =======================================================================================
  const update_api_tokens = () => {
    // set_anchor_loading(true);
    axios
      .get(url_tokens, {}, { timeout: 3000 })
      .then((data) => {
        const tokens = data.data.tokens || [];
        set_token_table(tokens);
        set_token_loading(false);
      })
      .catch((err) => {
        set_token_table(null);
        set_token_loading(false);
      });
  };
  const [token_sort_field, set_token_sort_field] = useState("token_id");
  const [token_sort_order, set_token_sort_order] = useState(1);
  const on_token_sort = (e) => {
    // set_token_sort_field(e.token_sort_field);
    // set_token_sort_order(e.token_sort_order);
  };
  const click_show_api_tokens = () => {
    window.open(url_tokens);
  };
  const click_refresh_api_tokens = () => {
    axios
      .get(url_tokens, {}, { timeout: 3000 })
      .then((data) => {
        set_token_table(data.data.tokens);
        set_token_loading(false);
      })
      .catch((error) => {
        set_token_table(null);
        set_token_loading(false);
      });
  };
  const token_on_checkbox_showid_change = (e) => {
    let selectedValue = [...token_checkbox_show_id];
    if (e.checked) selectedValue.push(e.value);
    else selectedValue.splice(selectedValue.indexOf(e.value), 1);
    //
    set_token_checkbox_show_id(selectedValue);
    set_token_show_id(selectedValue.indexOf("true") !== -1);
  };

  //
  const token_table_header = (
    <div className="table-header">
      <h5>Tokens</h5>
      <span className="">Last API update: {date_to_nicetime(interval_time)}</span>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={token_filter} onChange={(e) => set_token_filter(e.target.value)} placeholder="Global Search" />
      </span>
    </div>
  );

  // =======================================================================================

  const body_template = (data, props) => {
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {data[props.field]}
      </>
    );
  };

  const uptime_template = (data, props) => {
    const uptime = data[props.field];
    const time = data["time"];
    let time_date_obj = new Date(time * 1000);
    let date_now = Date.now();
    let result_string = "";
    const is_not_too_old = time_date_obj > date_now - max_age;
    if (time_date_obj > date_now - max_age) {
      let DHMS = convert_seconds_to_DHMS(uptime);
      const niceUptime = `${DHMS.days} days, ${nr_to_padzero_string(DHMS.hours, 2)}:${nr_to_padzero_string(DHMS.minutes, 2)}:${nr_to_padzero_string(DHMS.seconds, 2)}`;
      result_string = niceUptime;
    } else {
      result_string = "(down)";
    }
    if (data["_uplink_tech"] === "lorawan") {
      result_string = "-";
    }
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {result_string}
      </>
    );
  };

  const reptime_template = (data, props) => {
    let result_string = "";
    //
    const time = data.time || 0;
    let time_date_obj = new Date(time * 1000);
    let date_now = Date.now();
    const is_not_too_old = time_date_obj > date_now - max_age;
    //
    let nice_time = date_to_nicetime(time_date_obj);
    result_string = nice_time;
    //
    if (false) {
      const data_hex = data["_anchor_hextime"];
      if (data_hex) {
        result_string = nice_time + " (" + time + "s, 0x" + data_hex + ")";
      } else {
        result_string = nice_time + " (" + time + "s)";
      }
    }
    //
    if (!is_not_too_old) {
      result_string = result_string + " (old)";
    }
    //
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {result_string}
      </>
    );
  };

  const token_time_template = (data, props) => {
    const time = data[props.field];
    let time_date_obj = new Date(time * 1000);
    let result_string = "";
    let nice_time = date_to_nicetime(time_date_obj);
    result_string = nice_time;
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {result_string}
      </>
    );
  };

  const tokenlist_template = (data, props) => {
    const presence_tlist = data[props.field];
    const time = data["time"];
    let time_date_obj = new Date(time * 1000);
    let date_now = Date.now();
    let result_string = "";
    if (time_date_obj > date_now - max_age) {
      let token_list_str = presence_tlist.join(",");
      result_string = token_list_str;
    } else {
      result_string = "-";
    }
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {result_string}
      </>
    );
  };

  const location_template = (data, props) => {
    let anchor_list_str = "";
    const time = data.time || 0;
    let time_date_obj = new Date(time * 1000);
    let date_now = Date.now();
    const is_not_too_old = time_date_obj > date_now - max_age;
    //
    if (is_not_too_old) {
      const presence_alist = data.location.presence_alist;
      const t_time = data.time || 0;
      const a_list = presence_alist.filter((obj) => obj.time >= t_time).map((obj) => obj.anchor_id);
      // const tokens = presence_tlist.find(obj => obj.time == time).tokens;
      // let anchor_list_str = "Anchors:" + presence_alist.join(", ");
      // let anchor_list_str = "Anchors:" + a_list.join(", ");
      anchor_list_str = a_list.join(", ");
    } else {
      anchor_list_str = "-";
    }
    //
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {anchor_list_str}
      </>
    );
  };

  const fixes_template = (data, props) => {
    const fixes = data.fixes;
    const t_time = data.time;
    let fixes_list = [];
    fixes.forEach((element) => {
      const tid = element.token_id;
      const presence_alist = element.location.presence_alist;
      const a_list = presence_alist.filter((obj) => obj.time >= t_time).map((obj) => obj.anchor_id);
      let new_fix_str = "";
      if (a_list.length > 0) {
        new_fix_str = `T:${tid}->Anchors:` + a_list.join(",");
      } else {
        new_fix_str = `T:${tid}->Gone`;
      }
      fixes_list.push(new_fix_str);
    });
    const fixes_str = fixes_list.join("; ");
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {fixes_str}
      </>
    );
  };

  // _uplink_tech:    { type: String,   required: false},
  // _uplink_prov:    { type: String,   required: false},
  // _lw_framecount:  { type: Number,   required: false},
  // _lw_timestamp:   { type: String,   required: false},
  // _lw_rssi:        { type: Number,   required: false},
  // _lw_lorasnr:     { type: Number,   required: false},

  return (
    <div className="grid table-demo">
      <div className="col-12">
        <div className="card">
          <DataTable
            className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
            header={token_table_header}
            value={token_table}
            dataKey="_id"
            loading={token_loading}
            emptyMessage="No items found."
            globalFilter={token_filter}
            sortField={token_sort_field}
            sortOrder={token_sort_order}
            onSort={on_token_sort}
            // paginator rows={24}
            rowHover
            size="small"
            // autoLayout="true"
            // resizableColumns
          >
            {token_show_id && <Column field="_id" header="ID" sortable body={body_template}></Column>}
            <Column field="token_id" header="Token-ID" sortable body={body_template}></Column>
            <Column field="_bpl" header="Battery" sortable body={body_template}></Column>
            <Column field="time" header="Latest report" sortable body={reptime_template}></Column>
            <Column field="location" header="Location" sortable body={location_template}></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
}; // \export const OverviewPage = () => {

//-EOF
