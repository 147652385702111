//= ./src/components/TableAnchor.jsx

/* 
// Usage: examples of how we are called:
<TableAnchor show_items_limit="recent" show_columns="only_graph"   />
<TableAnchor show_items_limit="recent" show_columns="only_details" /> 
<TableAnchor show_items_limit="all"                                />
<TableAnchor show_items_limit="all"    show_columns="only_edit"    show_live="false" {...props} />
*/

import React, { useState, Component, useContext, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable"; // https://www.primefaces.org/primereact/datatable/
import { Column } from "primereact/column";
// import { Rating } from "primereact/rating";
import { Button } from "primereact/button"; // https://www.primefaces.org/primereact/button/
// import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
// import { ProgressBar } from "primereact/progressbar";
// import { Checkbox } from "primereact/checkbox";
import { Chip } from "primereact/chip";

// import { CrudTable } from "../CrudTable";

import axios from "axios"; // npm i axios --save
import moment_js, { normalizeUnits } from "moment"; // npm i moment --save

import { f_max_age_ms, f_get_max_age_both } from "../config/config";

import { f_convert_selected_sensors_to_display_string, f_convert_sensors_to_display_string, f_get_epoch_now, f_is_object, f_js, f_tpid_is_anchor, getColorFromNumber } from "../lib/functions";
import TokenPill from "./TokenPill";

const max_age = f_max_age_ms();
const { max_age_anchor, max_age_token } = f_get_max_age_both();

const TableAnchor = (props) => {
  //

  const [anchor_table, set_anchor_table] = useState(null);
  const [anchor_filter, set_anchor_filter] = useState("");
  const [anchor_loading, set_anchor_loading] = useState(true);
  const [anchor_show_id, set_anchor_show_id] = useState(false);
  const [anchor_checkbox_show_id, set_anchor_checkbox_show_id] = useState([]);
  // Date.now() // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/now // "returns the number of milliseconds elapsed since January 1, 1970 00:00:00 UTC."
  const [interval_time, set_interval_time] = useState(Date.now());
  const [sortField, setSortField] = useState("tpid");
  const [sortOrder, setSortOrder] = useState(1);
  const [v_state_show_columns_graph, f_setstate_show_columns_graph] = useState(true);
  const [v_state_show_columns_details, f_setstate_show_columns_details] = useState(true);
  const [v_state_show_columns_all, f_setstate_show_columns_all] = useState(true);
  const [v_state_show_columns_edit, f_setstate_show_columns_edit] = useState(true);

  const ref_datatable = useRef(null);

  useEffect(() => {
    //
    // f_ui_process_props_show_columns();
    switch (props.show_columns) {
      case "only_graph":
        f_setstate_show_columns_graph(true);
        f_setstate_show_columns_details(false);
        f_setstate_show_columns_edit(false);
        f_setstate_show_columns_all(false);
        break;
      case "only_details":
        f_setstate_show_columns_graph(false);
        f_setstate_show_columns_details(true);
        f_setstate_show_columns_edit(false);
        f_setstate_show_columns_all(false);
        break;
      case "only_edit":
        f_setstate_show_columns_graph(false);
        f_setstate_show_columns_details(false);
        f_setstate_show_columns_edit(true);
        f_setstate_show_columns_all(false);
        break;
      case "show_all":
      default:
        f_setstate_show_columns_graph(false);
        f_setstate_show_columns_details(false);
        f_setstate_show_columns_edit(false);
        f_setstate_show_columns_all(true);
        break;
    }
    //
    //update_api_anchors();
    //
    // const interval = setInterval(() => {
    //   set_interval_time(Date.now());
    //   clearInterval(interval);
    //   update_api_anchors();
    // }, update_interval_s * 1000);
    concurrentCalls();
    //
    // return a function that is to be run at 'componentWillUnmount':
    return () => {
      console.log("component unmount")
      clearInterval(interval); // undo 'setInterval()'
    };
    //
    // eslint-disable-next-line
  }, []);
  const  concurrentCalls =() =>{
    var  interval=   setInterval(() => {
        set_interval_time(Date.now());
        clearInterval(interval);
        setTimeout(function() {
   
          update_api_anchors();
        }, 2000);
      }, update_interval_s * 1000);
    }
  const date_to_nicetime = (date) => {
    return moment_js(date).format("YYYY-MMM-DD/HH:mm:ss");
  };

  // const api_url = "";
  // const api_url = ".";
  // const api_url = "http://ubuntu-vm-imac19.i.dgt-bv.com:8052";
  const api_url = window._env_.API_BASE_URL;
  //
  // /api/reports?start=1646033418&end=1646934419&limit=1230&ffield=tpid&fvalue=anchor-a71&select=_lw_rssi_0
  const url_reports = api_url + "/api/reports";
  //
  const url_anchors = api_url + "/anchors";
  // const url_anchors_v3 = api_url + "/api/anchor";
  const url_anchors_v3 = api_url + "/anchors";
  // const url_tokens = api_url + "/api/tokens";
  const url_tokens = api_url + "/api/tokens";
  const url_timepoints = api_url + "/api/timepoints";
  const url_maps = api_url + "/api/maps";
  // url_anchors, url_tokens, url_timepoints, url_maps

  let update_interval_s = 3;

  const update_api_anchors = () => {
    // set_anchor_loading(true);
    axios
      .get(url_anchors_v3, {}, { timeout: 3000 })
      .then((data) => {
        let anchors = data.data && data.data.anchors ? data.data.anchors : null;
        //
        const filter_non_tpid = true;
        if (filter_non_tpid) {
          const anchors_non_tpid = anchors.filter((item) => {
            return f_tpid_is_anchor(item.tpid);
          });
          anchors = anchors_non_tpid;
        }
        //
        if (props.show_items_limit === "recent") {
          const anchors_new = anchors.filter((item) => {
            // let time_data = new Date(item.time * 1000);
            // let time_now = Date.now();
            // const is_old = time_data < time_now - max_age * 2;
            const time_now = f_get_epoch_now();
            const is_old = item.time < time_now - max_age_anchor * 2;
            return !is_old;
          });
          anchors = anchors_new;
        }
        //
        const add_key_copy_id = true;
        if (add_key_copy_id) {
          let count = 0;
          const add_key_anchors = anchors.map((item) => {
            count += 1;
            // item.key = item._id || item.id || count;
            item.key = count;
            return item;
          });
          anchors = add_key_anchors;
        }
        //
        set_anchor_table(anchors);
        set_anchor_loading(false);
        concurrentCalls();
      })
      .catch((err) => {
        set_anchor_table(null);
        set_anchor_loading(false);
        concurrentCalls();
      });
  };

  const f_ui_open_api = () => {
    window.open(url_anchors_v3);
  };

  const anchor_table_header = () => {
    // const time_now = f_get_epoch_now();
    const last_api_update_str = `Last API update: ${date_to_nicetime(interval_time)} (max_age_anchor=${max_age_anchor}s)`;
    return (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        {/* <div className="table-header"> */}
        {v_state_show_columns_all && <h5 className="m-0">Anchors</h5>}
        {v_state_show_columns_details && <h5 className="m-0">Anchors - Sensors</h5>}
        {v_state_show_columns_graph && <h5 className="m-0">Anchors - Token-list</h5>}
        {v_state_show_columns_edit && <h5 className="m-0">Anchors - View & Edit</h5>}

        <div className="my-2">
          {/* <div className="field-checkbox">
            <Checkbox inputId="checkShowIDs_anchors" name="option" value="true" checked={anchor_checkbox_show_id.indexOf('true') !== -1} onChange={anchor_on_checkbox_showid_change} />
            <label htmlFor="checkShowIDs_anchors">show IDs</label>
        </div> */}
          {/* <Button label="/api/anchors" onClick={click_show_api_anchors} className="p-button-raised p-button-warning mr-2 mb-2" /> */}
          {/* <Button label="refresh" onClick={update_api_anchors} className="p-button-raised p-button-warning mr-2 mb-2" /> */}

          {props.show_live !== "false" && (
            <Chip className="my-2 mr-2" label={last_api_update_str} />
            // <span className="mono">
            //   {/* Last API update: {date_to_nicetime(interval_time)} ({Math.round(time_now - interval_time / 1000)} secs ago) */}
            //   Last API update: {date_to_nicetime(interval_time)} (max_age_anchor={max_age_anchor}s)
            // </span>
          )}

          <Button label="Open API" icon="pi pi-external-link" onClick={f_ui_open_api} className="p-button-sm p-button-warning mr-2 my-2" />
        </div>

        {/* <span className="p-input-icon-left"> */}
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText value={anchor_filter} onChange={(e) => set_anchor_filter(e.target.value)} placeholder="Global Search" />
        </span>
      </div>
    );
  };
  // JDG-Todo: replace this with lodash.get()
  const f_object_lookup_default = function ({ obj, field, dflt }) {
    const ret_value = f_is_object(obj) && obj.hasOwnProperty(field) ? obj[field] : dflt;
    return ret_value;
  };

  const body_template = (data, props) => {
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {f_object_lookup_default({ obj: data, field: props.field, dflt: "" })}
      </>
    );
  };

  const member_template = (data, props) => {
    const my_list = f_object_lookup_default({ obj: data, field: props.field, dflt: [] });
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {my_list.map((tpid) => {
          // const hexstr = tid.split("-")[1];
          // return <TokenPill hexstr={hexstr} />;
          return <TokenPill tpid={tpid} />;
        })}
      </>
    );
  };

  const decAid_template = (data, props) =>{
    console.log();
    const deviceId = String(parseInt(data.tpid.split('-')[1].substring(1,data.tpid.split('-')[1].length),16)).padStart(3,'0')
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {deviceId}
      </>
    );
  }

  const display_name_template = (data, props) =>{
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {data.display_name}
      </>
    );
  }
  const lw_freq_template = (data, props) => {
    // const field = props.field;
    // const field = "lw";
    // const data_obj = f_object_lookup_default({ obj: data, field: field, dflt: {} });
    // const data_obj = data.aux && data.aux.lw ? data.aux.lw : {};
    const data_obj = data.sensors && data.sensors.lw && data.sensors.lw.value ? data.sensors.lw.value : {};
    //
    // const bandwidth = data_obj.bandwidth || "undef";
    // const spreading_factor = data_obj.spreading_factor || "undef";
    const bandwidth = data_obj.bandwidth || undefined;
    const spreading_factor = data_obj.spreading_factor || undefined;
    //
    const fcnt_cur = data_obj.framecount;
    const fcnt_prev = data_obj.f_cnt_prev;
    //
    const time = data.time || 0;
    const time_prev = data_obj.time_prev;
    //
    if (!bandwidth || !spreading_factor) {
      return <></>;
    }
    let data_formatted = `bw=${bandwidth} sf=${spreading_factor}`;
    if (fcnt_cur && fcnt_prev && time_prev) {
      const str1 = `bw=${bandwidth} sf=${spreading_factor}`;
      // const str2 = `fc=${fcnt_cur},prev=${fcnt_prev}`;
      const missed = fcnt_cur - fcnt_prev - 1;
      const str2 = missed ? `fc=${fcnt_cur} missed=${missed}` : `fc=${fcnt_cur}`;
      const str3 = `time_prev=${time - time_prev}s`;
      data_formatted = (
        <>
          {str1} <br /> {str2} <br /> {str3}
        </>
      );
    }
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {data_formatted}
      </>
    );
  };

  const lw_gw_template = (data, props) => {
    // const field = props.field;
    // const field = "lw";
    // const data_obj = f_object_lookup_default({ obj: data, field: field, dflt: {} });
    // const data_obj = data.aux && data.aux.lw ? data.aux.lw : {};
    const data_obj = data.sensors && data.sensors.lw && data.sensors.lw.value ? data.sensors.lw.value : {};
    //
    const gw_obj_list = data_obj.gateways || [];
    const gw_list = gw_obj_list.map((obj) => `gw_id=${obj.gateway_id} rssi=${obj.rssi} snr=${obj.snr}`);
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {gw_list.map((obj) => {
          return (
            <>
              {obj} <br />
            </>
          );
        })}
      </>
    );
  };

  const repstring_template = (data, props) => {
    const repstring = f_object_lookup_default({ obj: data, field: "repstring", dflt: "" });
    const repstring_punc = f_object_lookup_default({ obj: data, field: "repstring_punc", dflt: repstring });
    // let display_string = f_object_lookup_default({ obj: data, field: props.field, dflt: "" });
    let display_string = repstring_punc;
    //
    const rep_obj = f_object_lookup_default({ obj: data, field: "rep_obj", dflt: {} });
    if (rep_obj.ver) display_string += ` ver=${rep_obj.ver}`;
    if (rep_obj.typ) display_string += ` type=${rep_obj.typ}`;
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {display_string}
      </>
    );
  };

  const reptime_template = (data, props) => {
    const time = data.time || 0;
    const time_now = f_get_epoch_now();
    const data_time_str = data.time_str;
    const time_diff = time_now - time;
    const top_str = `${time} (${time_diff}s)`;
    const bot_str = `${data_time_str}`;
    const data_formatted = (
      <>
        {top_str} <br /> {bot_str}
      </>
    );
    const is_old = time < time_now - max_age_anchor * 1;
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        <span className={is_old ? "span_grey" : "span_bold_blue"}>{data_formatted}</span>
      </>
    );
  };

  const anchor_template = (data, props) => {
    const tpid = f_object_lookup_default({ obj: data, field: "tpid", dflt: {} });
    
    return (
      <p>
        <TokenPill tpid={tpid} />
      </p>
    );
    //
  };

  const tokens_template = (data, props) => {
    const tokens = f_object_lookup_default({ obj: data, field: "tokens", dflt: {} });
    let token_ids = Object.keys(tokens);
    //
    const time_now = f_get_epoch_now();
    token_ids = token_ids.filter((tok) => tokens[tok].time > time_now - max_age_token);
    //
    token_ids.sort(); // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
    //
    // const color = getColorFromNumber(hexnr);
    //
    // let result_string = token_ids.join(",");
    // return (
    //   <>
    //     <span className="p-column-title">{props.header}</span>
    //     {result_string}
    //   </>
    // );
    //
    // const some = (
    //   <svg>
    //     <g transform="translate(20,20)">
    //       <circle
    //         cx="0"
    //         cy="0"
    //         r="20"
    //         fill={color}
    //       />
    //       <text
    //         x="-8"
    //         y="5"
    //         stroke="white"
    //         fill="white"
    //         className="mono"
    //       >
    //         {20}
    //       </text>
    //     </g>
    //   </svg>
    // );
    //
    // const some2 = (
    //   <span
    //     type="button"
    //     class="btn btn-primary btn-sm"
    //     style={{
    //       padding: "2px 4px",
    //       border: `2px solid ${color}`,
    //       borderRadius: "6px",
    //       color: "white",
    //       backgroundColor: color,
    //       fontSize: "16px",
    //       fontFamily: "monospace",
    //       fontWeight: "bold",
    //       marginRight: "6px",
    //     }}
    //   >
    //     {`T-${hexnr}`}
    //   </span>
    // );
    // return (
    //   <>
    //     <span className="p-column-title">{props.header}</span>
    //     {some2}
    //   </>
    // );
    //
    return (
      <p>
        {token_ids.map((tpid) => {
          // const hexstr = tid.split("-")[1];
          // return <TokenPill hexstr={hexstr} />;
          return <TokenPill tpid={tpid} />;
        })}
      </p>
    );

    //
  };

  const sensors_template = (data, props) => {
    const sensors = f_object_lookup_default({ obj: data, field: "sensors", dflt: {} });
    //
    // let result_string = f_convert_selected_sensors_to_display_string({ sensors }); // wens Hans
    // let result_string = f_convert_sensors_to_display_string({ sensors, time: data.time, time_str: data.time_str });
    let result_string = f_convert_sensors_to_display_string({ sensors });
    //
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {result_string}
      </>
    );
  };

  const cdf_template = (data, props) => {
    const cdf_obj = f_object_lookup_default({ obj: data, field: "cdf", dflt: {} });
    //
    // let result_string = f_convert_selected_sensors_to_display_string({ sensors }); // wens Hans
    let result_string = JSON.stringify(cdf_obj);
    // result_string = result_string.replace(":", ":: ");
    // result_string = result_string.replace(",", ",, ");
    result_string = result_string.replace(/([:,])/g, "$1 ");
    //
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {result_string}
      </>
    );
  };

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
  };

  // let v_state_show_columns_graph = true;
  // let v_state_show_columns_details = true;
  function f_ui_process_props_show_columns() {
    switch (props.show_columns) {
      case "only_graph":
        f_setstate_show_columns_details(false);
        break;
      case "only_details":
        f_setstate_show_columns_graph(false);
        break;
      case "show_all":
      default:
        break;
    }
  }

  const f_ui_edit_item = (rowData) => {
    //
    const tpid = rowData.tpid || undefined;
    const pathname = (props.location && props.location.pathname) || "";
    if (tpid && pathname) {
      //
      props.history.push(`${pathname}/${tpid}`);
    }
  };

  const f_ui_action_body_template = (rowData) => {
    return (
      <div className="actions">
        {v_state_show_columns_edit && <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => f_ui_edit_item(rowData)} tooltip="Edit" tooltipOptions={{ position: "top" }} />}
      </div>
    );
  };

  return (
    //   {/* https://www.primefaces.org/primereact/datatable/ */}
    <DataTable
      ref={ref_datatable}
      className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
      header={anchor_table_header}
      value={anchor_table}
      dataKey="key"
      loading={anchor_loading}
      emptyMessage="No items found."
      globalFilter={anchor_filter}
      // globalFilterFields={["id", "tpid", "display_name", "lw_freq", "lw_gw", "time_str", "repstring", "tokens", "sensors"]}
      globalFilterMatchMode={"contains"}
      // scrollable scrollHeight="600px"
      //
      sortField={sortField}
      sortOrder={sortOrder}
      onSort={onSort}
      //
      // sortMode="multiple"
      // multiSortMeta={multiSortMeta} onSort={(e) => setMultiSortMeta(e.multiSortMeta)}
      //
      // paginator rows={24}
      rowHover
      // showGridlines={true} // <== jdg:already set with: className="p-datatable-gridlines"
      size="small"
      // columnResizeMode="fit"
      autoLayout="true"
      resizableColumns
      //
    >
      {anchor_show_id && (v_state_show_columns_all || v_state_show_columns_details || v_state_show_columns_edit) && <Column field="id" header="ID" sortable body={body_template} />}
      {/*  */}
      <Column field="tpid" header="Anchor-ID" sortable body={anchor_template} />
      {/*  */}
      {
      (v_state_show_columns_all || v_state_show_columns_graph || v_state_show_columns_edit) && 
      <Column field="tpid" header="DecAID" 
      sortable body={decAid_template} style={{ whiteSpace: "normal" }} 
      
      />}
       {
      (v_state_show_columns_all || v_state_show_columns_graph || v_state_show_columns_edit) && 
      <Column field="display_name" header="Display Name" 
      sortable body={display_name_template} style={{ whiteSpace: "normal" }} 
      
      />}
      
       {/*  */}
       {(v_state_show_columns_all || v_state_show_columns_edit) && <Column field="member_of" header="Member of" sortable body={member_template} style={{ whiteSpace: "normal" }} />}
      {/* <Column
          field="_uplink_tech"
          header="Uplink:Tech"
          sortable
          body={body_template}
        />
        <Column
          field="_uplink_prov"
          header="Uplink:Prov"
          sortable
          body={body_template}
        /> */}
      {
          /* <Column
          field="_uplink_health"
          header="Uplink:Health"
          sortable
          body={body_template}
          // style={{ whiteSpace: "normal" }} // undo:App.scss : .p-datatable-tbody { white-space: nowrap;}
           /> */
        }
      {/* // */}
      {(v_state_show_columns_all || v_state_show_columns_graph || v_state_show_columns_edit) && (
        <Column
          field="time_str"
          header="Last update"
          sortable
          body={reptime_template}
          // style={{ whiteSpace: "normal" }}
        />
      )}
      {/* // */}
      {(v_state_show_columns_all || v_state_show_columns_details) && (
        <Column
          // field="time"
          field="lw_freq"
          header="LoraWAN freq"
          sortable
          body={lw_freq_template}
          style={{ whiteSpace: "normal" }}
          // visible={v_state_show_columns_details}
        />
      )}
      {v_state_show_columns_all ||
        (v_state_show_columns_details && (
          <Column
            // field="time"
            field="lw_gw"
            header="LoraWAN GWs"
            sortable
            body={lw_gw_template}
            style={{ whiteSpace: "normal" }}
            // visible={v_state_show_columns_details}
          />
        ))}
      {/* // */}
      {/* <Column
          field="_anchor_uptime"
          header="Anchor Uptime"
          sortable
          body={uptime_template}
        /> */}
      {(v_state_show_columns_all || v_state_show_columns_details) && (
        <Column
          field="repstring"
          // field="repstring_punc"
          header="Repstring"
          sortable
          body={repstring_template}
          // visible={v_state_show_columns_details}
          style={{ whiteSpace: "normal" }}
        />
      )}
      {(v_state_show_columns_all || v_state_show_columns_graph) && (
        <Column
          field="tokens"
          header="Tokens"
          sortable
          body={tokens_template}
          // ./src/App.scss:.p-datatable-tbody:white-space: nowrap;
          style={{ width: "80%", whiteSpace: "normal" }}
          // visible={v_state_show_columns_graph}
        />
      )}
      {(v_state_show_columns_all || v_state_show_columns_details) && (
        <Column
          field="sensors"
          header="Sensors"
          sortable
          // filter
          body={sensors_template}
          style={{ width: "60%", whiteSpace: "normal" }}
          // visible={v_state_show_columns_details}
        />
      )}
      {(v_state_show_columns_all || v_state_show_columns_details) && (
        <Column
          field="cdf"
          header="cdfuncs"
          sortable
          // filter
          body={cdf_template}
          style={{ width: "60%", whiteSpace: "normal" }}
          // visible={v_state_show_columns_details}
        />
      )}
      {/* - - - - - - = = = - - - - - - */}
      {v_state_show_columns_edit && <Column header="Action" body={f_ui_action_body_template} />}
      {/* - - - - - - = = = - - - - - - */}
    </DataTable>

    // /* - - - - - - = = = - - - - - - */
    // /* <CrudTable meta_prop={meta_prop} /> */
    // /* - - - - - - = = = - - - - - - */
  );
};

export default TableAnchor;

//-eof
