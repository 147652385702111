//= AppTopbar.js

// import React  from 'react';
// import React, { Component } from 'react';
import React, { Component, useState, useContext, useCallback, useEffect, useRef } from "react";

// import { Link } from 'react-router-dom';
import classNames from "classnames";
import { Tag } from "primereact/tag";
import { Menu } from "primereact/menu";
import { Chip } from "primereact/chip";

import ApiStatus from "./components/ApiStatus"; // ./components/ApiStatus
import { f_date_to_nicedatetime, f_date_to_nicetime, f_date_to_nicetime_str } from "./lib/functions";
import floorplan from "./layout/images/KYP-Project-logo-universeel.png";
// import {SocketContext} from './lib/socket_io_context';

// import { useState } from 'react';
// import { observer } from 'mobx-react-lite';

// import { AppConfig } from './AppConfig';
// JDG: get func as method: AppConfig.toggleConfigurator()

// import React, {useState, useContext, useCallback, useEffect} from 'react';
// import {SocketContext} from 'context/socket';
// const GrandChild = ({userId}) => {
//   const socket = useContext(SocketContext);
//   const [joined, setJoined] = useState(false);
//   const handleInviteAccepted = useCallback(() => {
//     setJoined(true);
//   }, []);
//   const handleJoinChat = useCallback(() => {
//     socket.emit("SEND_JOIN_REQUEST");
//   }, []);
//   useEffect(() => {
//     // as soon as the component is mounted, do the following tasks:

//     // emit USER_ONLINE event
//     socket.emit("USER_ONLINE", userId);

//     // subscribe to socket events
//     socket.on("JOIN_REQUEST_ACCEPTED", handleInviteAccepted);

//     return () => {
//       // before the component is destroyed
//       // unbind all event handlers used in this component
//       socket.off("JOIN_REQUEST_ACCEPTED", handleInviteAccepted);
//     };
//   }, [socket, userId, handleInviteAccepted]);
//   return (
//     <div>
//       { joined ? (
//         <p>Click the button to send a request to join chat!</p>
//       ) : (
//         <p>Congratulations! You are accepted to join chat!</p>
//       ) }
//       <button onClick={handleJoinChat}>
//         Join Chat
//       </button>
//     </div>
//   );
// };

// // https://dev.to/bravemaster619/how-to-use-socket-io-client-correctly-in-react-app-o65
// import React, {useState, useContext, useCallback, useEffect} from 'react';
// import {SocketContext} from 'context/socket';
// const GrandChild = ({userId}) => {
//   const socket = useContext(SocketContext);
//   const [joined, setJoined] = useState(false);
//   const handleInviteAccepted = useCallback(() => {
//     setJoined(true);
//   }, []);
//   const handleJoinChat = useCallback(() => {
//     socket.emit("SEND_JOIN_REQUEST");
//   }, []);
//   useEffect(() => {
//     // as soon as the component is mounted, do the following tasks:
//     // emit USER_ONLINE event
//     socket.emit("USER_ONLINE", userId);
//     // subscribe to socket events
//     socket.on("JOIN_REQUEST_ACCEPTED", handleInviteAccepted);
//     return () => {
//       // before the component is destroyed
//       // unbind all event handlers used in this component
//       socket.off("JOIN_REQUEST_ACCEPTED", handleInviteAccepted);
//     };
//   }, [socket, userId, handleInviteAccepted]);
//   return (
//     <div>
//       { joined ? (
//         <p>Click the button to send a request to join chat!</p>
//       ) : (
//         <p>Congratulations! You are accepted to join chat!</p>
//       ) }
//       <button onClick={handleJoinChat}>
//         Join Chat
//       </button>
//     </div>
//   );
// };

// class AppTopbar extends Component {
//   // state = {  }
//   // componentDidMount = () => {
//   //   this.socket_io = useContext(SocketContext);
//   // };
//   render() {
//     return (
//       <div className="layout-topbar X_red_border">
//           {/* <Link to="/" className="layout-topbar-logo"> */}
//           <a href="./" className="layout-topbar-logo">

//             {/* <img src=(((this.props.layoutColorMode === 'light' ? 'assets/layout/images/logo-dark.svg' : 'assets/layout/images/logo-light.svg'))) alt="logo"/> */}
//             {/* <img className="mr-3" src={'assets/layout/images/trid-symbol.svg'} alt="logo"/> */}
//             {/* <img className="mr-3" src={'assets/layout/images/sostark-logo.png'} alt="logo"/> */}

//             <span>{ window._env_.TITLE }</span>
//           </a>
//           {/* </Link> */}

//           <button type="button" className="p-link  layout-menu-button layout-topbar-button X_red_border" onClick={this.props.onToggleMenuClick} id="toggleMenuClick" >
//               <i className="pi pi-bars"/>
//           </button>

//           <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button X_red_border" onClick={this.props.onMobileTopbarMenuClick}>
//               <i className="pi pi-ellipsis-v" />
//           </button>

//           {/* <p>windows._env_.API_BASE_URL={window._env_.API_BASE_URL}</p> */}

//               <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': this.props.mobileTopbarMenuActive })}>

//                   <li>
//                       <button className="p-link layout-topbar-button" onClick={(e) => this.props.onMobileSubTopbarMenuClick(e, "cal")}>
//                           <i className="pi pi-calendar"/>
//                           <span>Events</span>
//                       </button>
//                   </li>

//                   <li>
//                     {/* <button className="p-link layout-topbar-button" onClick={this.props.onMobileSubTopbarMenuClick}> */}
//                     {/* <button className="p-link layout-topbar-button" onClick={AppConfig.toggleConfigurator}> */}
//                       <button className="p-link layout-topbar-button" onClick={(e) => this.props.onMobileSubTopbarMenuClick(e, "cog")}>
//                           <i className="pi pi-cog"/>
//                           <span>Settings</span>
//                       </button>
//                   </li>
//                   <li>
//                       {/* <button className="p-link layout-topbar-button" onClick={this.props.onMobileSubTopbarMenuClick}> */}
//                       <button className="p-link layout-topbar-button" onClick={(e) => this.props.onMobileSubTopbarMenuClick(e, "user")}>
//                           <i className="pi pi-user"/>
//                           <span>Profile</span>
//                       </button>
//                   </li>
//               </ul>
//       </div>
//     );
//   }
// }
// export default AppTopbar;

// socket.on('webui_connect_req', () => {
//   connected = true;
//   socket_io.emit('webui_connect_ack', {
//     connected: connected
//   });
// });

export const AppTopbar = (props) => {
  // export const AppTopbar = observer( (props) => {

  const userMenu = useRef(null);
  const toggleMenu = (event) => {
    userMenu.current.toggle(event);
  };
  const overlayMenuItems = [
    {
      label: "Login",
      icon: "pi pi-save",
    },
    {
      label: "Account",
      icon: "pi pi-refresh",
    },
    {
      label: "Logout",
      icon: "pi pi-trash",
    },
  ];

  // const BUILD_TIME = window._env_.BUILD_TIME || 0;
  const BUILDTIME = window.BUILDTIME || 0;
  //
  let BUILD_TIME_str = "";
  //BUILD_TIME_str = "Last UI Build Time: ";
  if (BUILDTIME) {
    // BUILD_TIME_str = "Last build: ";
    // const this_date = new Date(BUILDTIME);
    // BUILD_TIME_str = f_date_to_nicedatetime(this_date);
    //BUILD_TIME_str += f_date_to_nicedatetime(BUILDTIME * 1000);
    // BUILD_TIME_str = f_date_to_nicetime(BUILDTIME);
    // BUILD_TIME_str = BUILDTIME;
  } else {
    //BUILD_TIME_str += "<unknown>";
  }

  return (
    <div className="layout-topbar X_red_border">
      {/* <Link to="/" className="layout-topbar-logo"> */}
      <a href="./" className="layout-topbar-logo">
        {/* <img src=(((props.layoutColorMode === 'light' ? 'assets/layout/images/logo-dark.svg' : 'assets/layout/images/logo-light.svg'))) alt="logo"/> */}
        {/* <img className="mr-3" src={'assets/layout/images/trid-symbol.svg'} alt="logo"/> */}
        {/* <img className="mr-3" src={'assets/layout/images/sostark-logo.png'} alt="logo"/> */}

        <span>{window._env_.TITLE}</span>
      </a>
      {/* </Link> */}
      <div class="header_right" >
        <button type="button" className="p-link  layout-menu-button layout-topbar-button X_red_border" onClick={props.onToggleMenuClick} id="toggleMenuClick">
          <i className="pi pi-bars" />
        </button>

        <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button X_red_border" onClick={props.onMobileTopbarMenuClick}>
          <i className="pi pi-ellipsis-v" />
        </button>

        {/* <span class="title_head">Engineering Dashboard</span> */}
        <img className="mr-3" src={floorplan} height="30px"  alt="odense-floorplan" />
        <ul className={classNames("layout-topbar-menu lg:flex origin-top align-items-center", { "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive })}>
        
          {BUILD_TIME_str && (
            <li>
              <Chip className="mr-2" label={BUILD_TIME_str} />
            </li>
          )}
          <li>
            <ApiStatus />
          </li>

          {/* <li>
                          <button className="p-link layout-topbar-button" onClick={(e) => props.onMobileSubTopbarMenuClick(e, "cal")}>
                              <i className="pi pi-calendar"/>
                              <span>Events</span>
                          </button>
                      </li> */}

          <li>
            {/* <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}> */}
            {/* <button className="p-link layout-topbar-button" onClick={AppConfig.toggleConfigurator}> */}
            <button className="p-link layout-topbar-button" onClick={(e) => props.onMobileSubTopbarMenuClick(e, "cog")}>
              <i className="pi pi-cog" />
              <span>Settings</span>
            </button>
          </li>
          <li>
            {/* <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}> */}
            {/* <button className="p-link layout-topbar-button" onClick={(e) => props.onMobileSubTopbarMenuClick(e, "user")}> */}
            <button className="p-link layout-topbar-button" onClick={toggleMenu}>
              <i className="pi pi-user" />
              <span>Profile</span>
            </button>
          </li>
        </ul>
      </div>
      <Menu ref={userMenu} model={overlayMenuItems} popup />
    </div>
  );
};

//-EOF
