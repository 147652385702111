//= ./src/components/AnchorMap.jsx

import React, { Component } from "react";
import TokenPill from "./TokenPill";

const AnchorMap = (props) => {
  return (
    <div
      style={{
        border: "2px solid #d9d3d3",
        padding: "6px",
        margin: "0px",
        display: "inline-block",
        width: "330px",
        // maxWidth: "240px",
        minHeight: "120px",
        textAlign: "center",
        // position: "absolute",
        // zIndex: 1,
        // top: "160px",
        // left: "160px",
        // cursor: "move",
        // cursor: this.props.is_moveable ? "move" : "auto",
        cursor: "move",
        borderRadius:"12px"
      }}
    >
      <p>
        <TokenPill
          tpid={props.anchor.tpid}
          display_name={props.anchor.display_name}
          // is_moveable={true}
        />
        <span>{}</span>
      </p>
      <p>
        {props.anchor.tokens &&
          Object.keys(props.anchor.tokens).map((t_tpid) => {
            return (
              <TokenPill
                key={t_tpid}
                tpid={t_tpid}
              />
            );
          })}
      </p>
    </div>
  );
};

export default AnchorMap;
//-eof
