//= pages/QMessages.js

// eslint-disable-next-line

import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable"; // https://www.primefaces.org/primereact/datatable/
import { Column } from "primereact/column"; // https://www.primefaces.org/primereact/column/
import { Toast } from "primereact/toast";
import { Button } from "primereact/button"; // https://www.primefaces.org/primereact/button/
// import { FileUpload } from 'primereact/fileupload';
import { Rating } from "primereact/rating";
// import { Toolbar } from 'primereact/toolbar';
import { InputText } from "primereact/inputtext"; // https://www.primefaces.org/primereact/inputtext/
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";

import axios from "axios"; // npm i axios --save
import moment_js from "moment"; // npm i moment --save

import { f_get_substring_length, f_date_to_nicetime, f_js } from "../lib/functions";

// - - - - - - = = = - - - - - -
// export Component
export const QMessagesPage = () => {
  //

  let emptyItem = {
    id: null,
    name: "",
  };

  const max_length = 42;

  const enum_fields = [
    {
      name: "category",
      label: "Category",
      values: ["Accessories", "Clothing", "Electronics", "Fitness"],
    },
  ];
  const category_enum = enum_fields.find((obj) => obj.name === "category");

  // const api_url = "";
  // const api_url = ".";
  // const api_url = "http://ubuntu-vm-imac19.i.dgt-bv.com:8052";
  const api_url = window._env_.API_BASE_URL;
  //
  // const url_mqttpub = api_url + '/api/mqttpub';
  const url_api_qmessages = api_url + "/qmessages";

  const [item, setItem] = useState(emptyItem);
  const [items, setItems] = useState(null);
  const [selectedItems, setSelectedItems] = useState(null);

  const [itemShowDialog, setItemShowDialog] = useState(false);
  const [deleteSingleItemShowDialog, setDeleteSingleItemShowDialog] = useState(false);
  const [deleteManyItemsShowDialog, setDeleteManyItemsShowDialog] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);

  const toast = useRef(null);
  const refDataTable = useRef(null);

  const [mqtt_loading, set_mqtt_loading] = useState(true);
  const [mqtt_show_id, set_mqtt_show_id] = useState(false);
  const [mqtt_checkbox_show_id, set_mqtt_checkbox_show_id] = useState([]);

  // const regex_ascii = /^[\x00-\x7f]*$/; // reference: https://en.wikipedia.org/wiki/ASCII
  // const regex_ascii = /^[\x20-\x7e]*$/; // only printable chars
  // const regex_ascii = /^[a-zA-Z0-9\ \.,\-_]*$/; // disallow formatting chars like []{}()<>*&^%$#@!=+\/|`?'~"
  const re_ASCII = new RegExp("^[a-zA-Z0-9 -.,_!?&()%@=+/]*$");

  // - - - - - - = = = - - - - - -
  // API logic:

  const API_getItems = function (props) {
    const func_name = "API_getItems";
    //
    // // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise
    // return new Promise((resolve, reject) => {
    //   let my_data = [];
    //   //
    //   // from original Crud.js, to be replaced with my new API-call:
    //   const itemService = new ProductService();
    //   itemService.getProducts().then((data) => (my_data = data));
    //   //
    //   resolve(my_data);
    // });
    //
    // const itemService = new ProductService();
    // return itemService.getProducts(); // jdg-note: just return promise, let called resolve it.
    //
    // return new Promise((resolve, reject) => {
    //   resolve([]);
    // });
    //
    // axios
    //   .get(url_mqttpub, {}, { timeout: 3000 })
    //   .then((data) => {
    //     set_mqtt_table(data.data.mqttpub);
    //     set_mqtt_loading(false);
    //   })
    //   .catch((error) => {
    //     set_mqtt_table(null);
    //     set_mqtt_loading(false);
    //   });
    //
    return axios.get(url_api_qmessages, {}, { timeout: 3000 });
    //
  };

  const API_saveEditItem = function ({ items, item }) {
    const index = findIndexById(item.id);
    items[index] = item; // save
    return items;
  };

  const API_saveNewItem = function ({ items, item }) {
    item.id = createId();
    item.image = "item-placeholder.svg";
    items.push(item); // save
    return items;
  };

  const API_deleteSingleItem = function ({ items, item }) {
    let _items = items.filter((val) => val.id !== item.id);
    setItems(_items);
  };

  const API_deleteManyItems = function ({ items, selectedItems }) {
    let _items = items.filter((val) => !selectedItems.includes(val));
    setItems(_items);
  };

  // - - - - - - = = = - - - - - -
  // local logic

  const [last_update_time, set_last_update_time] = useState(Date.now());

  const f_date_to_nicetime_local = (date) => {
    // let my_data_formatted = moment_js(date).format('YYYY-MMM-DD HH:mm:ss');
    let my_data_formatted = f_date_to_nicetime(date);
    if (/2022-Jan-01/.test(my_data_formatted)) my_data_formatted = "(pending)";
    return my_data_formatted;
  };

  const f_replace_response = (text) => {
    let my_data_formatted = text;
    if (!text) my_data_formatted = "...";
    return my_data_formatted;
  };

  const update_api_items = function () {
    // const data = await API_getItems();
    API_getItems().then((data) => setItems(data.data.qmessages));
    set_mqtt_loading(false);
  };

  const update_interval = 3000;

  useEffect(() => {
    //
    update_api_items();
    //
    const interval = setInterval(() => {
      set_last_update_time(Date.now());
      update_api_items();
    }, update_interval);
    //
    // return a function that is to be run at 'componentWillUnmount':
    return () => {
      clearInterval(interval); // undo 'setInterval()'
    };
    //
    // eslint-disable-next-line
  }, []);

  const formatCurrency = (value) => {
    return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
  };

  const openNew = () => {
    setItem(emptyItem);
    setSubmitted(false);
    setItemShowDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setItemShowDialog(false);
  };

  const hideDeleteSingleItemDialog = () => {
    setDeleteSingleItemShowDialog(false);
  };

  const hideDeleteManyItemsDialog = () => {
    setDeleteManyItemsShowDialog(false);
  };

  const saveItem = () => {
    setSubmitted(true);
    //
    // const _items = API_saveItem();
    //
    let _items = [...items];
    let _item = { ...item };
    if (item.name.trim()) {
      if (item.id) {
        // const index = findIndexById(item.id);
        // _items[index] = _item;
        //
        _items = API_saveEditItem({ items: _items, item: _item });
        //
        toast.current.show({ severity: "success", summary: "Successful", detail: "Item Updated", life: 3000 });
      } else {
        // _item.id = createId();
        // _item.image = 'item-placeholder.svg';
        // _items.push(_item);
        //
        _items = API_saveNewItem({ items: _items, item: _item });
        //
        toast.current.show({ severity: "success", summary: "Successful", detail: "Item Created", life: 3000 });
      }
    }
    //
    setItems(_items);
    setItemShowDialog(false);
    setItem(emptyItem);
  };

  const editItem = (item) => {
    setItem({ ...item });
    setItemShowDialog(true);
  };

  const confirmSingleDeleteItem = (item) => {
    setItem(item);
    setDeleteSingleItemShowDialog(true);
  };

  const deleteSingleItem = () => {
    //
    // let _items = items.filter((val) => val.id !== item.id);
    // setItems(_items);
    //
    API_deleteSingleItem({ items: items, item: item });
    //
    setDeleteSingleItemShowDialog(false);
    setItem(emptyItem);
    toast.current.show({ severity: "success", summary: "Successful", detail: "Item Deleted", life: 3000 });
  };

  const findIndexById = (id) => {
    let index = -1;
    for (let i = 0; i < items.length; i++) {
      if (items[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  };

  const createId = () => {
    let id = "";
    let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 5; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return id;
  };

  // const exportCSV = () => {
  //   refDataTable.current.exportCSV();
  // };

  const confirmDeleteSelected = () => {
    setDeleteManyItemsShowDialog(true);
  };

  const deleteSelectedItems = () => {
    //
    // let _items = items.filter((val) => !selectedItems.includes(val));
    // setItems(_items);
    //
    API_deleteManyItems({ items: items, selectedItems: selectedItems });
    //
    setDeleteManyItemsShowDialog(false);
    setSelectedItems(null);
    toast.current.show({ severity: "success", summary: "Successful", detail: "Items Deleted", life: 3000 });
  };

  const mqtt_on_checkbox_showid_change = (e) => {
    let selectedValue = [...mqtt_checkbox_show_id];
    if (e.checked) selectedValue.push(e.value);
    else selectedValue.splice(selectedValue.indexOf(e.value), 1);
    //
    set_mqtt_checkbox_show_id(selectedValue);
    set_mqtt_show_id(selectedValue.indexOf("true") !== -1);
  };

  const onCategoryChange = (e) => {
    let _item = { ...item };
    _item["category"] = e.value;
    setItem(_item);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _item = { ...item };
    _item[`${name}`] = val;

    setItem(_item);
  };

  const onInputNumberChange = (e, name) => {
    const val = e.value || 0;
    let _item = { ...item };
    _item[`${name}`] = val;

    setItem(_item);
  };

  // const leftToolbarTemplate = () => {
  //   return (
  //     <React.Fragment>
  //       <div className="my-2">
  //         <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
  //         <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedItems || !selectedItems.length} />
  //       </div>
  //     </React.Fragment>
  //   );
  // };

  // const rightToolbarTemplate = () => {
  //   return (
  //     <React.Fragment>
  //       <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
  //       <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
  //     </React.Fragment>
  //   );
  // };

  // - - - - - - = = = - - - - - -
  // Templates:

  const body_template = (data, props) => {
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {data[props.field]}
      </>
    );
  };

  const multiBodyTemplate = (data, props) => {
    const my_header = props.header;
    const my_field = props.field;
    const my_data = data[my_field];
    let my_data_formatted = my_data;
    switch (my_field) {
      case "msg_type":
        my_data_formatted = my_data;
        break;
      case "msg_body":
        // my_data_formatted = f_get_substring_length({ str: my_data, length: max_length });
        my_data_formatted = f_get_substring_length({ str: f_js(my_data), length: max_length });
        break;
      case "create_time":
      case "send_time":
        // my_data_formatted = f_date_to_nicetime(my_data);
        // if (/2022-Jan-01/.test(my_data_formatted)) my_data_formatted = '';
        my_data_formatted = f_date_to_nicetime_local(my_data);
        break;
      case "sender_result":
        my_data_formatted = f_replace_response(my_data);
        break;
      case "send_attempted":
      case "send_success":
        my_data_formatted = my_data ? "true" : "false";
        break;
      default:
        break;
    }
    return (
      <>
        <span className="p-column-title">{my_header}</span>
        {my_data_formatted}
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editItem(rowData)} tooltip="View" tooltipOptions={{ position: "top" }} />
        <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmSingleDeleteItem(rowData)} tooltip="Delete" tooltipOptions={{ position: "top" }} />
      </div>
    );
  };

  const tableHeader = (
    <div
      className="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
      // className="table-header"
    >
      <h5 className="m-0">Messages-Q</h5>
      <div className="my-2">
        <a href={url_api_qmessages} target="_blank" rel="noreferrer">
          (API)
        </a>
      </div>
      <div className="my-2">Last update: {f_date_to_nicetime(last_update_time)}</div>
      {/* <div className="field-checkbox">
        <Checkbox inputId="checkShowIDs_mqtt" name="option" value="true" checked={mqtt_checkbox_show_id.indexOf('true') !== -1} onChange={mqtt_on_checkbox_showid_change} />
        <label htmlFor="checkShowIDs_mqtt">show IDs</label>
      </div> */}
      {/* <Button label="/api/mqttpub" onClick={click_show_api_mqtt} className="p-button-raised p-button-warning mr-2 mb-2" />
      <Button label="refresh" onClick={click_refresh_api_mqtt} className="p-button-raised p-button-warning mr-2 mb-2" /> */}
      <div className="my-2">
        {/* <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} /> */}
        <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedItems || !selectedItems.length} />
      </div>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        {/* https://www.primefaces.org/primereact/inputtext/ */}
        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
      </span>
      {/* <Button
          icon="pi pi-icon p-c pi-times-circle"
          className="p-button-rounded"
          onClick={() => {
            setGlobalFilter(null);
          }}
          disabled={!globalFilter || !globalFilter.length}
        /> */}
    </div>
  );

  const itemDialogFooter = (
    <>
      <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      {/* <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveItem} /> */}
    </>
  );
  const deleteSingleItemDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteSingleItemDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSingleItem} />
    </>
  );
  const deleteManyItemsDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteManyItemsDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItems} />
    </>
  );

  // - - - - - - = = = - - - - - -
  // Render logic:

  // copy data-structure:
  // const qmessages_schema = new mongoose.Schema(
  //   {
  //     type: { type: String, required: true, enum: ['mqttpub', 'other'] }, // API resource name, also DB name
  //     sender_name: { type: String, required: true }, // = 'name' in mttqpub collection
  //     body: { type: String, required: true }, // the actual message.json to be send_time
  //     create_time: { type: Date, default: Date.now }, //
  //     // send_time: date when send_time or error, so if empty then msg is still queued, if error 'sender_result' says why
  //     // send_time: { type: Date, default: undefined },
  //     send_attempted: { type: Boolean, default: false },
  //     send_success: { type: Boolean, default: false },
  //     send_time: { type: Date, default: moment_js('2022-01-01T00:00:00.000Z') },
  //     sender_result: { type: String, default: '' }, // error string if error, 'OK' if send_time-success
  //   },
  //   { timepoints: true } // creates these keys: createdAt, updatedAt
  // );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          {/* - - - - - - = = = - - - - - - */}
          <Toast ref={toast} />

          {/* <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar> */}

          {/* - - - - - - = = = - - - - - - */}
          <DataTable
            ref={refDataTable}
            value={items}
            selection={selectedItems}
            onSelectionChange={(e) => setSelectedItems(e.value)}
            // dataKey="id"
            dataKey="_id"
            // dataKey="create_time"
            header={tableHeader}
            loading={mqtt_loading}
            paginator
            rows={10}
            rowHover
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            // className="datatable-responsive"
            // className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
            className="datatable-responsive p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
            globalFilter={globalFilter}
            emptyMessage="No items found."
            // scrollable scrollHeight="600px"
            // sortField={sortField} sortOrder={sortOrder} onSort={onSort}
            // sortMode="multiple"
            // multiSortMeta={multiSortMeta} onSort={(e) => setMultiSortMeta(e.multiSortMeta)}
            // showGridlines={true} // <== jdg:already set with: className="p-datatable-gridlines"
            columnResizeMode="fit"
            autoLayout="true">
            {mqtt_show_id && <Column field="_id" header="ID" sortable body={multiBodyTemplate}></Column>}
            <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
            {/* - - - - - - = = = - - - - - - */}
            <Column field="type" header="Type" sortable body={multiBodyTemplate}></Column>
            <Column field="sender_name" header="Sender" sortable body={multiBodyTemplate}></Column>
            <Column field="msg_type" header="Type" sortable body={multiBodyTemplate}></Column>
            <Column field="msg_body" header="Message" sortable body={multiBodyTemplate}></Column>
            <Column field="create_time" header="Created" sortable body={multiBodyTemplate}></Column>
            <Column field="send_time" header="Send" sortable body={multiBodyTemplate}></Column>
            <Column field="send_attempted" header="Send Attempt" sortable body={multiBodyTemplate}></Column>
            <Column field="send_success" header="Send Success" sortable body={multiBodyTemplate}></Column>
            <Column
              field="sender_result"
              header="Sender Result"
              sortable
              body={multiBodyTemplate}
              style={{ whiteSpace: "normal" }} // undo:App.scss : .p-datatable-tbody { white-space: nowrap;}
            ></Column>
            {/* - - - - - - = = = - - - - - - */}
            <Column header="Actions" body={actionBodyTemplate}></Column>
            {/* - - - - - - = = = - - - - - - */}
          </DataTable>

          {/* - - - - - - = = = - - - - - - */}
          <Dialog visible={itemShowDialog} style={{ width: "760px" }} header="Message details" modal className="p-fluid" footer={itemDialogFooter} onHide={hideDialog}>
            {/* - - - - - - = = = - - - - - - */}
            <div className="formgrid grid">
              {/* - - - - - - = = = - - - - - - */}
              <div className="field col">
                <label htmlFor="type">Type</label>
                <InputText id="type" value={item.type} />
              </div>
              {/* - - - - - - = = = - - - - - - */}
              <div className="field col">
                <label htmlFor="sender_name">Sender Name</label>
                <InputText id="sender_name" value={item.sender_name} />
              </div>
            </div>
            {/* - - - - - - = = = - - - - - - */}
            <div className="field">
              <label htmlFor="body">Description</label>
              <InputTextarea id="msg_body" value={f_js(item.msg_body)} rows={8} cols={40} />
            </div>
            {/* - - - - - - = = = - - - - - - */}
            {/* <div className="field">
              <label className="mb-3">{category_enum.label}</label>
              <div className="formgrid grid">
                {category_enum.values &&
                  category_enum.values.map((cat, index) => {
                    return (
                      <div className="field-radiobutton col-6">
                        <RadioButton inputId={`${category_enum.name}_${index}`} name={category_enum.name} value={cat} onChange={onCategoryChange} checked={item.category === cat} />
                        <label htmlFor={`${category_enum.name}_${index}`}>{cat}</label>
                      </div>
                    );
                  })}
              </div> 
              {/* - - - - - - = = = - - - - - - * / }
            </div> */}
            {/* - - - - - - = = = - - - - - - */}
            <div className="formgrid grid">
              {/* - - - - - - = = = - - - - - - */}
              <div className="field col">
                <label htmlFor="create_time">Created</label>
                <InputText id="create_time" value={f_date_to_nicetime(item.create_time)} />
              </div>
              {/* - - - - - - = = = - - - - - - */}
              <div className="field col">
                <label htmlFor="send_time">Send</label>
                <InputText id="send_time" value={f_date_to_nicetime_local(item.send_time)} />
              </div>
            </div>
            {/* - - - - - - = = = - - - - - - */}
            <div className="formgrid grid">
              {/* - - - - - - = = = - - - - - - */}
              <div className="field col">
                <label htmlFor="send_attempted">Send Attempted</label>
                <InputText id="send_attempted" value={item.send_attempted ? "true" : "false"} />
              </div>
              {/* - - - - - - = = = - - - - - - */}
              <div className="field col">
                <label htmlFor="send_success">Send Success</label>
                <InputText id="send_success" value={item.send_success ? "true" : "false"} />
              </div>
              {/* - - - - - - = = = - - - - - - */}
              <div className="field ">
                <label htmlFor="sender_result">Sender Result</label>
                <InputText id="sender_result" value={f_replace_response(item.sender_result)} />
              </div>
            </div>
          </Dialog>

          {/* - - - - - - = = = - - - - - - */}
          <Dialog visible={deleteSingleItemShowDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteSingleItemDialogFooter} onHide={hideDeleteSingleItemDialog}>
            <div className="flex align-items-center justify-content-center">
              <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
              {item && (
                <span>
                  Are you sure you want to delete <b>{item.name}</b>?
                </span>
              )}
            </div>
          </Dialog>

          {/* - - - - - - = = = - - - - - - */}
          <Dialog visible={deleteManyItemsShowDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteManyItemsDialogFooter} onHide={hideDeleteManyItemsDialog}>
            <div className="flex align-items-center justify-content-center">
              <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
              {item && <span>Are you sure you want to delete the selected items?</span>}
            </div>
          </Dialog>
          {/* - - - - - - = = = - - - - - - */}
        </div>
      </div>
    </div>
    // - - - - - - = = = - - - - - -
  ); // \return ()
  // - - - - - - = = = - - - - - -
}; // \export const QMessagesPage = () => {}

//-eof
