//= ./src/components/common/ErrorBoundary.jsx

// idea from: https://reactjs.org/docs/error-boundaries.html

import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      // return <p>Something went wrong.</p>;

      return (
        <div className="grid crud-demo">
          <div className="col-12">
            <div className="card">
              <p>Internal Error (catched by the "ErrorBoundary" component)</p>
            </div>
          </div>
        </div>
        // - - - - - - = = = - - - - - -
      ); // \return ()
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

//-eof
