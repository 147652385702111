//= ./src/components/TableAnchorDetail.jsx

/* 
// Usage: we are called this way: 
<TableAnchorDetail params_tpid={params_tpid} {...props} />
*/

// import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
//
import { TableTemplate } from "../components/common/TableTemplate";
import { f_nr_to_padzero_string, fa_sleep_ms_promise, gen_colors } from "../lib/functions";

// - - - - - - = = = - - - - - -
const mod_name = "PageConfigMessages";
const func_name = `${mod_name}:anonymous`; // <== default, needs to be locally overwritten

// Icons =>        https://www.primefaces.org/primereact/icons/
// CSS =>          https://www.primefaces.org/primeflex/
// Componenets =>  https://www.primefaces.org/primereact/calendar/

const TableAnchorDetail = (props) => {
  //
  // - - - - - - = = = - - - - - -
  // LOCAL STATE:
  const [v_state_items, f_setstate_items] = useState([]); // useState(null);
  const [v_state_api_error, f_setstate_api_error] = useState("");
  //
  // LOCAL CONST:
  const api_base_url = window._env_.API_BASE_URL || "";
  const api_api_uri = "/";
  const api_resource_name = "anchors";
  const api_url = api_base_url + api_api_uri + api_resource_name;
  //
  // EXPORTED MODEL:
  const model = {
    api_url: api_url,
    api_timeout_ms: 1000,
    api_resource_name: api_resource_name,
    item_name_singular: "Anchor",
    item_name_plural: "Anchors",
    table_title: `Anchor ${props.params_tpid}`,
    dialog_title: "Anchor details",
    dialog_width: "650px",
    msg_empty: "No Anchor found.",
    msg_confirm_dialog_header: "Confirm",
    msg_are_you_sure_delete_single_item: "Are you sure you want to delete",
    msg_are_you_sure_delete_multi_item: "Are you sure you want to delete the selected Anchor?",
    fields: [
      {
        field: "id",
        label: "ID",
        dialog_type: "id",
        focus: false,
        table_type: "string",
        required: false,
        sortable: true,
        wrap: false,
      },
      {
        field: "tpid",
        label: "Anchor ID:TPID",
        dialog_type: "string",
        focus: true,
        table_type: "string",
        required: false,
        sortable: true,
        wrap: true,
      },
    ],
    table_fields_order: ["tpid"],
    dialog_fields_order: ["tpid"],
    main_field: "tpid",
    initial_sort_field: "tpid",
    data_key: "id",
    rows: 10,
    rows_per_page_options: [5, 10, 25, 50, 100],
    operations: ["new", "edit", "delete"], // all options: 'new', 'view', 'edit', 'duplicate', 'delete' // NVEDD
    show_showid_button: true,
    show_openapi_button: true,
    show_refresh_button: true,
    refresh_button_label: "Reload Table",
    auto_refresh_secs: 0,
    auto_refresh_options: [0, 1, 3, 5, 10, 30, 60],
    toast_life_ms: 5000,
    new_item_template: {
      time: "",
    },
    image_path: "assets/demo/images/item",
    //
    // fa_model_get_items: undefined,
    // fa_model_set_item: undefined,
    //
  };

  // - - - - - - = = = - - - - - -
  // Render logic:
  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <TableTemplate v_state_items={v_state_items} f_setstate_items={f_setstate_items} model={model} v_state_api_error={v_state_api_error} />
        </div>
      </div>
    </div>
    // - - - - - - = = = - - - - - -
  ); // \return ()
  // - - - - - - = = = - - - - - -
};

export default TableAnchorDetail;

//-eof
