// socket_io_context.js

// idea from: https://dev.to/bravemaster619/how-to-use-socket-io-client-correctly-in-react-app-o65
//
import React from "react";
import socketio_client from "socket.io-client"; // npm i socket.io-client --save
// import { SOCKET_URL } from "config";

const api_url = window._env_.API_BASE_URL;
// const url_anchors     = api_url + "/api/anchors";

// const socketio_path = '/api/sys-socketio/';
const socketio_path = "/sock/sys-socketio/";
// const socket_io_url = socketio_path + '/socket.io/socket.io.min.js';
// const asdasdadad = '/api/static/socket.io/client-dist/socket.io.min.js';

// {serveClient: true}
// Default value: true
// Whether to serve the client files. If true, the different bundles will be served at the following location:
// <url>/socket.io/socket.io.js
// <url>/socket.io/socket.io.min.js
// <url>/socket.io/socket.io.msgpack.min.js

// export const socket_io = socketio_client.connect(SOCKET_URL);
// export const socket_io = socketio_client.connect(socket_io_url);
// export const socket_io = socketio_client.connect(socket_io_url, {path: socketio_path});
// export const socket_io = socketio_client.connect(api_url, {path: socketio_path});
export const socket_io = {};

export const SocketContext = React.createContext();
// elsewhere use:
// import {SocketContext, socket_io} from 'lib/socket_io_context';

//-EOF
