//= ./src/pages/Crud.js
// - - - - - - = = = - - - - - -

// ----!!!! NOTE !!!!!-------------------------------------
// ----!!!! NOTE !!!!!-------------------------------------
// ----!!!! NOTE !!!!!-------------------------------------

// this "Crud.js" component is replace by the "TableTemplate.jsx" component !!!!
//
// import { TableTemplate } from "../components/common/TableTemplate";

// ----!!!! NOTE !!!!!-------------------------------------
// ----!!!! NOTE !!!!!-------------------------------------
// ----!!!! NOTE !!!!!-------------------------------------

// eslint-disable-next-line

// import PropTypes from 'prop-types'; // npm install prop-types --save
import React, { useState, useEffect, useRef } from "react";
//
import { ProductService } from "../service/ProductService";
// import { MouseTracker } from '../components/MouseTracker';
// import { CrudTable } from "../components/CrudTable";
import { TableTemplate } from "../components/common/TableTemplate";

// - - - - - - = = = - - - - - -
const mod_name = "pages_crud";
const func_name = `${mod_name}:anonymous`; // <== default, needs to be locally overwritten

// JDG-note: mini-copy here of: '../service/ProductService' to show what happens:
//
// export class ProductService {
//  getProducts() {
//       return axios.get('assets/demo/data/products.json').then(res => res.data.data); // res.data == axios-syntax, (res.data).data == (products.json).key
//   }
// }
//
// JDG-note: mini-copy here of: 'assets/demo/data/products.json'
//
// {
//   "data": [
//       {"id": "1000","code": "f230fh0g3","name": "Bamboo Watch","description": "Product Description","image": "bamboo-watch.jpg","price": 65,"category": "Accessories","quantity": 24,"inventoryStatus": "INSTOCK","rating": 5},
//       {"id": "1001","code": "nvklal433","name": "Black Watch","description": "Product Description","image": "black-watch.jpg","price": 72,"category": "Accessories","quantity": 61,"inventoryStatus": "INSTOCK","rating": 4},
//       {"id": "1002","code": "zz21cz3c1","name": "Blue Band","description": "Product Description","image": "blue-band.jpg","price": 79,"category": "Fitness","quantity": 2,"inventoryStatus": "LOWSTOCK","rating": 3},
//       . . .
//   ]
// }

// - - - - - - = = = - - - - - -
// export Component
export const CrudPage = () => {
  //

  // - - - - - - = = = - - - - - -
  // Model definition:

  const model = {
    api_url: "/api/crud",
    item_name_singular: "Product",
    item_name_plural: "Products",
    table_title: "Manage Products",
    dialog_title: "Product Details",
    dialog_width: "450px",
    msg_empty: "No products found.",
    msg_confirm_dialog_header: "Confirm",
    msg_are_you_sure_delete_single_item: "Are you sure you want to delete",
    msg_are_you_sure_delete_multi_item: "Are you sure you want to delete the selected products?",
    fields: [
      { field: "id", label: "ID", dialog_type: "id", focus: false, table_type: "string", required: false, sortable: true },
      { field: "code", label: "Code", dialog_type: "string", focus: false, table_type: "string", required: false, sortable: true },
      { field: "name", label: "Name", dialog_type: "string", focus: true, table_type: "string", required: true, sortable: true, cols_in_dialog: 12 },
      { field: "image", label: "Image", dialog_type: "image", focus: false, table_type: "image", required: false, sortable: false, cols_in_dialog: 12 },
      { field: "description", label: "Description", dialog_type: "text", focus: false, table_type: "string", required: true, sortable: false, cols_in_dialog: 12, textarea_rows: 3, textarea_cols: 20 },
      { field: "category", label: "Category", dialog_type: "enum", enums: ["Accessories", "Clothing", "Electronics", "Fitness"], focus: false, table_type: "string", required: false, sortable: true, cols_in_dialog: 12 },
      { field: "price", label: "Price", dialog_type: "currency", currency: "USD", locale: "en-US", show_cents: true, focus: false, table_type: "currency", required: false, sortable: true, cols_in_dialog: 6 },
      { field: "rating", label: "Reviews", dialog_type: "integer", focus: false, table_type: "rating", required: false, sortable: true },
      { field: "inventoryStatus", label: "Status", dialog_type: "enum", enums: ["INSTOCK", "OUTOFSTOCK", "LOWSTOCK"], focus: false, table_type: "status", required: false, sortable: true },
      { field: "quantity", label: "Quantity", dialog_type: "integer", focus: false, table_type: "string", required: false, sortable: true, cols_in_dialog: 6 },
    ],
    table_fields_order: ["code", "name", "image", "price", "category", "rating", "inventoryStatus"],
    dialog_fields_order: ["image", "name", "description", "category", "price", "quantity"],
    main_field: "name",
    initial_sort_field: "name",
    data_key: "id",
    rows: 10,
    rows_per_page_options: [5, 10, 25, 50, 100],
    operations: ["new", "edit", "delete"], // all options: 'new', 'view', 'edit', 'duplicate', 'delete' // NVEDD
    show_showid_button: true,
    show_openapi_button: true,
    show_refresh_button: true,
    auto_refresh_secs: 0,
    auto_refresh_options: [0, 1, 3, 5, 10, 30, 60],
    new_item_template: {
      name: "New",
    },
    image_path: "assets/demo/images/item",
    //
    fa_model_get_items: undefined,
    fa_model_set_item: undefined,
    //
  };

  // - - - - - - = = = - - - - - -
  // API logic:

  const fa_model_get_items = async function () {
    //
    // NORMALLY: get update from API backend
    //
    // FOR THIS CRUD PAGE ONLY: use 'localmod':
    const get_items = await f_locmod_get_items_promise();
    //
    f_set_items(get_items); // this will cause a re-render
  };

  const fa_model_set_item = async function ({ action, item }) {
    const func_name = "fa_model_set_item";
    //
    let updated_item = undefined;
    // console.log(`${func_name} action=${action}`);
    //
    return new Promise(async (resolve, reject) => {
      try {
        // NORMALLY: send update to API backend
        //
        // HERE: we use some local funcs:
        switch (action) {
          case "new":
            // console.log(`${func_name} action=${action} - new`);
            updated_item = f_locmod_save_new_item(item);
            break;
          case "update":
            // console.log(`${func_name} action=${action} - update`);
            updated_item = f_locmod_save_edit_item(item);
            break;
          case "deleteOne":
            // console.log(`${func_name} action=${action} - deleteOne`);
            updated_item = f_locmod_delete_single_item(item);
            break;
          case "deleteMany":
            // console.log(`${func_name} action=${action} - deleteMany`);
            updated_item = f_locmod_delete_many_items(item);
            break;
          default:
            // throw "unknown action"; // <== gives error in ESlint: "Expected an error object to be thrown  no-throw-literal"
            throw new Error("unknown action");
          // don';'t do it here:
          // f_set_items(updated_item); // this will cause a re-render
        }
        //
        // next, return normally:
        resolve(updated_item);
        //
      } catch (err) {
        reject(err);
      }
    });
    //
  };

  model.fa_model_get_items = fa_model_get_items;
  model.fa_model_set_item = fa_model_set_item;

  // - - - - - - = = = - - - - - -
  // - - - - - - = = = - - - - - -
  // - - - - - - = = = - - - - - -

  // - - - - - - = = = - - - - - -
  // LOCAL STATE:
  const [items, f_set_items] = useState(null);

  // - - - - - - = = = - - - - - -
  // LOCAL FUNCS (instead of API calls):

  const f_locmod_get_items_promise = function (props) {
    const func_name = `${mod_name}:f_locmod_get_items_promise`;
    //
    // // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise
    // return new Promise((resolve, reject) => {
    //   let my_data = [];
    //   //
    //   // from original Crud.js, to be replaced with my new API-call:
    //   const itemService = new ProductService();
    //   itemService.getProducts().then((data) => (my_data = data));
    //   //
    //   resolve(my_data);
    // });
    //
    const itemService = new ProductService();
    return itemService.getProducts(); // jdg-note: return 'promise' here, let caller resolve it.
    //
  };

  const f_locmod_save_edit_item = function (item) {
    const func_name = `${mod_name}:f_locmod_save_edit_item`;
    //
    let my_items = [...items];
    let updated_item = { ...item };
    //
    const f_find_index_by_id = ({ items, id }) => {
      let index = -1;
      for (let i = 0; i < items.length; i++) {
        if (items[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    };
    const index = f_find_index_by_id({ items, id: item.id });
    //
    // JDG: this was the bug, wrong code, return object:
    // const index = items.find((elem) => elem.id === updated_item.id);
    //
    // console.log(`${func_name} index=${index} item.id=${item.id}`);
    //
    my_items[index] = updated_item; // save
    f_set_items(my_items);
    return updated_item;
  };

  const f_locmod_save_new_item = function (item) {
    let my_items = [...items];
    let updated_item = { ...item };
    //
    const f_create_id = () => {
      let id = "";
      let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    };
    updated_item.id = f_create_id();
    updated_item.code = f_create_id();
    //
    updated_item.image = "item-placeholder.svg";
    my_items.push(updated_item); // save
    f_set_items(my_items);
    return updated_item;
  };

  const f_locmod_delete_single_item = function (item) {
    let my_items = items.filter((val) => val.id !== item.id);
    let updated_item = { ...item };
    f_set_items(my_items);
    return updated_item;
  };

  const f_locmod_delete_many_items = function (selected_items) {
    let my_items = items.filter((val) => !selected_items.includes(val));
    let updated_item = selected_items;
    f_set_items(my_items);
    return updated_item;
  };

  // - - - - - - = = = - - - - - -
  // Render logic:
  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <TableTemplate
            parent_items={items}
            model={model}
          />
        </div>
      </div>
    </div>
    // - - - - - - = = = - - - - - -
  ); // \return ()
  // - - - - - - = = = - - - - - -
}; // \export const CrudPage = () => {}

//-eof
