//= ./components/ApiStatus.jsx

import React, { Component, useState, useContext, useEffect } from "react";
import { Tag } from "primereact/tag";

import { SocketContext } from "../lib/socket_io_context";

const ApiStatus = () => {
  //
  return (
    <Tag
      className="mr-2"
      icon="pi pi-check"
      severity="success"
    >
      API-connected
    </Tag>
  );
  //
  // // eslint-disable-next-line
  // const socket_io = useContext(SocketContext);
  // const [sockStatus, setSockStatus] = useState(false);
  // useEffect(() => {
  //   socket_io.emit('webui_connect_req', { client_id: 'dashboard.52' });
  //   //
  //   socket_io.on('disconnect', () => {
  //     console.log('# (ApiStatus) socket_io: disconnected');
  //     setSockStatus(false);
  //   });
  //   //
  //   socket_io.on('reconnect', (e) => {
  //     console.log(`# (ApiStatus) socket_io: It took ${e} tries to reconnect`);
  //     socket_io.emit('webui_connect_req', { client_id: 'dashboard.52' });
  //   });
  //   //
  //   // https://stackoverflow.com/questions/4432271/node-js-and-socket-io-how-to-reconnect-as-soon-as-disconnect-happens
  //   // tell socket.io to never give up :)
  //   socket_io.on('error', function () {
  //     console.log('# (ApiStatus) socket_io: error');
  //     socket_io.socket.reconnect();
  //     socket_io.emit('webui_connect_req', { client_id: 'dashboard.52' });
  //   });
  //   //
  //   socket_io.on('webui_connect_ack', (connected) => {
  //     console.log('# (ApiStatus) socket_io: webui_connect_ack');
  //     setSockStatus(connected);
  //   });
  //   // eslint-disable-next-line
  // }, [socket_io]);
  // // eslint-disable-next-line
  // return sockStatus ? (
  //   <Tag className="mr-2" icon="pi pi-check" severity="success">
  //     API-connected
  //   </Tag>
  // ) : (
  //   <Tag className="mr-2" icon="pi pi-exclamation-triangle" severity="warning">
  //     API-trying
  //   </Tag>
  // );
};
export default ApiStatus;

// class ApiStatus extends Component {
//   // state = {  }
//   render() {
//     return (
//       sockStatus
//       ? <li><Tag className="mr-2" icon="pi pi-check" severity="success" >API-connected</Tag></li>
//       : <li><Tag className="mr-2" icon="pi pi-exclamation-triangle" severity="warning" >API-trying</Tag></li>
//     );
//   }
// }
// export default ApiStatus;

//-EOF
