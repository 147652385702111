//= pages/Events.js

// eslint-disable-next-line

import React, { useState, Component, useContext, useEffect } from "react";
// import React from 'react';
//
import { DataTable } from "primereact/datatable"; // https://www.primefaces.org/primereact/datatable/
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";


// import { SocketContext } from '../lib/socket_io_context';
import { CrudTable } from "../components/CrudTable";

import axios from "axios"; // npm i axios --save
import moment_js from "moment"; // npm i moment --save

import { f_max_age_ms, f_get_max_age_both } from "../config/config";
import { f_convert_selected_sensors_to_display_string, f_is_object, f_js } from "../lib/functions";

const convert_seconds_to_DHMS = function (secs) {
  let seconds = secs;
  let days = Math.floor(seconds / (3600 * 24));
  seconds -= days * 3600 * 24;
  let hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;
  let minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;
  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
};

function nr_to_padzero_string(nr, digits) {
  var str = "00000" + nr;
  return str.substr(-1 * digits);
}

const date_to_nicetime = (date) => {
  return moment_js(date).format("YYYY-MMM-DD HH:mm:ss");
};


let max_age = f_max_age_ms();

// - - - - - - = = = - - - - - -
// export Component

export const EventsPage = () => {
  //
  const meta_prop = {
    api_url: window._env_.API_BASE_URL,
    item_name_singular: "Anchor",
    item_name_plural: "Anchors",
    table_title: "Manage Anchors",
    dialog_title: "Anchor Details",
    dialog_width: "450px",
    msg_empty: "No anchors found.",
    msg_confirm_dialog_header: "Confirm",
    msg_are_you_sure_delete_single_item: "Are you sure you want to delete",
    msg_are_you_sure_delete_multi_item: "Are you sure you want to delete the selected anchors?",

    f_api_get_items_promise: undefined,
    f_api_save_edit_item: undefined,
    f_api_save_new_item: undefined,
    f_api_delete_single_item: undefined,
    f_api_delete_many_items: undefined,
  };

  const f_api_get_items_promise = function (props) {
    return new Promise(); // jdg-note: return 'promise' here, let caller resolve it.
  };

  const f_api_save_edit_item = function ({ items, item }) {
    let _items = [];
    return _items;
  };

  const f_api_save_new_item = function ({ items, item }) {
    let _items = [];
    return _items;
  };

  const f_api_delete_single_item = function ({ items, item }) {
    let _items = [];
    return _items;
  };

  const f_api_delete_many_items = function ({ items, selected_items }) {
    let _items = [];
    return _items;
  };

  meta_prop.f_api_get_items_promise = f_api_get_items_promise;
  meta_prop.f_api_save_edit_item = f_api_save_edit_item;
  meta_prop.f_api_save_new_item = f_api_save_new_item;
  meta_prop.f_api_delete_single_item = f_api_delete_single_item;
  meta_prop.f_api_delete_many_items = f_api_delete_many_items;

  const api_url = window._env_.API_BASE_URL;
  const url_anchors_v3 = api_url + "/anchors";

  const [anchor_table, set_anchor_table] = useState(null);
  const [anchor_filter, set_anchor_filter] = useState("");
  const [anchor_loading, set_anchor_loading] = useState(true);
  const [anchor_show_id, set_anchor_show_id] = useState(false);
  const [anchor_checkbox_show_id, set_anchor_checkbox_show_id] = useState([]);

  const [interval_time, set_interval_time] = useState(Date.now());

  let update_interval_s = 2;

  useEffect(() => {
    //
    update_api_anchors();
    //
    const interval = setInterval(() => {
      set_interval_time(Date.now());
      update_api_anchors();
    }, update_interval_s * 1000);
    return () => {
      clearInterval(interval); // undo 'setInterval()'
    };
  }, []);

  const click_show_api_anchors = () => {
    window.open(url_anchors_v3);
  };
  const update_api_anchors = () => {
    axios
      .get(url_anchors_v3, {}, { timeout: 3000 })
      .then((data) => {
        const anchors = data.data && data.data.anchors ? data.data.anchors : null;
        set_anchor_table(anchors);
        set_anchor_loading(false);
      })
      .catch((err) => {
        set_anchor_table(null);
        set_anchor_loading(false);
      });
  };
  const anchor_on_checkbox_showid_change = (e) => {
    let selectedValue = [...anchor_checkbox_show_id];
    if (e.checked) selectedValue.push(e.value);
    else selectedValue.splice(selectedValue.indexOf(e.value), 1);
    //
    set_anchor_checkbox_show_id(selectedValue);
    set_anchor_show_id(selectedValue.indexOf("true") !== -1);
  };
  //
  const anchor_table_header = (
    <div className="table-header">
      <h5>Anchors</h5>
      <span className="">Last API update: {date_to_nicetime(interval_time)}</span>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={anchor_filter} onChange={(e) => set_anchor_filter(e.target.value)} placeholder="Global Search" />
      </span>
    </div>
  );

  const f_object_lookup_default = function ({ obj, field, dflt }) {
    const ret_value = f_is_object(obj) && obj.hasOwnProperty(field) ? obj[field] : dflt;
    return ret_value;
  };

  const body_template = (data, props) => {
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {f_object_lookup_default({ obj: data, field: props.field, dflt: "" })}
      </>
    );
  };

  const lw_freq_template = (data, props) => {
    const data_obj = data.aux && data.aux.lw ? data.aux.lw : {};
    const bandwidth = data_obj.bandwidth || "undef";
    const spreading_factor = data_obj.spreading_factor || "undef";
    const data_formatted = `bw=${bandwidth},sf=${spreading_factor}`;
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {data_formatted}
      </>
    );
  };

  const lw_gw_template = (data, props) => {
    const data_obj = data.aux && data.aux.lw ? data.aux.lw : {};
    //
    const gw_obj_list = data_obj.gateways || [];
    const gw_list = gw_obj_list.map((obj) => `gw_id=${obj.gateway_id},rssi=${obj.rssi},snr=${obj.snr}`);
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {gw_list.map((obj) => {
          return (
            <>
              {obj} <br />
            </>
          );
        })}
      </>
    );
  };

  const uptime_template = (data, props) => {
    const uptime = f_object_lookup_default({ obj: data, field: props.field, dflt: "" });
    const time = data.time || 0;
    let time_date_obj = new Date(time * 1000);
    let date_now = Date.now();
    let result_string = "";
    //
    const is_not_too_old = time_date_obj > date_now - max_age;
    if (time_date_obj > date_now - max_age) {
      let DHMS = convert_seconds_to_DHMS(uptime);
      const niceUptime = `${DHMS.days} days, ${nr_to_padzero_string(DHMS.hours, 2)}:${nr_to_padzero_string(DHMS.minutes, 2)}:${nr_to_padzero_string(DHMS.seconds, 2)}`;
      result_string = niceUptime;
    } else {
      result_string = "(down)";
    }
    //
    if (data["_uplink_tech"] === "lorawan") {
      result_string = "-";
    }
    //
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {result_string}
      </>
    );
  };

  const reptime_template = (data, props) => {
    let result_string = "";
    //
    const time = data.time || 0;
    let time_date_obj = new Date(time * 1000);
    let date_now = Date.now();
    const is_not_too_old = time_date_obj > date_now - max_age;
    //
    let nice_time = date_to_nicetime(time_date_obj);
    result_string = nice_time;
    //
    if (!is_not_too_old) {
      result_string = result_string + " (old)";
    }
    //
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {result_string}
      </>
    );
  };

  const tokens_template = (data, props) => {
    const tokens = f_object_lookup_default({ obj: data, field: "tokens", dflt: {} });
    const token_ids = Object.keys(tokens);
    token_ids.sort(); // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
    //
    let result_string = token_ids.join(",");
    //
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {result_string}
      </>
    );
  };

  const sensors_template = (data, props) => {
    const sensors = f_object_lookup_default({ obj: data, field: "sensors", dflt: {} });
    //
    let result_string = f_convert_selected_sensors_to_display_string({ sensors });
    //
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {result_string}
      </>
    );
  };

  const location_template = (data, props) => {
    const presence_alist = data.location.presence_alist;
    const t_time = data.time || 0;
    const a_list = presence_alist.filter((obj) => obj.time >= t_time).map((obj) => obj.anchor_id);
    // const tokens = presence_tlist.find(obj => obj.time == time).tokens;
    // let anchor_list_str = "Anchors:" + presence_alist.join(", ");
    let anchor_list_str = "Anchors:" + a_list.join(", ");
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {anchor_list_str}
      </>
    );
  };

  const fixes_template = (data, props) => {
    const fixes = data.fixes;
    const t_time = data.time || 0;
    let fixes_list = [];
    fixes.forEach((element) => {
      const tid = element.token_id;
      const presence_alist = element.location.presence_alist;
      const a_list = presence_alist.filter((obj) => obj.time >= t_time).map((obj) => obj.anchor_id);
      let new_fix_str = "";
      if (a_list.length > 0) {
        new_fix_str = `T:${tid}->Anchors:` + a_list.join(",");
      } else {
        new_fix_str = `T:${tid}->Gone`;
      }
      fixes_list.push(new_fix_str);
    });
    const fixes_str = fixes_list.join("; ");
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {fixes_str}
      </>
    );
  };

  const [sortField, setSortField] = useState("anchor_id");
  const [sortOrder, setSortOrder] = useState(1);
  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
  };

  return (
    <div className="grid table-demo">
      <div className="col-12">
        <div className="card">
          {/* https://www.primefaces.org/primereact/datatable/ */}
          <DataTable
            className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
            header={anchor_table_header}
            value={anchor_table}
            dataKey="_id"
            loading={anchor_loading}
            emptyMessage="No items found."
            globalFilter={anchor_filter}
            // scrollable scrollHeight="600px"
            //
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
          
            rowHover
            // showGridlines={true} // <== jdg:already set with: className="p-datatable-gridlines"
            size="small"
            // columnResizeMode="fit"
            autoLayout="true"
            resizableColumns
            //
          >
            {anchor_show_id && <Column field="id" header="ID" sortable body={body_template}></Column>}
            <Column field="anchor_id" header="Anchor-ID" sortable body={body_template}></Column>
            <Column field="display_name" header="Display Name" sortable body={body_template}></Column>
           
            <Column field="time" header="LoraWAN freq" sortable body={lw_freq_template}></Column>
            <Column field="time" header="LoraWAN GWs" sortable body={lw_gw_template}></Column>
           
            <Column field="time_str" header="Last update" sortable body={body_template}></Column>
            <Column field="repstring" header="Repstring" sortable body={body_template}></Column>
            <Column field="tokens" header="Tokens" sortable body={tokens_template} style={{ width: "40%" }}></Column>
            <Column field="sensors" header="Sensors" sortable body={sensors_template} style={{ width: "40%" }}></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
}; 