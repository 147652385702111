//= ./src/components/common/TableTemplate.jsx

// eslint-disable-next-line

// npm install prop-types --save // https://www.npmjs.com/package/prop-types // https://reactjs.org/docs/typechecking-with-proptypes.html
// import PropTypes from 'prop-types'; // ES6
// var PropTypes = require('prop-types'); // ES5 with npm
// var PropTypes = require('prop-types');
// usage:
// MyComponent.propTypes = {
//   optionalArray: PropTypes.array,
//   optionalBigInt: PropTypes.bigint,
//   optionalBool: PropTypes.bool,
//   optionalFunc: PropTypes.func,
//   optionalNumber: PropTypes.number,
//   optionalObject: PropTypes.object,
//   optionalString: PropTypes.string,
// };

import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames"; // https://www.npmjs.com/package/classnames // https://github.com/JedWatson/classnames#readme
// usage: classNames('foo', { bar: true }); // => 'foo bar'
import { DataTable } from "primereact/datatable"; // https://www.primefaces.org/primereact/datatable
import { Column } from "primereact/column";
import { Toast } from "primereact/toast"; // https://www.primefaces.org/primereact/toast
import { Button } from "primereact/button"; // https://www.primefaces.org/primereact/button/
// import { FileUpload } from 'primereact/fileupload';
import { Rating } from "primereact/rating";
// import { Toolbar } from 'primereact/toolbar';
import { InputText } from "primereact/inputtext"; // https://www.primefaces.org/primereact/inputtext/  // https://devdocs.io/html/element/input
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber"; // https://www.primefaces.org/primereact/inputnumber/
import { Dialog } from "primereact/dialog"; // https://www.primefaces.org/primereact/dialog/
// import { Checkbox } from 'primereact/checkbox';
import { MultiSelect } from "primereact/multiselect"; // https://www.primefaces.org/primereact/multiselect/
import { Dropdown } from "primereact/dropdown";
//
import axios from "axios";
import _ from "lodash";

//
import { f_get_substring_length, f_date_to_nicedatetime, f_date_to_nicetime, f_nr_to_padzero_string, f_js, f_get_epoch_now } from "../../lib/functions";
import TokenPill from "../TokenPill";
import { PickList } from "primereact/picklist";

// - - - - - - = = = - - - - - -
// export Component
export const TableTemplate = ({ model, v_state_items, f_setstate_items, v_state_api_error }) => {
  //
  // PARAMS:
  // <TableTemplate
  //    v_state_items={v_state_items}
  //    f_setstate_items={f_setstate_items}
  //    model={model}
  //    v_state_api_error={v_state_api_error}
  // />;
  //
  // NOTE: all external-data (API) interactions are exclusively done via:
  // model.fa_model_get_items
  // model.fa_model_set_item
  //
  // const pl_source_demo = Array(24)
  //   .fill(0)
  //   .map((_, i) => {
  //     const tpid = `A-${f_nr_to_padzero_string(i, 4)}`;
  //     return {
  //       id: i,
  //       tpid: tpid,
  //       display_name: `Group ${tpid}`,
  //     };
  //   });

  // - - - - - - = = = - - - - - -
  // State
  const [item, f_setstate_item] = useState(model.new_item_template);
  // const [v_state_items, f_ui_set_items] = useState(null);
  const [selected_items, f_setstate_selected_items] = useState(null);
  const [itemShowDialog, f_setstate_itemShowDialog] = useState(false);
  const [deleteSingleItemShowDialog, f_setstate_delete_single_item_show_dialog] = useState(false);
  const [deleteManyItemsShowDialog, f_setstate_delete_many_items_show_dialog] = useState(false);
  const [submitted, f_setstate_submitted] = useState(false);
  const [globalFilter, f_setstate_global_filter] = useState(null);
  const [showid, f_setstate_showid] = useState(false);
  const [last_update_time, f_setstate_last_update_time] = useState(Date.now());
  const [sortField, f_setstate_sort_field] = useState(model.initial_sort_field);
  const [sortOrder, f_setstate_sort_order] = useState(1);
  //
  // const [v_state_multiselect, f_setstate_multiselect] = useState([]);
  const [v_state_options_multiselect, f_setstate_options_multiselect] = useState([]);
  //
  // const [v_state_dropdown, f_setstate_dropdown] = useState([]);
  const [v_state_options_dropdown, f_setstate_options_dropdown] = useState([]);

  //
  // const [pl_source, f_setstate_pl_source] = useState(pl_source_demo);
  // const [pl_target, f_setstate_pl_target] = useState();
  //
  const toast_ref = useRef(null);
  const datatable_ref = useRef(null);

  const f_get_query_method_path_from_req = (req) => {
    // const parsed_url = url.parse(req.url, true);
    // const parsed_url = url.parse(req.originalUrl, true);
    // const path = parsed_url.path; // parsed_url.path =is_same_as?= req.url
    const path = req.url || "?";
    const method = req.method ? req.method.toUpperCase() : "?";
    const query_method_path = `${method} ${path}`;
    return query_method_path;
  };

  // - - - - - - = = = - - - - - -
  // Local vars (non state):

  let local_model = {};
  let v_glob_fields_data = {};

  // - - - - - - = = = - - - - - -
  // API logic:

  const f_get_err_message_from_axios_error = (error) => {
    let err_message = "Unknown error (code:PCDF)";
    if (error.response) {
      const data_response = (error.response.data && (error.response.data.response || error.response.data.error || error.response.data.message)) || "<None>";
      err_message = `Error API status-code ${error.response.status} response-msg: ${data_response}`;
    } else if (error.request) {
      const query_method_path = f_get_query_method_path_from_req(error.request);
      err_message = `No response from API on request ${query_method_path}`;
    } else {
      if (error.message) {
        err_message = error.message;
      } else {
        err_message = f_js(error);
      }
    }
    return err_message;
  };

  const fa_model_get_items = async function () {
    let get_items = undefined;
    try {
      const response = await axios.get(model.api_url, {}, { timeout: model.api_timeout_ms });
      get_items = response.data && response.data[model.api_resource_name] ? response.data[model.api_resource_name] : [];
      f_setstate_items(get_items); // ==> this will cause a re-render, and the Controlled-Child-Component (CCC) is informed of 'v_state_items' via props : v_state_items={v_state_items}
    } catch (error) {
      //
      // // - - - - - - = = = - - - - - -
      // // from: https://axios-http.com/docs/handling_errors
      // if (error.response) {
      //   // The request was made and the server responded with a status code that falls out of the range of 2xx
      //   console.log(error.response.data); // = body of HTTP-response
      //   console.log(error.response.status); // = HTTP status code
      //   console.log(error.response.headers);
      // } else if (error.request) {
      //   // The request was made but no response was received
      //   // `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
      //   // info: https://nodejs.org/api/http.html
      //   console.log(error.request);
      // } else {
      //   // Something happened in setting up the request that triggered an Error
      //   console.log("Error", error.message);
      // }
      // console.log(error.config);
      // console.log(error.toJSON()); // <== Using toJSON you get an object with more information about the HTTP error.
      // // - - - - - - = = = - - - - - -
      //
      const err_message = f_get_err_message_from_axios_error(error);
      //
      // throw new Error(f_setstate_api_error("Whoops"));
      throw new Error(err_message);
    }
    //
  };

  const fa_model_set_item = async function ({ action, item }) {
    const func_name = "fa_model_set_item";
    //
    let updated_item = undefined;
    // console.log(`${func_name} action=${action}`);
    //
    return new Promise(async (resolve, reject) => {
      try {
        let this_item = item || {};
        let response = undefined;
        let api_url_item_id = this_item && this_item.id ? model.api_url + "/" + this_item.id : "";
        switch (action) {
          case "new":
            response = await axios.post(model.api_url, this_item, { timeout: model.api_timeout_ms });
            break;
          case "update":
            if (!api_url_item_id) throw new Error("no ID present in item");
            response = await axios.put(api_url_item_id, this_item, { timeout: model.api_timeout_ms });
            break;
          case "deleteOne":
            if (!api_url_item_id) throw new Error("no ID present in item");
            response = await axios.delete(api_url_item_id, this_item, { timeout: model.api_timeout_ms });
            break;
          case "deleteMany":
            throw new Error("'deleteMany' not implemented");
          // response = await axios.post(model.api_url, item, { timeout: model.api_timeout_ms });
          // break;
          default:
            throw new Error("unknown action");
        }
        //
        await local_model.fa_model_get_items();
        //
        updated_item = response && response.data && response.data[model.api_resource_name] ? response.data[model.api_resource_name] : {};
        resolve(updated_item);
        //
      } catch (error) {
        //
        const err_message = f_get_err_message_from_axios_error(error);
        //
        reject(new Error(err_message));
      }
    });
    //
  }; // \const fa_model_set_item = async function ({ action, item }) {}

  // model.fa_model_get_items = fa_model_get_items;
  // model.fa_model_set_item = fa_model_set_item;
  local_model.fa_model_get_items = fa_model_get_items;
  local_model.fa_model_set_item = fa_model_set_item;

  // - - - - - - = = = - - - - - -
  // local logic

  useEffect(() => {
    //
    async function fa_wrapper() {
      //
      await fa_ui_refresh_api();
      await fa_ui_refresh_fields_data();
      //
      if (model.auto_refresh_secs) {
        //
        const interval = setInterval(async () => {
          await fa_ui_refresh_api();
        }, model.auto_refresh_secs * 1000);
        //
        // return a function that is to be run at 'componentWillUnmount':
        return () => {
          clearInterval(interval); // undo 'setInterval()'
        };
      }
    }
    fa_wrapper();
    //
    // eslint-disable-next-line
  }, []);

  const fa_ui_refresh_api = async () => {
    try {
      await local_model.fa_model_get_items(); // this will (re)set the 'v_state_items' and therefor rerender here
      f_setstate_last_update_time(Date.now());
      //
    } catch (err) {
      const err_message = err.message || "Unknown Error (from:TT)";
      // toast_ref.current.show({ severity: "error", summary: "Error", detail: v_state_api_error, life: model.toast_life_ms });
      toast_ref.current.show({ severity: "error", summary: "Error", detail: err_message, life: model.toast_life_ms });
    }
  };

  const fa_ui_refresh_fields_data = async () => {
    const func_name = "fa_ui_refresh_fields_data";
    //
    // model.fields.forEach((field_obj) => {
    if (true) {
      // (model.item_name_plural === "configmessages") {
      //
      for (let field_obj of model.fields) {
        // field_obj.api_list_endpoint: api_base_url + api_api_uri + "v3/anchor",
        // field_obj.api_list_resource_name: "anchors",
        //
        const field_obj_copy__PageConfigMessages_anchors = {
          field: "anchors",
          label: "Target Anchor Set",
          dialog_type: "select-multiple-api-list",
          api_list_endpoint: `api_base_url + api_api_uri + "v3/anchor?select=tpid,display_name"`,
          api_list_resource_name: "anchors",
          api_list_field_name: "tpid",
          api_list_field_desc: "display_name",
          api_list_field_key: "tpid",
          focus: false,
          table_type: "string",
          required: true,
          sortable: false,
          wrap: false,
        };
        //
        const field_name = field_obj.field || "";
        const api_list_resource_name = field_obj.api_list_resource_name || "";
        const api_list_endpoint = field_obj.api_list_endpoint || "";
        const api_list_field_desc = field_obj.api_list_field_desc || "";
        const api_list_field_name = field_obj.api_list_field_name || "";
        const api_list_field_key = field_obj.api_list_field_key || "";
        //
        if (field_name && api_list_resource_name && api_list_endpoint && api_list_resource_name && api_list_field_name && api_list_field_key) {
          //
          // NOTE: for_now just do this for: ./src/pages/PageConfigMessages.jsx { field: "anchors", label: "Target Anchor Set" }
          if (api_list_resource_name === "anchors") {
            //
            let api_items = [];
            let api_error = "";
            try {
              //
              // console.log(`# api_list_endpoint=${api_list_endpoint}`);
              const response = await axios.get(api_list_endpoint, {}, { timeout: model.api_timeout_ms });
              //
              api_items = response.data && response.data[api_list_resource_name] ? response.data[api_list_resource_name] : [];
              // console.log(`api_items=${JSON.stringify(api_items, null, 2)}`);
            } catch (err) {
              api_error = f_get_err_message_from_axios_error(err);
            }
            // return { api_items, api_error };
            // v_glob_fields_data[field_name] = {};
            // v_glob_fields_data[field_name].api_items = api_items;
            // v_glob_fields_data[field_name].api_error = api_error;
            //
            if (true) {
              //
              // const api_items = field_data_hash.api_items || [];
              // const api_error = field_data_hash.api_error || "";
              // let v_state_options_multiselect = [];
              // //
              // if (api_error) {
              //   field_templ = (
              //     <div className="field-radiobutton col-12">
              //       <p>{api_error}</p>
              //     </div>
              //   );
              // } else {
              //   if (api_items && Array.isArray(api_items)) {
              //     api_items.forEach((item) => {
              //       let this_name = item[field_obj.api_list_field_name];
              //       if (field_obj.api_list_field_desc) {
              //         this_name = `${this_name} -- ${field_obj.api_list_field_desc}`;
              //       }
              //       v_state_options_multiselect.push({
              //         name: this_name,
              //         code: item[field_obj.api_list_field_key],
              //       });
              //     });
              //   }
              //
              // const [v_state_options_multiselect, f_setstate_options_multiselect] = useState([]);
              //
              let options_multiselect = [];
              api_items.forEach((item) => {
                //
                let this_name = item[api_list_field_name] || "";
                // if (field_obj.api_list_field_desc) {
                //   this_name = `${this_name} -- ${field_obj.api_list_field_desc}`;
                // }
                const this_code = item[api_list_field_key] || "";
                //
                if (this_name && this_code) {
                  options_multiselect.push({
                    name: this_name,
                    code: this_code,
                  });
                }
                //
              });
              // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
              // "The sort() method sorts the elements of an array in place and returns the reference to the same array, now sorted."
              options_multiselect.sort((a, b) => (a.name > b.name ? 1 : -1));
              // console.log(`# ${func_name}() options_multiselect=${JSON.stringify(options_multiselect, null, 2)}`);
              // # fa_ui_refresh_fields_data() options_multiselect=[
              //   {
              //     "name": "A-0032",
              //     "code": "A-0032"
              //   },
              //   {
              //     "name": "A-0038",
              //     "code": "A-0038"
              //   },
              // ]
              // console.log(`options_multiselect=`, options_multiselect);
              f_setstate_options_multiselect(options_multiselect);
              //
            }
          } // \if (api_list_resource_name === "anchors") {}
          //
          if (api_list_resource_name === "configtypes") {
            //
            let api_items = [];
            let api_error = "";
            try {
              const response = await axios.get(api_list_endpoint, {}, { timeout: model.api_timeout_ms });
              api_items = response.data && response.data[api_list_resource_name] ? response.data[api_list_resource_name] : [];
              // console.log(`api_items=${JSON.stringify(api_items, null, 2)}`);
            } catch (err) {
              api_error = f_get_err_message_from_axios_error(err);
            }
            if (true) {
              let options_multiselect = [];
              api_items.forEach((item) => {
                let this_name = item[field_obj.api_list_field_name] || "";
                const this_code = item[field_obj.api_list_field_key] || "";
                if (this_name && this_code) {
                  options_multiselect.push({
                    name: this_name,
                    code: this_code,
                  });
                }
                //
              });
              f_setstate_options_dropdown(options_multiselect.sort());
            }
          }
          //
        } // \if (field_name && api_list_resource_name && api_list_endpoint && api_list_resource_name) {}
        //
      } // \for (let field_obj of model.fields) {}
      //
    } // \if (model.item_name_plural === "configmessages") {}
    //
  }; // \const fa_ui_refresh_fields_data = async () => {}

  // const f_ui_format_currency = (value) => {
  //   return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  // };

  const f_setstate_itemShowDialog_wrapper = (new_value) => {
    // if (false && new_value === true) {
    //   model.fields.forEach((field_obj) => {
    //     if ((field_obj.field = "members")) {
    //       if (field_obj.f_get_current_items && typeof field_obj.f_get_current_items === "function") {
    //         const f_get_current_items = field_obj.f_get_current_items();
    //         f_setstate_pl_source(f_get_current_items);
    //       }
    //     }
    //   });
    // }
    f_setstate_itemShowDialog(new_value);
  };

  const f_ui_open_new = () => {
    f_setstate_item(model.new_item_template);
    f_setstate_submitted(false);
    f_setstate_itemShowDialog_wrapper(true);
  };

  const f_ui_hide_dialog = () => {
    f_setstate_submitted(false);
    f_setstate_itemShowDialog_wrapper(false);
  };

  const f_ui_hide_delete_single_item_dialog = () => {
    f_setstate_delete_single_item_show_dialog(false);
  };

  const f_ui_hide_delete_many_items_dialog = () => {
    f_setstate_delete_many_items_show_dialog(false);
  };

  const f_convert_hash_to_list = (hash, key) => {
    const my_list = hash.map((itm) => itm[key]);
    return my_list;
  };

  const f_convert_list_to_hash_with_keys = (list, list_of_key) => {
    const my_hash_list = [];
    list.forEach((itm) => {
      const new_hash_item = {};
      list_of_key.forEach((key) => {
        new_hash_item[key] = itm;
      });
      my_hash_list.push(new_hash_item);
    });
    return my_hash_list;
  };

  const f_ui_save_item = async () => {
    const func_name = "f_ui_save_item";
    //
    f_setstate_submitted(true);
    let new_item = { ...item };
    let updated_item = undefined;
    // if empty:
    try {
      if (new_item[model.main_field].trim()) {
        //
        if (model.item_name_plural === "configmessages") {
          // set new_time for both case of: new, edit
          new_item.time = f_get_epoch_now();
          //
          // new_item.anchors = v_state_multiselect;
          // new_item.configtype_id = v_state_dropdown;
          //
          // const new_item_anchors = new_item.anchors.map((itm) => itm.name);
          // new_item.anchors = new_item_anchors;
          new_item.anchors = f_convert_hash_to_list(new_item.anchors, "name");
          //
        }
        // console.log(`${func_name} new_item=${f_js(new_item)}`);
        //
        if (new_item && new_item.id) {
          updated_item = await local_model.fa_model_set_item({ action: "update", item: new_item });
          toast_ref.current.show({ severity: "success", summary: "Successful", detail: model.item_name_singular + " updated", life: model.toast_life_ms });
        } else {
          updated_item = await local_model.fa_model_set_item({ action: "new", item: new_item });
          toast_ref.current.show({ severity: "success", summary: "Successful", detail: model.item_name_singular + " created", life: model.toast_life_ms });
        }
      }
    } catch (err) {
      // toast_ref.current.show({ severity: "error", summary: "Error", detail: model.item_name_singular + " not updated", life: model.toast_life_ms });
      // toast_ref.current.show({ severity: "error", summary: "Error", detail: `${model.item_name_singular} not updated with error-msg: ${err.message}`, sticky: true });
      toast_ref.current.show({ severity: "error", summary: "Error", detail: `${model.item_name_singular} not updated with error-msg: ${err.message}`, life: model.toast_life_ms * 4 });
    }
    f_setstate_itemShowDialog_wrapper(false);
    f_setstate_item(model.new_item_template);
  };

  const f_ui_edit_item = (item) => {
    let edit_item = { ...item };
    //
    if (model.item_name_plural === "configmessages") {
      edit_item.anchors = f_convert_list_to_hash_with_keys(edit_item.anchors, ["name", "code"]);
    }
    //
    f_setstate_item(edit_item);
    f_setstate_itemShowDialog_wrapper(true);
  };

  const f_ui_confirm_single_delete_item = (item) => {
    f_setstate_item(item);
    f_setstate_delete_single_item_show_dialog(true);
  };

  const f_ui_delete_single_item = async () => {
    const selected_item = { ...item };
    try {
      const updated_item = await local_model.fa_model_set_item({ action: "deleteOne", item: selected_item });
      // const new_items = model.f_api_delete_single_item({ v_state_items, item });
      // model.fa_model_set_item(new_items);
      toast_ref.current.show({ severity: "success", summary: "Successful", detail: model.item_name_singular + " deleted", life: model.toast_life_ms });
    } catch (err) {
      // toast_ref.current.show({ severity: "error", summary: "Error", detail: `${model.item_name_singular} not deleted with error: ${err.message}`, life: model.toast_life_ms });
      // toast_ref.current.show({ severity: "error", summary: "Error", detail: `${model.item_name_singular} not deleted with error-msg: ${err.message}`, sticky: true });
      toast_ref.current.show({ severity: "error", summary: "Error", detail: `${model.item_name_singular} not deleted with error-msg: ${err.message}`, life: model.toast_life_ms * 4 });
    }
    f_setstate_delete_single_item_show_dialog(false);
    f_setstate_item(model.new_item_template);
  };

  // const exportCSV = () => {
  //   datatable_ref.current.exportCSV();
  // };

  const f_ui_confirm_delete_selected = () => {
    f_setstate_delete_many_items_show_dialog(true);
  };

  const f_ui_delete_selected_items = async () => {
    try {
      const updated_item = await local_model.fa_model_set_item({ action: "deleteMany", item: selected_items });
      // const new_items = model.f_api_delete_many_items({ v_state_items, selected_items });
      // model.fa_model_set_item(new_items);
      toast_ref.current.show({ severity: "success", summary: "Successful", detail: model.item_name_plural + " deleted", life: model.toast_life_ms });
    } catch (err) {
      // toast_ref.current.show({ severity: "error", summary: "Error", detail: model.item_name_plural + " not deleted", life: model.toast_life_ms });
      // toast_ref.current.show({ severity: "error", summary: "Error", detail: `${model.item_name_plural} not deleted with error: ${err.message}`, life: model.toast_life_ms });
      // toast_ref.current.show({ severity: "error", summary: "Error", detail: `${model.item_name_plural} not deleted with error: ${err.message}`, sticky: true });
      toast_ref.current.show({ severity: "error", summary: "Error", detail: `${model.item_name_plural} not deleted with error: ${err.message}`, life: model.toast_life_ms * 4 });
    }
    f_setstate_delete_many_items_show_dialog(false);
    f_setstate_selected_items(null);
  };

  const f_ui_on_category_change = (e) => {
    let new_item = { ...item };
    new_item["category"] = e.value;
    f_setstate_item(new_item);
  };

  const f_ui_on_input_change = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let new_item = { ...item };
    new_item[`${name}`] = val;
    
    f_setstate_item(new_item);
  };

  const f_ui_on_input_number_change = (e, name) => {
    const val = e.value || 0;
    let new_item = { ...item };
    new_item[`${name}`] = val;

    f_setstate_item(new_item);
  };

  const f_ui_open_api = () => {
    window.open(model.api_url);
  };

  const f_ui_click_refresh_api = async () => {
    await fa_ui_refresh_api();
    await fa_ui_refresh_fields_data();
  };

  const f_ui_on_sort = (e) => {
    f_setstate_sort_field(e.sortField);
    f_setstate_sort_order(e.sortOrder);
  };

  // - - - - - - = = = - - - - - -
  // Templates:

  const f_format_currency = (value) => {
    return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
  };

  // const leftToolbarTemplate = () => {
  //   return (
  //     <React.Fragment>
  //       <div className="my-2">
  //         <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={f_ui_open_new} />
  //         <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={f_ui_confirm_delete_selected} disabled={!selected_items || !selected_items.length} />
  //       </div>
  //     </React.Fragment>
  //   );
  // };

  // const rightToolbarTemplate = () => {
  //   return (
  //     <React.Fragment>
  //       <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
  //       <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
  //     </React.Fragment>
  //   );
  // };

  const c_ui_table_header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">{model.table_title}</h5>
      <div className="my-2">
        {model.show_showid_button && (
          // <Button label="Show IDs" icon={classNames('pi', { 'pi-minus-circle': showid, 'pi-check-circle': !showid })} onClick={() => f_setstate_showid(!showid)} className="p-button-sm p-button-warning mr-2" />
          <Button label="Show IDs" icon="pi pi-id-card" onClick={() => f_setstate_showid(!showid)} className={classNames("p-button-sm mr-2 my-2", { "p-button-blue": showid, "p-button-secondary": !showid })} />
          // <div className="field-checkbox">
          //   <Checkbox inputId="checkShowIDs_mqtt" name="option" value="true" checked={showid} onChange={() => f_setstate_showid(!showid)} />
          //   <label htmlFor="checkShowIDs_mqtt">show IDs</label>
          // </div>
        )}
        {model.show_openapi_button && <Button label="Open API" icon="pi pi-external-link" onClick={f_ui_open_api} className="p-button-sm p-button-warning mr-2 my-2" />}
        {model.show_refresh_button && <Button label={model.refresh_button_label || "Refresh"} icon="pi pi-refresh" onClick={f_ui_click_refresh_api} className="p-button-sm p-button-warning mr-2 my-2" />}
        {model.auto_refresh_secs > 0 && (
          <Button label={"Auto Refresh (" + model.auto_refresh_secs + " sec) [" + f_date_to_nicetime(last_update_time) + "]"} icon="pi pi-refresh" onClick={f_ui_click_refresh_api} className="p-button-sm p-button-warning mr-2 my-2" />
        )}
        {model.operations.includes("new") && <Button label="New" icon="pi pi-plus" className="p-button-sm p-button-success ml-4 mr-2 my-2" onClick={f_ui_open_new} />}
        {model.operations.includes("delete") && <Button label="Delete" icon="pi pi-trash" className="p-button-sm p-button-danger my-2" onClick={f_ui_confirm_delete_selected} disabled={!selected_items || !selected_items.length} />}
      </div>

      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          // dialog_type="search"
          type="search"
          onInput={(e) => f_setstate_global_filter(e.target.value)}
          placeholder="Search..."
        />
      </span>
      {/* <Button
          icon="pi pi-icon p-c pi-times-circle"
          className="p-button-rounded"
          onClick={() => {
            f_setstate_global_filter(null);
          }}
          disabled={!globalFilter || !globalFilter.length}
        /> */}
    </div>
  ); // \const c_ui_table_header = ()

  const f_ui_datatable_field_template = (data, props) => {
    const my_header = props.header;
    const my_field = props.field;
    const field_obj = model.fields.find((obj) => obj.field === my_field);
    const table_type = field_obj.table_type;
    let my_data = data[my_field];
    if (my_data && Array.isArray(my_data)) {
      my_data = my_data.sort();
    }
    let my_data_formatted = my_data;
    // switch (my_field) {
    //   case 'code':
    //   case 'name':
    //   case 'category':
    //     break;
    //   case 'image':
    //     my_data_formatted = <img src={`${model.image_path}/${my_data}`} alt={my_data} className="shadow-2" width="100" />;
    //     break;
    //   case 'price':
    //     my_data_formatted = f_format_currency(my_data);
    //     break;
    //   case 'rating':
    //     my_data_formatted = <Rating value={my_data} readOnly cancel={false} />;
    //     break;
    //   case 'inventoryStatus':
    //     // JDG-note: ./src/App.scss : status-instock , status-outofstock , status-lowstock
    //     my_data_formatted = <span className={`item-badge status-${my_data.toLowerCase()}`}>{my_data}</span>;
    //     break;
    //   default:
    //     break;
    // }
    switch (table_type) {
      case "image":
        my_data_formatted = <img src={`${model.image_path}/${my_data}`} alt={my_data} className="shadow-2" width="100" />;
        break;
      case "currency":
        my_data_formatted = f_format_currency(my_data);
        break;
      case "rating":
        my_data_formatted = <Rating value={my_data} readOnly cancel={false} />;
        break;
      case "status":
        // JDG-note: ./src/App.scss : status-instock , status-outofstock , status-lowstock
        my_data_formatted = <span className={`item-badge status-${my_data.toLowerCase()}`}>{my_data}</span>;
        break;
      case "tpid":
        my_data_formatted = <TokenPill tpid={my_data} />;
        break;
      case "array-tpid":
        my_data_formatted = (
          <p style={{ width: "80%", whiteSpace: "normal" }}>
            {my_data.map((tpid) => {
              return <TokenPill key={tpid} tpid={tpid} />;
            })}
          </p>
        );
        break;
      case "array-string":
        my_data_formatted = my_data.join(",");
        break;
      case "array-string-space":
        my_data_formatted = my_data.join(", ");
        break;
      case "object-path":
        // table_type: "object_path", // _.get(field_obj, 'key1.key2', 'optionalDefaultValue');
        // object_path: "configtype.display_name",
        const object_path = field_obj.object_path || "";
        my_data_formatted = object_path ? _.get(data, object_path, my_data) : my_data;
        break;
      case "bool":
        my_data_formatted = my_data ? "true" : "false";
        break;
      case "epoch_to_nicedatetime":
        my_data_formatted = my_data ? f_date_to_nicedatetime(my_data * 1000) : "";
        break;
      case "string":
      default:
        my_data_formatted = my_data;
        break;
    }
    return (
      <>
        <span className="p-column-title">{my_header}</span>
        {my_data_formatted}
      </>
    );
  }; // \const f_ui_datatable_field_template = (data, props) => {}

  const f_ui_column_template = (field) => {
    const field_obj = model.fields.find((obj) => obj.field === field);
    let this_style = {};
    if (field_obj.wrap) this_style.whiteSpace = "normal";
    return (
      <Column
        field={field}
        header={field_obj.label}
        sortable={field_obj.sortable}
        body={f_ui_datatable_field_template}
        // style={{ width: "80%", whiteSpace: "normal" }}
        style={this_style}
        key={field}></Column>
    );
  };

  const f_ui_action_body_template = (rowData) => {
    return (
      <div className="actions">
        {model.operations.includes("view") && <Button icon="pi pi-eye" className="p-button-rounded p-button-success mr-2" onClick={() => f_ui_edit_item(rowData)} tooltip="View" tooltipOptions={{ position: "top" }} />}
        {model.operations.includes("edit") && <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => f_ui_edit_item(rowData)} tooltip="Edit" tooltipOptions={{ position: "top" }} />}
        {model.operations.includes("delete") && <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => f_ui_confirm_single_delete_item(rowData)} tooltip="Delete" tooltipOptions={{ position: "top" }} />}
      </div>
    );
  };

  // const fa_ui_dialog_field_template = async ({ field_obj }) => {
  const f_ui_dialog_field_template = ({ field_obj }) => {
    let field_templ = undefined;
    if (!field_obj) return null;
    const field_name = field_obj.field || "";
    if (!field_name) return null;
    let item_value = item[field_name] || null;
    // console.log(`# f_ui_dialog_field_template() item_value=`, item_value);
    let item_value_join = undefined;
    switch (field_obj.dialog_type) {
      case "text":
        field_templ = <InputTextarea id={field_name} value={item_value} onChange={(e) => f_ui_on_input_change(e, field_name)} required={field_obj.required} rows={field_obj.textarea_rows} cols={field_obj.textarea_cols} />;
        break;
      case "select-one-api-list":
        field_templ = (
          <Dropdown
            optionLabel="name"
            optionValue="code"
            //
            // value={v_state_dropdown} // const [v_state_dropdown, f_setstate_dropdown] = useState([]);
            // onChange={(e) => f_setstate_dropdown(e.value)}
            //
            value={item_value}
            onChange={(e) => f_ui_on_input_change(e, field_name)}
            //
            options={v_state_options_dropdown} // const [v_state_options_dropdown, f_setstate_options_dropdown] = useState([]);
            style={{ width: "100%" }}
            filter="true"
            placeholder="Select a ConfigType..."
          />
        );
        break;
      case "select-multiple-api-list":
        if (item_value && Array.isArray(item_value)) {
          item_value = item_value.sort();
        }
        field_templ = (
          <div className="field-radiobutton col-12">
            <MultiSelect
              // https://www.primefaces.org/primereact/multiselect/
              id={field_name}
              display="chip"
              //
              // value={v_state_multiselect} // const [v_state_multiselect, f_setstate_multiselect] = useState([]);
              // onChange={(e) => f_setstate_multiselect(e.value)}
              //
              value={item_value}
              onChange={(e) => f_ui_on_input_change(e, field_name)}
              //
              optionLabel="name"
              optionValue="code"
              options={v_state_options_multiselect} // const [v_state_options_multiselect, f_setstate_options_multiselect] = useState([]);
              //
              style={{ width: "100%" }}
              filter="true"
              // filterBy="label"
              placeholder="Select one or more Anchors..."
            />
            {/* Temp_test */}
          </div>
        );
        //
        break;
      case "image":
        return (field_templ = item_value && <img src={`assets/demo/images/item/${item_value}`} alt={item_value} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />);
      case "enum":
        field_templ = (
          <div className="formgrid grid">
            {field_obj.enums.map((cat, index) => {
              return (
                <div className="field-radiobutton col-6" key={index}>
                  <RadioButton inputId={`${field_name}_${index}`} name={field_name} value={cat} onChange={f_ui_on_category_change} checked={item.category === cat} />
                  <label htmlFor={`${field_name}_${index}`}>{cat}</label>
                </div>
              );
            })}
          </div>
        );
        break;
      case "integer":
        field_templ = (
          <InputNumber
            id={field_name}
            value={item_value}
            onChange={(e) => f_ui_on_input_number_change(e, field_name)}
            required={field_obj.required}
            autoFocus={field_obj.focus}
            // OLD:
            // integeronly={field_obj.dialog_type === "integer"}
            // integeronly
            // NEW: see https://www.primefaces.org/primereact/inputnumber/
            mode="decimal"
            className={classNames({ "p-invalid": field_obj.required && submitted && !item_value })}
          />
        );
        break;
      case "currency":
        field_templ = (
          <InputNumber
            id={field_name}
            value={item_value}
            onChange={(e) => f_ui_on_input_number_change(e, field_name)}
            required={field_obj.required}
            autoFocus={field_obj.focus}
            mode="currency"
            currency={field_obj.currency}
            locale={field_obj.locale}
            className={classNames({ "p-invalid": field_obj.required && submitted && !item_value })}
          />
        );
        break;
      case "array-tpid":
        // if (field_obj.f_get_current_items && typeof field_obj.f_get_current_items === "function") {
        //   const f_get_current_items = field_obj.f_get_current_items();
        //   f_setstate_pl_source(f_get_current_items);
        // }
        field_templ = (
          <div style={{ margin: "4px", padding: "4px", border: "1px solid lightgray" }}>
            {/* <PickList
              style={{ margin: "12px" }}
              id={field_name}
              source={pl_source}
              target={pl_target}
              itemTemplate={f_ui_pl_item_template}
              onChange={f_ui_pl_on_change}
            /> */}
          </div>
        );
        break;
      case "array-string":
        item_value = item_value ? item_value.join(",") : "";
      // break;
      // eslint-disable-next-line
      case "string":
      case "id":
      default:
        field_templ = (
          <InputText
            id={field_name}
            value={item_value}
            onChange={(e) => f_ui_on_input_change(e, field_name)}
            required={field_obj.required}
            autoFocus={field_obj.focus}
            className={classNames({ "p-invalid": field_obj.required && submitted && !item_value })}
          />
        );
        break;
    }
    return (
      <div className="formgrid grid">
        <label htmlFor={field_name} className="mr-2 mb-2">
          {field_obj.label}
        </label>
        {field_templ}
        {field_obj.required && submitted && !item_value && <small className="p-invalid">{field_obj.label} is required.</small>}
      </div>
    );
    //
  }; // \const fa_ui_dialog_field_template = ({ field_obj }) => {}

  const c_ui_item_dialog_footer = (
    <>
      <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={f_ui_hide_dialog} />
      <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={f_ui_save_item} />
    </>
  );
  const c_ui_delete_single_item_dialog_footer = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={f_ui_hide_delete_single_item_dialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={f_ui_delete_single_item} />
    </>
  );
  const c_ui_delete_many_items_dialog_footer = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={f_ui_hide_delete_many_items_dialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={f_ui_delete_selected_items} />
    </>
  );

  function f_ui_pl_item_template(item) {
    return (
      // <div
      //   class="card"
      //   // style={{ width: "20rem" }}
      // >
      //   <div class="card-body">
      // <p className={"nowrap"}>
      //   <TokenPill tpid={item.tpid} />
      //   {item.display_name}
      // </p>
      //   </div>
      // </div>
      <div
        style={{
          // border: "1px solid lightgray",
          padding: "4px",
        }}>
        <p className={"nowrap"}>
          <TokenPill tpid={item.tpid} />
          {item.display_name}
        </p>
      </div>
    );
  }
  function f_ui_pl_on_change() {
    //
  }

  // const fa_ui_dialog_field_template__field_obj = fa_ui_dialog_field_template({ field_obj });
  // const { api_items, api_error } = f_get_api_list_items({ endpoint: field_obj.api_list_endpoint, resource_name: field_obj.api_list_resource_name });

  // - - - - - - = = = - - - - - -
  // Render logic:
  return (
    <>
      {/* - - - - - - = = = - - - - - - */}
      {/* <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar> */}

      {/* - - - - - - = = = - - - - - - */}
      <Toast ref={toast_ref} />
      {/* {v_state_api_error && toast_ref.current.show({ severity: "error", summary: "Error", detail: v_state_api_error, life: model.toast_life_ms })} */}

      {/* - - - - - - = = = - - - - - - */}
      <DataTable
        ref={datatable_ref}
        value={v_state_items}
        selection={selected_items}
        onSelectionChange={(e) => f_setstate_selected_items(e.value)}
        //
        // OLD: see https://www.primefaces.org/primereact/datatable/
        // dataKey={model.data_key}
        // dataKey="id"
        // rowKey="id"
        // key="id"
        //
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={f_ui_on_sort}
        //
        paginator
        rows={model.rows}
        rowsPerPageOptions={model.rows_per_page_options}
        className="datatable-responsive"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
        globalFilter={globalFilter}
        emptyMessage={model.msg_empty}
        header={c_ui_table_header}>
        <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
        {/* - - - - - - = = = - - - - - - */}
        {/* {showid && <Column field="_id" header="ID" sortable body={model.f_datatable_field_template}></Column>} */}
        {/* {showid && f_ui_column_template("id")} */}
        <Column field="id" key="id" header={model.fields.find((obj) => obj.field === "id").label} sortable={model.fields.find((obj) => obj.field === "id").sortable} body={f_ui_datatable_field_template} hidden={!showid}></Column>
        {/* - - - - - - = = = - - - - - - */}
        {/* <Column field="code" header="Code" sortable body={model.f_datatable_field_template}></Column>
        <Column field="name" header="Name" sortable body={model.f_datatable_field_template}></Column>
        <Column field="image" header="Image" body={model.f_datatable_field_template}></Column>
        <Column field="price" header="Price" body={model.f_datatable_field_template} sortable></Column>
        <Column field="category" header="Category" sortable body={model.f_datatable_field_template}></Column>
        <Column field="rating" header="Reviews" body={model.f_datatable_field_template} sortable></Column>
        <Column field="inventoryStatus" header="Status" body={model.f_datatable_field_template} sortable></Column> */}
        {/* - - - - - - = = = - - - - - - */}
        {/* {model.fields
          .filter((field_obj) => field_obj.show_in_table)
          .map((field_obj) => {
            return <Column field={field_obj.field} header={field_obj.label} sortable={field_obj.sortable} body={model.f_datatable_field_template}></Column>;
          })} */}
        {/* - - - - - - = = = - - - - - - */}
        {model.table_fields_order.map((field) => {
          // const field_obj = model.fields.find((obj) => obj.field === field);
          // return <Column field={field} header={field_obj.label} sortable={field_obj.sortable} body={model.f_datatable_field_template}></Column>;
          let f_ui_column_template_field = f_ui_column_template(field);
          // f_ui_column_template_field.key = "";
          return f_ui_column_template_field;
        })}
        {/* - - - - - - = = = - - - - - - */}
        <Column header="Action" body={f_ui_action_body_template}></Column>
      </DataTable>

      {/* - - - - - - = = = - - - - - - */}
      <Dialog visible={itemShowDialog} style={{ width: model.dialog_width }} header={model.dialog_title} modal className="p-fluid" footer={c_ui_item_dialog_footer} onHide={f_ui_hide_dialog}>
        {/* - - - - - - = = = - - - - - - */}
        {/* https://www.primefaces.org/primeflex/formlayout */}
        {/* https://primefaces.org/primeflex/gridsystem  // <== 12-column system */}
        {/* {model.fields
          .filter((field_obj) => field_obj.show_in_dialog)
          .map((field_obj) => {
            return;
          })} */}
        {/* - - - - - - = = = - - - - - - */}
        {/* {model.dialog_fields_order.map(async (field) => { */}
        {model.dialog_fields_order.map((field) => {
          const field_obj = model.fields.find((obj) => obj.field === field);
          if (!field || !field_obj) return null;
          // let field_data_hash = (field_obj.api_list_endpoint && v_glob_fields_data[field]) || {};
          // const need_grid = field_obj.cols_in_dialog && field_obj.cols_in_dialog !== 12;
          // const fa_ui_dialog_field_template__field_obj = fa_ui_dialog_field_template({ field_obj });
          const fa_ui_dialog_field_template__field_obj = f_ui_dialog_field_template({ field_obj });
          return (
            <div className="field" key={field}>
              {fa_ui_dialog_field_template__field_obj}
            </div>
          );
        })}
        {/* - - - - - - = = = - - - - - - */}
        {/* {f_ui_dialog_template(model.fields)} */}
        {/* - - - - - - = = = - - - - - - */}
        {/* {item.image && <img src={`assets/demo/images/item/${item.image}`} alt={item.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}

        <div className="field">
          <label htmlFor="name">Name</label>
          <InputText id="name" value={item.name} onChange={(e) => f_ui_on_input_change(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !item.name })} />
          {submitted && !item.name && <small className="p-invalid">Name is required.</small>}
        </div>

        <div className="field">
          <label htmlFor="description">Description</label>
          <InputTextarea id="description" value={item.description} onChange={(e) => f_ui_on_input_change(e, 'description')} required rows={3} cols={20} />
        </div>

        <div className="field">
          <label className="mb-3">{model.fields.find((obj) => obj.field === 'category').label}</label>
          <div className="formgrid grid">
            {model.fields
              .find((obj) => obj.field === 'category')
              .enums.map((cat, index) => {
                return (
                  <div className="field-radiobutton col-6">
                    <RadioButton
                      inputId={`${model.fields.find((obj) => obj.field === 'category').field}_${index}`}
                      name={model.fields.find((obj) => obj.field === 'category').field}
                      value={cat}
                      onChange={f_ui_on_category_change}
                      checked={item.category === cat}
                    />
                    <label htmlFor={`${model.fields.find((obj) => obj.field === 'category').field}_${index}`}>{cat}</label>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="price">Price</label>
            <InputNumber id="price" value={item.price} onValueChange={(e) => f_ui_on_input_number_change(e, 'price')} mode="currency" currency="USD" locale="en-US" />
          </div>

          <div className="field col">
            <label htmlFor="quantity">Quantity</label>
            <InputNumber id="quantity" value={item.quantity} onValueChange={(e) => f_ui_on_input_number_change(e, 'quantity')} integeronly />
          </div>
        </div> */}
      </Dialog>

      {/* - - - - - - = = = - - - - - - */}
      <Dialog visible={deleteSingleItemShowDialog} style={{ width: "450px" }} header={model.msg_confirm_dialog_header} modal footer={c_ui_delete_single_item_dialog_footer} onHide={f_ui_hide_delete_single_item_dialog}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {item && (
            <span>
              {model.msg_are_you_sure_delete_single_item} <b>{item[model.main_field]}</b>?
            </span>
          )}
        </div>
      </Dialog>

      {/* - - - - - - = = = - - - - - - */}
      <Dialog visible={deleteManyItemsShowDialog} style={{ width: "450px" }} header={model.msg_confirm_dialog_header} modal footer={c_ui_delete_many_items_dialog_footer} onHide={f_ui_hide_delete_many_items_dialog}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {item && <span>{model.msg_are_you_sure_delete_multi_item}</span>}
        </div>
      </Dialog>
      {/* - - - - - - = = = - - - - - - */}
    </>
    // - - - - - - = = = - - - - - -
  ); // \return ()
  // - - - - - - = = = - - - - - -
}; // \export const TableTemplate = () => {}

TableTemplate.propTypes = {
  // model: PropTypes.object,
};

//-eof
