//= ./pages/PageAnchorDetail.jsx

// eslint-disable-next-line

import React, { useState, Component, useContext, useEffect } from "react";
import TableAnchor from "../components/TableAnchor";
//
import TableAnchorDetail from "../components/TableAnchorDetail";

/* <Route path="/anchor-detail/:tpid?" component={PageAnchorDetail} /> */

export const PageAnchorDetail = (props) => {
  const params_tpid = (props && props.match && props.match.params && props.match.params.tpid) || undefined;
  return (
    <div className="grid table-demo">
      <div className="col-12">
        <div className="card">
          {params_tpid && (
            <TableAnchorDetail
              params_tpid={params_tpid}
              some="none"
              {...props}
            />
          )}
          {!params_tpid && (
            <TableAnchor
              show_items_limit="all"
              show_columns="only_edit"
              show_live="false"
              {...props}
            />
          )}
        </div>
      </div>
    </div>
  );
}; // \export const PageAnchorDetail = () => {

//-eof
