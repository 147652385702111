//= ./components/TokenMap.jsx

import React, { Component, useState, useContext, useEffect } from "react";

import { TokenMap } from "./TokenMap";

import { f_max_age_ms, f_get_max_age_both } from "../config/config";
let max_age = f_max_age_ms();

// called as: <TokenMapMatrix map_width={map_width} map_height={map_height} matrix_cols={matrix_cols} matrix_rows={matrix_rows} anchor_list={m} />

// const c_matrix_cols = 5;
// const c_matrix_rows = 2;
const c_map_width = 300;
const c_map_height = 200;
// const c_anchors_per_matrix = 6;

let v_matrix_cols = 5;
let v_matrix_rows = 2;

// const anchor_pos_seed = [
//   { anr: 1, label_pos: 'top', posx: '0', posy: '0' },
//   { anr: 2, label_pos: 'top', posx: `${c_map_width}`, posy: '0' },
//   { anr: 3, label_pos: 'top', posx: `${c_map_width * 2}`, posy: '0' },
//   { anr: 4, label_pos: 'bot', posx: '0', posy: `${c_map_height}` },
//   { anr: 5, label_pos: 'bot', posx: `${c_map_width}`, posy: `${c_map_height}` },
//   { anr: 6, label_pos: 'bot', posx: `${c_map_width * 2}`, posy: `${c_map_height}` },
// ];
// console.log("# anchor_pos_seed = ", anchor_pos_seed);

const f_add_anchor_pos_seed = function (an_list) {
  //
  // // { label: "1a01", anr: 1, label_pos: "top", width: "300", height: "200", posx: "0",   posy: "0"   },
  // let anchor_list_processed = an_list.map((an, i) => {
  //   let i_mod = i % c_anchors_per_matrix;
  //   const anchor_pos = anchor_pos_seed[i_mod];
  //   const new_anchor_obj = Object.assign(an, anchor_pos);
  //   new_anchor_obj.label = an.anchor_id;
  //   return new_anchor_obj;
  // });
  //
  const anchors = Array.isArray(an_list) ? an_list : [];
  //
  v_matrix_cols = Math.round(an_list.length / 2);
  //
  let anchor_list_processed = [];
  //
  for (let anr = 1; anr <= v_matrix_cols * v_matrix_rows; anr = anr + 1) {
    //
    const a_i = anr - 1;
    // const anchor_pos = anchor_pos_seed[a_i] || {};
    // const anchor_obj = anchors[a_i] || {};
    // const new_anchor_obj = Object.assign(anchor_obj, anchor_pos);
    // new_anchor_obj.label = anchor_obj.anchor_id || "";
    //
    let anchor_obj = anchors[a_i] || {};
    anchor_obj.anr = anr;
    //
    if (anr <= v_matrix_cols) {
      anchor_obj.label_pos = "top";
      anchor_obj.posy = 0;
    } else {
      // anchor_obj.label_pos = 'bottom';
      anchor_obj.label_pos = "top";
      anchor_obj.posy = c_map_height;
    }
    //
    const rel_posx = (anr - 1) % v_matrix_cols; // relative X position
    anchor_obj.posx = rel_posx * c_map_width;
    //
    // anchor_obj.label = anchor_obj.anchor_id || '';
    anchor_obj.label = anchor_obj.display_name || anchor_obj.anchor_id || "";
    //
    anchor_list_processed.push(anchor_obj);
  }
  //
  return anchor_list_processed;
  //
};

export const TokenMapMatrix = (props) => {
  // useEffect(() => {
  // }, []);

  // <TokenMapMatrix map_width={c_map_width} map_height={c_map_height} matrix_cols={v_matrix_cols} matrix_rows={v_matrix_rows} anchor_list={matrix_slice1} />
  // const { matrix_cols, matrix_rows, map_width, map_height, anchor_list } = props;
  // const matrix_cols = props.matrix_cols;
  // const matrix_rows = props.matrix_rows;
  // const map_width = props.map_width;
  // const map_height = props.map_height;
  // const anchor_list = props.anchor_list;
  //
  // const matrix_width  = map_width   * matrix_cols;
  // const matrix_height = map_height  * matrix_rows;

  // <TokenMapMatrix anchors={anchor_token_table} />
  // const { anchor_list } = props;
  const anchor_list = props.anchors;

  const matrix_width = c_map_width * v_matrix_cols;
  const matrix_height = c_map_height * v_matrix_rows;

  const anchors_seed_list = f_add_anchor_pos_seed(anchor_list);
  // const anchors_seed_list = [];
  // const anchors_seed_list = anchor_pos_seed;
  //
  // const map_list = [];
  // for (   let c = 1 ; c=c+1 ; c <= v_matrix_cols) {
  //   for ( let r = 1 ; r=r+1 ; r <= v_matrix_rows) {
  //     const anr = c + (r-1) * v_matrix_cols ;
  //     map_list.push();
  //   }
  // }
  //

  return (
    // <svg width="900" height="400" viewBox="0 0 900 400" >
    // <svg width={matrix_width} height={matrix_height} viewBox={`0 0 ${matrix_width} ${matrix_height}`} className="red_border" >

    <svg
      width={matrix_width}
      height={matrix_height}
      viewBox={`0 0 ${matrix_width} ${matrix_height}`}
    >
      {anchors_seed_list.map((a_iter) => {
        // <TokenMap key={an.anr} width={an.width} height={an.height} posx={an.posx} posy={an.posy} label_pos={an.label_pos} label={an.label} token_icons={props.token_icons} />

        // const time = a_iter.time || 0;
        // let time_date_obj = new Date(time * 1000);
        // let date_now = Date.now();
        // const is_not_too_old = (time_date_obj > (date_now - max_age) );
        // const token_icons = (a_iter.presence_tlist && is_not_too_old) ? a_iter.presence_tlist.map((token_id, index) => { return {token_id: token_id, seq_nr: index} }) : [] ;

        const token_icons = a_iter.presence_tlist
          ? a_iter.presence_tlist.map((token_id, index) => {
              return { token_id: token_id, seq_nr: index };
            })
          : [];
        // const token_icons = [] ;

        return (
          <TokenMap
            key={a_iter.anr}
            width={c_map_width}
            height={c_map_height}
            posx={a_iter.posx}
            posy={a_iter.posy}
            label_pos={a_iter.label_pos}
            label={a_iter.label}
            token_icons={token_icons}
          />
        );
      })}

      {/* <TokenMap width="300" height="200" posx="0"   posy="0"   label_pos="top" label="Anchor-1" />
      <TokenMap width="300" height="200" posx="300" posy="0"   label_pos="top" label="Anchor-2" />
      <TokenMap width="300" height="200" posx="600" posy="0"   label_pos="top" label="Anchor-3" />
      <TokenMap width="300" height="200" posx="0"   posy="200" label_pos="bot" label="Anchor-4" />
      <TokenMap width="300" height="200" posx="300" posy="200" label_pos="bot" label="Anchor-5" />
      <TokenMap width="300" height="200" posx="600" posy="200" label_pos="bot" label="Anchor-6" /> */}
    </svg>
  );
};
// export default TokenMap;

// class ApiStatus extends Component {
//   // state = {  }
//   render() {
//     return (
//       sockStatus
//       ? <li><Tag className="mr-2" icon="pi pi-check" severity="success" >API-connected</Tag></li>
//       : <li><Tag className="mr-2" icon="pi pi-exclamation-triangle" severity="warning" >API-trying</Tag></li>
//     );
//   }
// }
// export default ApiStatus;

//-EOF
