//= pages/Timepoints.js

// eslint-disable-next-line
import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import { Rating } from 'primereact/rating';
import { Button } from "primereact/button";
// import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";
// import { ProgressBar } from 'primereact/progressbar';
import { Checkbox } from "primereact/checkbox";

import axios from "axios"; // npm i axios --save
import momentjs from "moment"; // npm i moment --save

// import { CustomerService } from '../service/CustomerService';
// (is:)
// import axios from 'axios'
// export class CustomerService {
//     getCustomersMedium() {
//         return axios.get('assets/demo/data/customers-medium.json')
//             .then(res => res.data.data);
//     }
//     getCustomersLarge() {
//         return axios.get('assets/demo/data/customers-large.json')
//                 .then(res => res.data.data);
//     }
// }

// eslint-disable-next-line
const customers_medium_json = {
  data: [
    { id: 1000, name: "James Butt", country: { name: "Algeria", code: "dz" }, company: "Benton, John B Jr", date: "2015-09-13", status: "unqualified", activity: 17, representative: { name: "Ioni Bowcher", image: "ionibowcher.png" } },
    { id: 1001, name: "Josephine Darakjy", country: { name: "Egypt", code: "eg" }, company: "Chanay, Jeffrey A Esq", date: "2019-02-09", status: "proposal", activity: 0, representative: { name: "Amy Elsner", image: "amyelsner.png" } },
    { id: 1002, name: "Art Venere", country: { name: "Panama", code: "pa" }, company: "Chemel, James L Cpa", date: "2017-05-13", status: "qualified", activity: 63, representative: { name: "Asiya Javayant", image: "asiyajavayant.png" } },
  ],
};

export const TimepointsPage = () => {
  // const api_url = "";
  // const api_url = ".";
  // const api_url = "http://ubuntu-vm-imac19.i.dgt-bv.com:8052";
  const api_url = window._env_.API_BASE_URL;
  //
  const url_anchors = api_url + "/api/anchors";
  const url_tokens = api_url + "/api/tokens";
  const url_timepoints = api_url + "/api/timepoints";
  const url_maps = api_url + "/api/maps";
  // url_anchors, url_tokens, url_timepoints, url_maps

  const [anchor_table, set_anchor_table] = useState(null);
  const [anchor_filter, set_anchor_filter] = useState("");
  const [anchor_loading, set_anchor_loading] = useState(true);
  const [anchor_show_id, set_anchor_show_id] = useState(false);
  const [anchor_checkbox_show_id, set_anchor_checkbox_show_id] = useState([]);

  const [token_table, set_token_table] = useState(null);
  const [token_filter, set_token_filter] = useState("");
  const [token_loading, set_token_loading] = useState(true);
  const [token_show_id, set_token_show_id] = useState(false);
  const [token_checkbox_show_id, set_token_checkbox_show_id] = useState([]);

  const [timepoint_table, set_timepoint_table] = useState(null);
  const [timepoint_filter, set_timepoint_filter] = useState("");
  const [timepoint_loading, set_timepoint_loading] = useState(true);
  const [timepoint_show_id, set_timepoint_show_id] = useState(false);
  const [timepoint_checkbox_show_id, set_timepoint_checkbox_show_id] = useState([]);

  const [map_table, set_map_table] = useState(null);
  const [map_filter, set_map_filter] = useState("");
  const [map_loading, set_map_loading] = useState(true);
  const [map_show_id, set_map_show_id] = useState(false);
  const [map_checkbox_show_id, set_map_checkbox_show_id] = useState([]);

  useEffect(() => {
    //
    axios
      .get(url_anchors, {}, { timeout: 3000 })
      .then((data) => {
        set_anchor_table(data.data.anchors);
        set_anchor_loading(false);
      })
      .catch((error) => {
        set_anchor_table(null);
        set_anchor_loading(false);
      });
    //
    axios
      .get(url_tokens, {}, { timeout: 3000 })
      .then((data) => {
        set_token_table(data.data.tokens);
        set_token_loading(false);
      })
      .catch((error) => {
        set_token_table(null);
        set_token_loading(false);
      });
    //
    axios
      .get(url_timepoints, {}, { timeout: 3000 })
      .then((data) => {
        set_timepoint_table(data.data.timepoints);
        set_timepoint_loading(false);
      })
      .catch((error) => {
        set_timepoint_table(null);
        set_timepoint_loading(false);
      });
    //
    axios
      .get(url_maps, {}, { timeout: 3000 })
      .then((data) => {
        set_map_table(data.data.maps);
        set_map_loading(false);
      })
      .catch((error) => {
        set_map_table(null);
        set_map_loading(false);
      });
    //
    // eslint-disable-next-line
  }, []);

  const click_show_api_timepoints = () => {
    window.open(url_timepoints);
  };
  const click_refresh_api_timepoints = () => {
    axios
      .get(url_timepoints, {}, { timeout: 3000 })
      .then((data) => {
        set_timepoint_table(data.data.timepoints);
        set_timepoint_loading(false);
      })
      .catch((error) => {
        set_timepoint_table(null);
        set_timepoint_loading(false);
      });
  };
  const timepoint_on_checkbox_showid_change = (e) => {
    let selectedValue = [...timepoint_checkbox_show_id];
    if (e.checked) selectedValue.push(e.value);
    else selectedValue.splice(selectedValue.indexOf(e.value), 1);
    //
    set_timepoint_checkbox_show_id(selectedValue);
    set_timepoint_show_id(selectedValue.indexOf("true") !== -1);
  };
  //
  const timepoint_table_header = (
    <div className="table-header">
      <h5>Timepoints</h5>
      <div className="field-checkbox">
        <Checkbox inputId="checkShowIDs_tpoints" name="option" value="true" checked={timepoint_checkbox_show_id.indexOf("true") !== -1} onChange={timepoint_on_checkbox_showid_change} />
        <label htmlFor="checkShowIDs_tpoints">show IDs</label>
      </div>
      <Button label="/api/timepoints" onClick={click_show_api_timepoints} className="p-button-raised p-button-warning mr-2 mb-2" />
      <Button label="refresh" onClick={click_refresh_api_timepoints} className="p-button-raised p-button-warning mr-2 mb-2" />
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={timepoint_filter} onChange={(e) => set_timepoint_filter(e.target.value)} placeholder="Global Search" />
      </span>
    </div>
  );

  const body_template = (data, props) => {
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {data[props.field]}
      </>
    );
  };

  const time_template = (data, props) => {
    const time = data[props.field];
    const data_hex = data["_anchor_hextime"];
    let date_obj = new Date(time * 1000);
    // https://www.w3docs.com/snippets/javascript/how-to-format-a-javascript-date.html
    // let nice_time = date.format('YYYY-MM-DD HH:m:s') + " UTC";
    // https://momentjs.com/
    // eslint-disable-next-line
    let nice_time = momentjs(date_obj).format("YYYY-MMM-DD [[h:m:s]] HH:mm:ss");
    let time_string = "";
    if (data_hex) {
      time_string = nice_time + " (" + time + "s, 0x" + data_hex + ")";
    } else {
      time_string = nice_time + " (" + time + "s)";
    }
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {time_string}
      </>
    );
  };

  const tokenlist_template = (data, props) => {
    const presence_tlist = data[props.field];
    // const time = data['time'];
    // const tokens = presence_tlist.find(obj => obj.time == time).tokens;
    let token_list_str = presence_tlist.join(", ");
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {token_list_str}
      </>
    );
  };

  const location_template = (data, props) => {
    const presence_alist = data.location.presence_alist;
    const t_time = data.time;
    const a_list = presence_alist.filter((obj) => obj.time >= t_time).map((obj) => obj.anchor_id);
    // const tokens = presence_tlist.find(obj => obj.time == time).tokens;
    // let anchor_list_str = "Anchors:" + presence_alist.join(", ");
    let anchor_list_str = "Anchors:" + a_list.join(", ");
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {anchor_list_str}
      </>
    );
  };

  const fixes_template = (data, props) => {
    const fixes = data.fixes;
    const t_time = data.time;
    let fixes_list = [];
    fixes.forEach((element) => {
      const tid = element.token_id;
      const presence_alist = element.location.presence_alist;
      const a_list = presence_alist.filter((obj) => obj.time >= t_time).map((obj) => obj.anchor_id);
      let new_fix_str = "";
      if (a_list.length > 0) {
        new_fix_str = `T:${tid}->Anchors:` + a_list.join(",");
      } else {
        new_fix_str = `T:${tid}->Gone`;
      }
      fixes_list.push(new_fix_str);
    });
    const fixes_str = fixes_list.join("; ");
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {fixes_str}
      </>
    );
  };

  // _uplink_tech:    { type: String,   required: false},
  // _uplink_prov:    { type: String,   required: false},
  // _lw_framecount:  { type: Number,   required: false},
  // _lw_timestamp:   { type: String,   required: false},
  // _lw_rssi:        { type: Number,   required: false},
  // _lw_lorasnr:     { type: Number,   required: false},

  return (
    <div className="grid table-demo">
      <div className="col-12">
        <div className="card">
          <DataTable
            className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
            header={timepoint_table_header}
            value={timepoint_table}
            dataKey="_id"
            loading={timepoint_loading}
            emptyMessage="No items found."
            globalFilter={timepoint_filter}
            // scrollable scrollHeight="600px"
            paginator
            rows={6}
            rowHover
            // showGridlines={true} // <== jdg:already set with: className="p-datatable-gridlines"
            columnResizeMode="fit"
            autoLayout="true">
            {timepoint_show_id && <Column field="_id" header="ID" sortable body={body_template}></Column>}
            <Column field="time" header="Timepoint" sortable body={time_template}></Column>
            <Column field="fixes" header="Fixes" sortable body={fixes_template}></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
}; // \export const OverviewPage = () => {

//-EOF
