//= ./components/TokenIcon.jsx

import { checkPropTypes } from 'prop-types';
import React, {Component, useState, useContext, useEffect} from 'react';

// https://stackoverflow.com/questions/58704990/calculate-pixel-width-of-text-without-knowing-font-in-react-javascript
function getTextWidth(text, font) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font || getComputedStyle(document.body).font;
  return context.measureText(text).width;
}

function hexShortStringToInt(token_id) {
  let num = parseInt(token_id, 16);
  return num;
}

let Colors = {};
Colors.names = {
  // aqua: "#00ffff",
  // azure: "#f0ffff",
  // beige: "#f5f5dc",
  // black: "#000000",
  blue: "#0000ff",
  brown: "#a52a2a",
  // cyan: "#00ffff",
  darkblue: "#00008b",
  darkcyan: "#008b8b",
  darkgrey: "#a9a9a9",
  darkgreen: "#006400",
  darkkhaki: "#bdb76b",
  darkmagenta: "#8b008b",
  // darkolivegreen: "#556b2f",
  darkorange: "#ff8c00",
  darkorchid: "#9932cc",
  darkred: "#8b0000",
  darksalmon: "#e9967a",
  darkviolet: "#9400d3",
  fuchsia: "#ff00ff",
  gold: "#ffd700",
  green: "#008000",
  indigo: "#4b0082",
  // khaki: "#f0e68c",
  // lightblue: "#add8e6",
  // lightcyan: "#e0ffff",
  // lightgreen: "#90ee90",
  // lightgrey: "#d3d3d3",
  // lightpink: "#ffb6c1",
  // lightyellow: "#ffffe0",
  // lime: "#00ff00",
  myLime: "#00aa00",
  magenta: "#ff00ff",
  maroon: "#800000",
  navy: "#000080",
  olive: "#808000",
  orange: "#ffa500",
  // pink: "#ffc0cb",
  // purple: "#800080",
  violet: "#800080",
  red: "#ff0000",
  silver: "#c0c0c0",
  // white: "#ffffff",
  yellow: "#ffff00"
};
Colors.getColorFromNumber = function(token_id) {
  let num = hexShortStringToInt(token_id);
  const ColorSize = Object.keys(Colors.names).length;
  let modNumber = num % ColorSize;
  let myColorName = Object.keys(Colors.names)[modNumber];
  let myColorStr = Colors.names[myColorName];
  return myColorStr;
};

export const TokenIcon = (props) => {
  
  return (
    <g transform={ `translate(${props.posx},${props.posy})` }>
      <circle cx="0" cy="0" r="20" fill={ Colors.getColorFromNumber(props.token_id) } />
      <text x="-8" y="5" stroke="white" fill="white" className='mono'>{props.token_id}</text>
    </g>
  );
} 
// export default TokenIcon;

//-EOF
