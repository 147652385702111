//= pages/DashboardV3Page.js

// eslint-disable-next-line

import React, { useState, Component, useContext, useEffect } from "react";
//
import TableAnchor from "../components/TableAnchor";
import TableToken from "../components/TableToken";

export const DashboardV3Page = () => {
  //

  return (
    <div className="grid table-demo">
      <div className="col-12">
        <div className="card">
          <TableAnchor
            show_items_limit="recent"
            show_columns="only_graph"
          />
        </div>
        <div className="card">
          <TableAnchor
            show_items_limit="recent"
            show_columns="only_details"
          />
        </div>
        <div className="card">
          <TableToken show_items_limit="recent" />
        </div>
      </div>
    </div>
  );
};

//-eof
