//= pages/Events.js

// eslint-disable-next-line

import React, { useState, Component, useContext, useEffect } from "react";
import TokenPill from "../components/TokenPill";
// import React from 'react';
//
import { DataTable } from "primereact/datatable"; // https://www.primefaces.org/primereact/datatable/
import { Chart } from "primereact/chart";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { CSVLink } from "react-csv";
// import { SocketContext } from '../lib/socket_io_context';
import { CrudTable } from "../components/CrudTable";

import axios from "axios"; // npm i axios --save
import moment_js from "moment"; // npm i moment --save

import { f_max_age_ms, f_get_max_age_both } from "../config/config";
import { f_convert_selected_sensors_to_display_string, f_is_object, f_js } from "../lib/functions";

const convert_seconds_to_DHMS = function (secs) {
  let seconds = secs;
  let days = Math.floor(seconds / (3600 * 24));
  seconds -= days * 3600 * 24;
  let hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;
  let minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;
  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
};

function nr_to_padzero_string(nr, digits) {
  var str = "00000" + nr;
  return str.substr(-1 * digits);
}

const date_to_nicetime = (date) => {
  return moment_js(date).format("YYYY-MMM-DD HH:mm:ss");
};

let max_age = f_max_age_ms();

// - - - - - - = = = - - - - - -
// export Component

export const ReportPage = () => {
  //

  const headers = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Email", key: "email" },
    { label: "Age", key: "age" },
  ];

  const data = [
    { firstName: "Warren", lastName: "Morrow", email: "sokyt@mailinator.com", age: "36" },
    { firstName: "Gwendolyn", lastName: "Galloway", email: "weciz@mailinator.com", age: "76" },
    { firstName: "Astra", lastName: "Wyatt", email: "quvyn@mailinator.com", age: "57" },
    { firstName: "Jasmine", lastName: "Wong", email: "toxazoc@mailinator.com", age: "42" },
    { firstName: "Brooke", lastName: "Mcconnell", email: "vyry@mailinator.com", age: "56" },
    { firstName: "Christen", lastName: "Haney", email: "pagevolal@mailinator.com", age: "23" },
    { firstName: "Tate", lastName: "Vega", email: "dycubo@mailinator.com", age: "87" },
    { firstName: "Amber", lastName: "Brady", email: "vyconixy@mailinator.com", age: "78" },
    { firstName: "Philip", lastName: "Whitfield", email: "velyfi@mailinator.com", age: "22" },
    { firstName: "Kitra", lastName: "Hammond", email: "fiwiloqu@mailinator.com", age: "35" },
    { firstName: "Charity", lastName: "Mathews", email: "fubigonero@mailinator.com", age: "63" },
  ];

  const dropdownValues = [
    { name: "Past Day", value: "1D" },
    { name: "Past Week", value: "1W" },
  ];
  const annchors = [];
  const meta_prop = {
    api_url: window._env_.API_BASE_URL,
    item_name_singular: "Anchor",
    item_name_plural: "Report",
    table_title: "Manage Anchors",
    dialog_title: "Anchor Details",
    dialog_width: "450px",
    msg_empty: "No detail found.",
    msg_confirm_dialog_header: "Confirm",
    msg_are_you_sure_delete_single_item: "Are you sure you want to delete",
    msg_are_you_sure_delete_multi_item: "Are you sure you want to delete the selected anchors?",

    f_api_get_items_promise: undefined,
    f_api_save_edit_item: undefined,
    f_api_save_new_item: undefined,
    f_api_delete_single_item: undefined,
    f_api_delete_many_items: undefined,
  };

  const f_api_get_items_promise = function (props) {
    return new Promise(); // jdg-note: return 'promise' here, let caller resolve it.
  };

  const f_api_save_edit_item = function ({ items, item }) {
    let _items = [];
    return _items;
  };

  const f_api_save_new_item = function ({ items, item }) {
    let _items = [];
    return _items;
  };

  const f_api_delete_single_item = function ({ items, item }) {
    let _items = [];
    return _items;
  };

  const f_api_delete_many_items = function ({ items, selected_items }) {
    let _items = [];
    return _items;
  };

  meta_prop.f_api_get_items_promise = f_api_get_items_promise;
  meta_prop.f_api_save_edit_item = f_api_save_edit_item;
  meta_prop.f_api_save_new_item = f_api_save_new_item;
  meta_prop.f_api_delete_single_item = f_api_delete_single_item;
  meta_prop.f_api_delete_many_items = f_api_delete_many_items;

  const api_url = window._env_.API_BASE_URL;
  const url_anchors_v3 = api_url + "/anchors";

  const [anchor_table, set_anchor_table] = useState(null);
  const [chartdata, setChartData] = useState(null);
  const [anchorslist, set_anchorslist] = useState(null);
  const [anchorid, SetAnchorid] = useState(null);
  const [duration, setDuration] = useState(null);
  const [calendarValue, setCalendarValue] = useState(null);

  const [anchor_filter, set_anchor_filter] = useState("");
  const [anchor_loading, set_anchor_loading] = useState(true);
  const [anchor_show_id, set_anchor_show_id] = useState(false);
  const [anchor_checkbox_show_id, set_anchor_checkbox_show_id] = useState([]);

  let update_interval_s = 2;

  useEffect(() => {
    //
    update_api_anchors();
    //
    const interval = setInterval(() => {
      //update_api_anchors();
    }, update_interval_s * 1000);
    return () => {
      clearInterval(interval); // undo 'setInterval()'
    };
  }, []);

  const click_show_api_anchors = () => {
    window.open(url_anchors_v3);
  };
  const update_api_anchors = () => {
    axios
      .get(url_anchors_v3, {}, { timeout: 3000 })
      .then((data) => {
        const anchors = data.data && data.data.anchors ? data.data.anchors : null;
        debugger;
        const anchorslist = anchors.map((anchor) => ({ name: anchor.display_name, value: anchor.tpid })); // Set dropdown values based on anchor data
        //set_anchor_table(anchors);

        set_anchorslist(anchorslist);
        set_anchor_loading(false);
      })
      .catch((err) => {
        set_anchor_table(null);
        set_anchor_loading(false);
      });
  };
  const anchor_on_checkbox_showid_change = (e) => {
    let selectedValue = [...anchor_checkbox_show_id];
    if (e.checked) selectedValue.push(e.value);
    else selectedValue.splice(selectedValue.indexOf(e.value), 1);
    //
    set_anchor_checkbox_show_id(selectedValue);
    set_anchor_show_id(selectedValue.indexOf("true") !== -1);
  };
  //
  const anchor_table_header = (
    <div className="table-header">
      <h5>Report</h5>
      {/* <span className="">Last API update: {date_to_nicetime()}</span> */}
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={anchor_filter} onChange={(e) => set_anchor_filter(e.target.value)} placeholder="Global Search" />
      </span>
    </div>
  );

  const f_object_lookup_default = function ({ obj, field, dflt }) {
    const ret_value = f_is_object(obj) && obj.hasOwnProperty(field) ? obj[field] : dflt;
    return ret_value;
  };

  const body_template = (data, props) => {
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {f_object_lookup_default({ obj: data, field: props.field, dflt: "" })}
      </>
    );
  };

  const lw_freq_template = (data, props) => {
    const data_obj = data.aux && data.aux.lw ? data.aux.lw : {};
    const bandwidth = data_obj.bandwidth || "undef";
    const spreading_factor = data_obj.spreading_factor || "undef";
    const data_formatted = `bw=${bandwidth},sf=${spreading_factor}`;
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {data_formatted}
      </>
    );
  };

  const lw_gw_template = (data, props) => {
    const data_obj = data.aux && data.aux.lw ? data.aux.lw : {};
    //
    const gw_obj_list = data_obj.gateways || [];
    const gw_list = gw_obj_list.map((obj) => `gw_id=${obj.gateway_id},rssi=${obj.rssi},snr=${obj.snr}`);
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {gw_list.map((obj) => {
          return (
            <>
              {obj} <br />
            </>
          );
        })}
      </>
    );
  };

  const uptime_template = (data, props) => {
    const uptime = f_object_lookup_default({ obj: data, field: props.field, dflt: "" });
    const time = data.time || 0;
    let time_date_obj = new Date(time * 1000);
    let date_now = Date.now();
    let result_string = "";
    //
    const is_not_too_old = time_date_obj > date_now - max_age;
    if (time_date_obj > date_now - max_age) {
      let DHMS = convert_seconds_to_DHMS(uptime);
      const niceUptime = `${DHMS.days} days, ${nr_to_padzero_string(DHMS.hours, 2)}:${nr_to_padzero_string(DHMS.minutes, 2)}:${nr_to_padzero_string(DHMS.seconds, 2)}`;
      result_string = niceUptime;
    } else {
      result_string = "(down)";
    }
    //
    if (data["_uplink_tech"] === "lorawan") {
      result_string = "-";
    }
    //
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {result_string}
      </>
    );
  };

  const reptime_template = (data, props) => {
    let result_string = "";
    //
    const time = data.ad_time_t || 0;
    let time_date_obj = new Date(time * 1000);
    let date_now = Date.now();
    const is_not_too_old = time_date_obj > date_now - max_age;
    //
    let nice_time = date_to_nicetime(time_date_obj);
    result_string = nice_time;
    //
    if (!is_not_too_old) {
      result_string = result_string;
    }
    //
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {result_string}
      </>
    );
  };

  const tokens_template = (data, props) => {
    const tokens = f_object_lookup_default({ obj: data, field: "ad_tokens_j", dflt: {} });
    const token_ids = Object.values(tokens.tokens);
    
    token_ids.sort(); // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
    //
    let result_string = token_ids.join(",");
    //
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {result_string}
      </>
    );
  };

  const sensors_template = (data, props) => {
    const sensors = f_object_lookup_default({ obj: data, field: "sensors", dflt: {} });
    //
    let result_string = f_convert_selected_sensors_to_display_string({ sensors });
    //
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {result_string}
      </>
    );
  };

  const location_template = (data, props) => {
    const presence_alist = data.location.presence_alist;
    const t_time = data.time || 0;
    const a_list = presence_alist.filter((obj) => obj.time >= t_time).map((obj) => obj.anchor_id);
    // const tokens = presence_tlist.find(obj => obj.time == time).tokens;
    // let anchor_list_str = "Anchors:" + presence_alist.join(", ");
    let anchor_list_str = "Anchors:" + a_list.join(", ");
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {anchor_list_str}
      </>
    );
  };

  const fixes_template = (data, props) => {
    const fixes = data.fixes;
    const t_time = data.time || 0;
    let fixes_list = [];
    fixes.forEach((element) => {
      const tid = element.token_id;
      const presence_alist = element.location.presence_alist;
      const a_list = presence_alist.filter((obj) => obj.time >= t_time).map((obj) => obj.anchor_id);
      let new_fix_str = "";
      if (a_list.length > 0) {
        new_fix_str = `T:${tid}->Anchors:` + a_list.join(",");
      } else {
        new_fix_str = `T:${tid}->Gone`;
      }
      fixes_list.push(new_fix_str);
    });
    const fixes_str = fixes_list.join("; ");
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {fixes_str}
      </>
    );
  };

  const [sortField, setSortField] = useState("anchor_id");
  const [sortOrder, setSortOrder] = useState(1);
  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
  };
  // Chart options
  const chartOptions = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: "Time",
        },
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
        },
        plugins: {
          zoom: {
            zoom: {
              enabled: true,
              drag: true,
              mode: "x",
              limits: {
                max: 10,
                min: 0.5,
              },
            },
          },
        },
      },
      y: {
        title: {
          display: true,
          text: "Value",
        },
      },
    },
  };
  const csvReport = {
    data: data,
    headers: headers,
    filename: "Clue_Mediator_Report.csv",
  };
  const getData = () => {
    const api = `${url_anchors_v3}?tpid=${anchorid}&&duration=${duration}`;
    axios
      .get(api, {}, { timeout: 3000 })
      .then((data) => {
        let anchors = data.data && data.data.anchors ? data.data.anchors : null;
        if (anchors.length > 0) {
          anchors = anchors[0].time_objects;
        }
        const anchorslist = anchors.map((anchor) => ({ name: anchor.display_name, value: anchor.tpid })); // Set dropdown values based on anchor data
        const rssiValues = anchors.map((data) => parseInt(data.rssi));
        const snrValues = anchors.map((data) => parseFloat(data.snr));
        // Convert epoch timestamps to date strings
        const labels = anchors.map((data) => {
          const timestamp = data.ad_time_t * 1000; // Convert to milliseconds
          const date = new Date(timestamp);
          return date.toISOString(); // Modify date format as needed
        });
        const obj = {
          labels: labels,
          datasets: [
            {
              label: "RSSI",
              data: rssiValues,
              fill: false,
              backgroundColor: "#2f4860",
              borderColor: "#2f4860",
              tension: 0.4,
            },
            {
              label: "SNR",
              data: snrValues,
              fill: false,
              backgroundColor: "#00bb7e",
              borderColor: "#00bb7e",
              tension: 0.4,
            },
          ],
        };
        setChartData(obj);
        set_anchor_table(anchors);
        set_anchor_loading(false);
      })
      .catch((err) => {
        set_anchor_table(null);
        set_anchor_loading(false);
      });
  };

  return (
    <div className="grid table-demo">
      <div className="col-12">
        <div className="card">
          <label>Select Anchor : </label> <Dropdown value={anchorid} onChange={(e) => SetAnchorid(e.value)} options={anchorslist} optionLabel="name" placeholder="Select" />
          &nbsp;&nbsp;
          <label> Duration : </label>
          <Dropdown value={duration} onChange={(e) => setDuration(e.value)} options={dropdownValues} optionLabel="name" placeholder="Select" />
          &nbsp;&nbsp;
          {/* <Calendar showIcon showButtonBar value={calendarValue} onChange={(e) => setCalendarValue(e.value)}></Calendar>&nbsp;&nbsp;
          <Calendar showIcon showButtonBar value={calendarValue} onChange={(e) => setCalendarValue(e.value)}></Calendar>&nbsp;&nbsp; */}
          <Button label="Search" onClick={() => getData()} />
          <br />
          {/* <div className="card col-12 lg:col-10">
            <h5>Linear Chart</h5>
            <Chart type="line" data={chartdata} options={chartOptions} />
          </div> */}
          <br />
          {/* https://www.primefaces.org/primereact/datatable/ */}
          <DataTable
            className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
            header={anchor_table_header}
            value={anchor_table}
            dataKey="_id"
            loading={anchor_loading}
            emptyMessage="No items found."
            globalFilter={anchor_filter}
            paginator
            rows={50}
            rowsPerPageOptions={[5, 10, 25, 50, 1000]}
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            rowHover
            // showGridlines={true} // <== jdg:already set with: className="p-datatable-gridlines"
            size="small"
            // columnResizeMode="fit"
            autoLayout="true"
            resizableColumns
            //
          >
            {anchor_show_id && <Column field="id" header="ID" sortable body={body_template}></Column>}
            <Column field="tpid" header="Anchor-ID" sortable body={body_template}></Column>
            <Column field="ad_time_t" header="Last update" sortable body={reptime_template}></Column>
            <Column field="rssi" header="Rssi" sortable body={body_template}></Column>
            <Column field="snr" header="Snr" sortable body={body_template}></Column>
            <Column field="bandwidth" header="Bandwidth" sortable body={body_template}></Column>
            <Column field="spreading_factor" header="Spreading Factor" sortable body={body_template}></Column>
            <Column field="tmp_anchor" header="Tmp Anchor" sortable body={body_template}></Column>
            <Column field="tmp_env" header="Tmp Env" sortable body={body_template}></Column>
            <Column
              field="ad_tokens_j.tokens"
              header="Tokens"
              sortable
              body={tokens_template}
              // ./src/App.scss:.p-datatable-tbody:white-space: nowrap;
              style={{ width: "80%", whiteSpace: "normal" }}
              // visible={v_state_show_columns_graph}
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
};
