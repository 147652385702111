//= src/lib/functions.js

// functions.js
// - - - - - - = = = - - - - - -

import moment_js from "moment"; // npm i moment --save
// const moment_js = require("moment"); // npm i moment --save
// https://momentjs.com/
// https://momentjs.com/docs/#/use-it/node-js/
// examples:
// "To get the current date and time, just call moment() with no parameters."
// "This is essentially the same as calling moment(new Date())."
// var now = moment();
// moment("2018-12-24T18:04:47.806Z").toDate();
// moment().subtract(1, 'seconds');

// - - - - - - = = = - - - - - -
export function f_date_to_nicetime_str(date) {
  return moment_js(date).format("YYYY-MMM-DD_HH:mm:ss");
}

export function f_date_to_nicetime_ms_str(date) {
  return moment_js(date).format("YYYY-MMM-DD_HH:mm:ss.SSS");
}

export function f_return_new_array_remove_all_occurences_in_array_of_element(old_array, element) {
  // Usage: const {f_return_new_array_remove_all_occurences_in_array_of_element} = require('./lib/functions');
  let my_set = new Set(old_array);
  my_set.delete(element);
  // my_set = my_set.delete(element);
  const new_array = [...my_set];
  return new_array.sort();
}

export function f_return_new_array_deduped_sorted(old_array) {
  // Usage: const {f_return_new_array_deduped_sorted} = require('./lib/functions');
  let my_set = new Set(old_array);
  const new_array = [...my_set];
  return new_array.sort();
}

export function f_get_date_now() {
  const date_now = new Date();
  return date_now;
}

export function f_get_epoch_now() {
  const date_now = f_get_date_now();
  const epoch_now = Math.round(date_now / 1000);
  return epoch_now;
}

export function f_get_key_from_object_by_value(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/substr <==== Deprecated
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/substring
export function f_nr_to_padzero_string(nr, digits) {
  var padded_str = "00000" + nr;
  return padded_str.substr(-1 * digits);
}

// export function f_str_to_padzero_string(str, digits) {
//   var padded_str = "00000" + str;
//   return padded_str.substr(-1 * digits);
// }

export function f_hex_string_to_dec_number(hex_str) {
  let decimal_number = parseInt(Number("0x" + hex_str), 10);
  return decimal_number;
}

export function f_dec_number_to_hex_string(dec_num) {
  // https://www.w3docs.com/snippets/javascript/how-to-convert-decimal-to-hexadecimal-in-javascript.html
  return dec_num.toString(16);
}

export function f_is_object(val) {
  if (val === null) {
    return false;
  }
  return typeof val === "function" || typeof val === "object";
}

export function f_get_substring_length({ str, length }) {
  let my_str = str && typeof str === "string" && str.trim().length > 0 ? str.trim() : "";
  if (my_str.length > length - 3) {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/substring
    my_str = my_str.substring(0, length - 3) + "...";
  }
  return my_str;
}

export function fa_sleep_ms_promise(ms) {
  // idea from: https://stackoverflow.com/questions/14249506/how-can-i-wait-in-node-js-javascript-l-need-to-pause-for-a-period-of-time
  // usage: await fa_sleep_ms_promise(1000);
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function f_pretty_json_stringify(obj) {
  return JSON.stringify(obj, null, 2);
}

export function f_js(obj) {
  return JSON.stringify(obj);
}

export function f_sanitize_str_or_default_str({ str, default_str }) {
  const san_default = default_str && typeof default_str === "string" && default_str.trim().length > 0 ? default_str.trim() : "";
  const san_str = str && typeof str === "string" && str.trim().length > 0 ? str.trim() : san_default;
  return san_str;
}

// - - - - - - = = = - - - - - -
// eslint-disable-next-line
const regex_ascii_all = /^[\x00-\x7f]*$/; // reference: https://en.wikipedia.org/wiki/ASCII
//
const re_string_ascii_printable = "^[\x20-\x7e]*$"; // only printable chars
const ascii_printable_expanded_x20_x7e = " !\"#$%&'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\\]^_`abcdefghijklmnopqrstuvwxyz{|}~";
//
const ascii_alphanum = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
const ascii_punctuation = " !\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~";
//
const ascii_inter_words = " !,.:;?";
const ascii_brackets = "<>[](){}";
const ascii_quotes = "\"'`";
const ascii_signs = "#$%&@^_|~";
const ascii_math = "*+-/=\\";
//
const ascii_punctuation_concat = ascii_alphanum + ascii_inter_words + ascii_brackets + ascii_quotes + ascii_signs + ascii_math; // <== should be same as 'ascii_printable_expanded_x20_x7e'
//
const ascii_sentence_punctuation = " !\"#$%&'()*+,-./:;=?@[\\]_~";
const ascii_alphanum_sentence_punctuation = ascii_alphanum + ascii_sentence_punctuation;
//
const ascii_alphanum_tech_words = ascii_alphanum + "+-.:=@_";
//
const regex_ascii_printable = new RegExp(re_string_ascii_printable);
// const regex_ascii_print_noquotes_1 = /^[a-zA-Z0-9\ \.,\-_]*$/; // disallow formatting chars like []{}()<>*&^%$#@!=+\/|`?'~"
// const regex_ascii_alphanum_sentence_punctuation_1 = new RegExp('^[' + ascii_alphanum_sentence_punctuation + ']*$');
const regex_ascii_alphanum_sentence_punctuation = new RegExp("^[" + ascii_alphanum_sentence_punctuation + "]*$");
//
const regex_ascii_alphanum_tech_words = new RegExp("^[" + ascii_alphanum_tech_words + "]*$");
//
const regex_hexstring = new RegExp("^[a-zA-Z0-9]*$");

// - - - - - - = = = - - - - - -
// module.exports = {
//   f_return_new_array_remove_all_occurences_in_array_of_element,
//   f_return_new_array_deduped_sorted,
//   f_get_date_now,
//   f_get_epoch_now,
//   f_get_key_from_object_by_value,
//   f_date_to_nicetime_str,
//   f_date_to_nicetime_ms_str,
//   f_nr_to_padzero_string,
//   f_hex_string_to_dec_number,
//   f_dec_number_to_hex_string,
//   f_is_object,
//   f_get_substring_length,
//   fa_sleep_ms_promise,
//   f_pretty_json_stringify,
//   f_js,
//   f_sanitize_str_or_default_str,
//   regex_ascii_printable,
//   regex_ascii_alphanum_sentence_punctuation,
//   regex_ascii_alphanum_tech_words,
// }

// - - - - - - = = = - - - - - -
//-eof

export function f_date_to_nicedatetime(date) {
  return moment_js(date).format("YYYY-MMM-DD/HH:mm:ss");
}

export function f_date_to_nicedate(date) {
  return moment_js(date).format("YYYY-MMM-DD");
}

export function f_date_to_nicetime(date) {
  return moment_js(date).format("HH:mm:ss");
}

export function f_epoch_to_nicedatetime(epoch) {
  let localzone_format = "";
  try {
    let moment_var = moment_js.unix(epoch);
    localzone_format = moment_var.format("YYYY-MM-DD/HH:mm:ss");
  } catch (err) {
    localzone_format = "";
  }
  return localzone_format;
}

export function f_epoch_to_nicetime(epoch) {
  let localzone_format = "";
  try {
    let moment_var = moment_js.unix(epoch);
    localzone_format = moment_var.format("HH:mm:ss");
  } catch (err) {
    localzone_format = "";
  }
  return localzone_format;
}

export function f_convert_selected_sensors_to_display_string({ sensors }) {
  let display_string = "";
  if (!f_is_object(sensors) || !sensors.hasOwnProperty("tmp")) {
    return "";
  }
  // const attribs = ["tmp", "hum", "prs", "mox", "alt"];
  const attribs = ["tmp", "hum", "prs", "voc", "alt"];
  // attribs.sort(); // d221031 Hans: Kan je de volgorde en de decimals aanpassen? tmp 32.2 hum 43.0 prs 1009.0 voc 18.66
  //
  // JDG-Note: the value is already limited in decimals in API:repstring.js-repstring_def-field.decimals!
  const list_strings = attribs.map((attr) => {
    const sensors_attr = sensors[attr] && sensors[attr].value;
    if (sensors_attr) return `${attr}=${sensors_attr}`;
    return undefined;
  });
  display_string = list_strings.join(", ");
  return display_string;
}

// export function f_convert_sensors_to_display_string({ sensors, time, time_str }) {
export function f_convert_sensors_to_display_string({ sensors }) {
  let display_string = "";
  if (!f_is_object(sensors)) {
    return "";
  }
  const attribs = Object.keys(sensors);
  attribs.sort();
  //
  // JDG-Note: the value is already limited in decimals in API:repstring.js-repstring_def-field.decimals!
  // const list_strings = attribs.map((attr) => `${attr}=${sensors[attr]}`);
  const list_strings = [];
  attribs.forEach((attr) => {
    if (attr === "lw" || attr === "transport") {
      return;
    }
    let sensors_attr = (sensors[attr] && sensors[attr].value) || 0;
    let sensors_time = (sensors[attr] && sensors[attr].time) || 0;
    if (attr === "mov" || attr === "pir") {
      // sensors_attr = `${time}(=${time_str})`;
      // const nicetime = f_date_to_nicetime(time);
      if (sensors_time) {
        // const nicetime = f_date_to_nicetime(sensors_time);
        const nicetime = f_epoch_to_nicedatetime(sensors_time);
        sensors_attr = `${nicetime}`;
      } else {
        sensors_attr = "<error>";
      }
    }
    if (sensors_attr) {
      // return `${attr}=${sensors_attr}`;
      list_strings.push(`${attr}=${sensors_attr}`);
    } else {
      return;
    }
  });
  display_string = list_strings.join(", ");
  return display_string;
}

// https://leonardocolor.io/theme.html#
let Colors2 = {};
Colors2.names = {
  // Gray100: "#d9d9d9",
  Gray200: "#b5b5b5",
  Gray300: "#949494",
  Gray400: "#767676",
  Gray500: "#595959",
  Gray600: "#424242",
  // Green100: "#00fc00",
  Green200: "#00d200",
  Green300: "#00ac00",
  Green400: "#008900",
  Green500: "#006800",
  Green600: "#004e00",
  Blue100: "#d7d7ff",
  Blue200: "#adadff",
  Blue300: "#8787ff",
  Blue400: "#6060fe",
  Blue500: "#3131fe",
  Blue600: "#0000e2",
  // Red100: "#ffcece",
  Red200: "#ff9898",
  Red300: "#fe5d5d",
  Red400: "#ed0000",
  Red500: "#b60000",
  Red600: "#8b0000",
  Purple100: "#ffc7ff",
  Purple200: "#ff88ff",
  Purple300: "#fe26fe",
  Purple400: "#d000d0",
  Purple500: "#9f009f",
  Purple600: "#790079",
  Yellow100: "#e0e100",
  Yellow200: "#babb00",
  Yellow300: "#999900",
  Yellow400: "#7a7a00",
  Yellow500: "#5c5c00",
  Yellow600: "#444500",
};

export function gen_colors() {
  // let steps = ["4", "6", "8", "a", "c", "e"];
  let steps = ["4", "7", "a", "d"];
  let colors_list = {};
  for (let step1 of steps) {
    for (let step2 of steps) {
      for (let step3 of steps) {
        const new_color = `#${step1}${step2}${step3}`;
        colors_list[new_color] = new_color;
      }
    }
  }
  return colors_list;
  // const out_str = f_pretty_json_stringify(colors_list);
  // console.log(out_str);
}

let Colors3 = {};
Colors3.names = gen_colors();

let Colors = {};
Colors.names = {
  // aqua: "#00ffff",
  // azure: "#f0ffff",
  // beige: "#f5f5dc",
  // black: "#000000",
  blue: "#0000ff",
  brown: "#a52a2a",
  // cyan: "#00ffff",
  darkblue: "#00008b",
  darkcyan: "#008b8b",
  darkgrey: "#a9a9a9",
  darkgreen: "#006400",
  darkkhaki: "#bdb76b",
  darkmagenta: "#8b008b",
  // darkolivegreen: "#556b2f",
  darkorange: "#ff8c00",
  darkorchid: "#9932cc",
  darkred: "#8b0000",
  darksalmon: "#e9967a",
  darkviolet: "#9400d3",
  fuchsia: "#ff00ff",
  gold: "#ffd700",
  green: "#008000",
  indigo: "#4b0082",
  // khaki: "#f0e68c",
  // lightblue: "#add8e6",
  // lightcyan: "#e0ffff",
  // lightgreen: "#90ee90",
  // lightgrey: "#d3d3d3",
  // lightpink: "#ffb6c1",
  // lightyellow: "#ffffe0",
  // lime: "#00ff00",
  myLime: "#00aa00",
  magenta: "#ff00ff",
  maroon: "#800000",
  navy: "#000080",
  olive: "#808000",
  orange: "#ffa500",
  // pink: "#ffc0cb",
  // purple: "#800080",
  violet: "#800080",
  red: "#ff0000",
  silver: "#c0c0c0",
  // white: "#ffffff",
  yellow: "#ffff00",
};
// Colors.getColorFromNumber = function(token_id) {
export function getColorFromNumber(hexstr) {
  const my_colors = Colors2;
  let num = parseInt(hexstr, 16);
  const ColorSize = Object.keys(my_colors.names).length;
  let modNumber = num % ColorSize;
  let myColorName = Object.keys(my_colors.names)[modNumber];
  let myColorStr = my_colors.names[myColorName];
  return myColorStr;
}
export function getColorFromHexstr({ hexstr }) {
  const my_colors = Colors3;
  let num = parseInt(hexstr, 16) || 1;
  const ColorSize = Object.keys(my_colors.names).length;
  let modNumber = num % ColorSize;
  let myColorName = Object.keys(my_colors.names)[modNumber];
  let myColorStr = my_colors.names[myColorName];
  // console.log(`# hexstr=${hexstr} num=${num} modNumber=${modNumber} myColorStr=${myColorStr} `);
  return myColorStr;
}

function f_tpid_is_x({ tpid, prefix, max }) {
  // const regex = /^A-[0-9a-f]{2,4}$/;
  // const regex = new RegExp("^" + prefix + "-[0-9a-f]{2,4}$");
  if (!tpid) {
    return false;
  }
  const regex = new RegExp("^" + prefix + "-[0-9a-f]{2," + max + "}$");
  return regex.test(tpid);
}

export function f_tpid_is_anchor(tpid) {
  return f_tpid_is_x({ tpid, prefix: "A", max: 4 });
}
export function f_tpid_is_anchor_group(tpid) {
  return f_tpid_is_x({ tpid, prefix: "AG", max: 4 });
}
export function f_tpid_is_token(tpid) {
  return f_tpid_is_x({ tpid, prefix: "T", max: 4 });
}
export function f_tpid_is_token_group(tpid) {
  return f_tpid_is_x({ tpid, prefix: "TG", max: 4 });
}
export function f_tpid_is_color(tpid) {
  return f_tpid_is_x({ tpid, prefix: "C", max: 6 });
}

//-eof
