//= pages/TokenV3data.js

// eslint-disable-next-line

import TableToken from "../components/TableToken";

// import React, { useState, Component, useContext, useEffect } from "react";
// // import React from 'react';
// //
// import { DataTable } from "primereact/datatable"; // https://www.primefaces.org/primereact/datatable/
// import { Column } from "primereact/column";
// import { Rating } from "primereact/rating";
// import { Button } from "primereact/button";
// import { Toast } from "primereact/toast";
// import { InputText } from "primereact/inputtext";
// import { ProgressBar } from "primereact/progressbar";
// import { Checkbox } from "primereact/checkbox";

// // if we need an component that auto-rerenders itself, then:
// // https://github.com/jcoreio/react-interval-rerender
// // import Interval from 'react-interval-rerender'    // npm install --save react-interval-rerender
// // export const Clock = () => (
// //   <Interval delay={1000}>{() => new Date().toLocaleTimeString()}</Interval>
// // )

// // import { SocketContext } from '../lib/socket_io_context';
// import { CrudTable } from "../components/CrudTable";

// import axios from "axios"; // npm i axios --save
// import moment_js from "moment"; // npm i moment --save

// import { f_max_age_ms } from "../config/config";
// import { f_convert_sensors_to_display_string, f_is_object, f_js } from "../lib/functions";

// // import { CustomerService } from '../service/CustomerService';
// // (is:)
// // import axios from 'axios'
// // export class CustomerService {
// //     getCustomersMedium() {
// //         return axios.get('assets/demo/data/customers-medium.json')
// //             .then(res => res.data.data);
// //     }
// //     getCustomersLarge() {
// //         return axios.get('assets/demo/data/customers-large.json')
// //                 .then(res => res.data.data);
// //     }
// // }

// // eslint-disable-next-line
// const customers_medium_json = {
//   data: [
//     {
//       id: 1000,
//       name: "James Butt",
//       country: { name: "Algeria", code: "dz" },
//       company: "Benton, John B Jr",
//       date: "2015-09-13",
//       status: "unqualified",
//       activity: 17,
//       representative: { name: "Ioni Bowcher", image: "ionibowcher.png" },
//     },
//     {
//       id: 1001,
//       name: "Josephine Darakjy",
//       country: { name: "Egypt", code: "eg" },
//       company: "Chanay, Jeffrey A Esq",
//       date: "2019-02-09",
//       status: "proposal",
//       activity: 0,
//       representative: { name: "Amy Elsner", image: "amyelsner.png" },
//     },
//     {
//       id: 1002,
//       name: "Art Venere",
//       country: { name: "Panama", code: "pa" },
//       company: "Chemel, James L Cpa",
//       date: "2017-05-13",
//       status: "qualified",
//       activity: 63,
//       representative: { name: "Asiya Javayant", image: "asiyajavayant.png" },
//     },
//   ],
// };

// const convert_seconds_to_DHMS = function (secs) {
//   // DHMS = Days, Hourse, Minutes, Seconds
//   // let seconds = parseInt(secs, 10); // in case of string
//   let seconds = secs;
//   let days = Math.floor(seconds / (3600 * 24));
//   seconds -= days * 3600 * 24;
//   let hours = Math.floor(seconds / 3600);
//   seconds -= hours * 3600;
//   let minutes = Math.floor(seconds / 60);
//   seconds -= minutes * 60;
//   return {
//     days: days,
//     hours: hours,
//     minutes: minutes,
//     seconds: seconds,
//   };
// };

// function nr_to_padzero_string(nr, digits) {
//   var str = "00000" + nr;
//   return str.substr(-1 * digits);
// }

// const date_to_nicetime = (date) => {
//   return moment_js(date).format("YYYY-MMM-DD HH:mm:ss");
// };

// // socket_io msg ==> api_do_refresh

// // https://stackoverflow.com/questions/39426083/update-react-component-every-second
// // componentDidMount() {
// //   this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
// // }
// // componentWillUnmount() {
// //   clearInterval(this.interval);
// // }

// // - - - - - - + + + - - - - - -
// // about: useEffect
// //
// // // https://www.w3schools.com/react/react_useeffect.asp
// // useEffect(() => {
// //   // no 2nd param => Runs on every render
// // });
// // useEffect(() => {
// //   // empty array as 2nd param => Runs only on the first render
// // }, []);
// // useEffect(() => {
// //   // no 2ns param => Runs on the first render, And any time any dependency value changes
// // }, [prop, state]);
// // - - - - - - + + + - - - - - -

// let max_age = f_max_age_ms();

// // - - - - - - = = = - - - - - -
// // export Component

export const TokenV3DataPage = () => {
  // //
  // const meta_prop = {
  //   api_url: window._env_.API_BASE_URL,
  //   item_name_singular: "Token",
  //   item_name_plural: "Tokens",
  //   table_title: "Manage Tokens",
  //   dialog_title: "Token Details",
  //   dialog_width: "450px",
  //   msg_empty: "No tokens found.",
  //   msg_confirm_dialog_header: "Confirm",
  //   msg_are_you_sure_delete_single_item: "Are you sure you want to delete",
  //   msg_are_you_sure_delete_multi_item: "Are you sure you want to delete the selected tokens?",
  //   fields: [
  //     {
  //       field: "id",
  //       label: "ID",
  //       type: "id",
  //       focus: false,
  //       format_type: "string",
  //       required: false,
  //       sortable: true,
  //     },
  //     {
  //       field: "code",
  //       label: "Code",
  //       type: "string",
  //       focus: false,
  //       format_type: "string",
  //       required: false,
  //       sortable: true,
  //     },
  //     {
  //       field: "name",
  //       label: "Name",
  //       type: "string",
  //       focus: true,
  //       format_type: "string",
  //       required: true,
  //       sortable: true,
  //       cols_in_dialog: 12,
  //     },
  //     {
  //       field: "image",
  //       label: "Image",
  //       type: "image",
  //       focus: false,
  //       format_type: "image",
  //       required: false,
  //       sortable: false,
  //       cols_in_dialog: 12,
  //     },
  //     {
  //       field: "description",
  //       label: "Description",
  //       type: "text",
  //       focus: false,
  //       format_type: "string",
  //       required: true,
  //       sortable: false,
  //       cols_in_dialog: 12,
  //       textarea_rows: 3,
  //       textarea_cols: 20,
  //     },
  //     {
  //       field: "category",
  //       label: "Category",
  //       type: "enum",
  //       enums: ["Accessories", "Clothing", "Electronics", "Fitness"],
  //       focus: false,
  //       format_type: "string",
  //       required: false,
  //       sortable: true,
  //       cols_in_dialog: 12,
  //     },
  //     {
  //       field: "price",
  //       label: "Price",
  //       type: "currency",
  //       currency: "USD",
  //       locale: "en-US",
  //       show_cents: true,
  //       focus: false,
  //       format_type: "currency",
  //       required: false,
  //       sortable: true,
  //       cols_in_dialog: 6,
  //     },
  //     {
  //       field: "rating",
  //       label: "Reviews",
  //       type: "integer",
  //       focus: false,
  //       format_type: "rating",
  //       required: false,
  //       sortable: true,
  //     },
  //     {
  //       field: "inventoryStatus",
  //       label: "Status",
  //       type: "enum",
  //       enums: ["INSTOCK", "OUTOFSTOCK", "LOWSTOCK"],
  //       focus: false,
  //       format_type: "status",
  //       required: false,
  //       sortable: true,
  //     },
  //     {
  //       field: "quantity",
  //       label: "Quantity",
  //       type: "integer",
  //       focus: false,
  //       format_type: "string",
  //       required: false,
  //       sortable: true,
  //       cols_in_dialog: 6,
  //     },
  //   ],
  //   table_fields_order: ["code", "name", "image", "price", "category", "rating", "inventoryStatus"],
  //   dialog_fields_order: ["image", "name", "description", "category", "price", "quantity"],
  //   data_key: "id",
  //   rows: 10,
  //   rows_per_page_options: [5, 10, 25, 50, 100],
  //   operations: ["new", "edit", "delete"], // all options: 'new', 'view', 'edit', 'duplicate', 'delete' // NVEDD
  //   show_showid_button: true,
  //   show_openapi_button: true,
  //   show_refresh_button: false,
  //   auto_refresh_secs: 3,
  //   auto_refresh_options: [1, 3, 5, 10, 30, 60],
  //   new_item_template: {
  //     id: null,
  //     name: "",
  //     image: null,
  //     description: "",
  //     category: null,
  //     price: 0,
  //     quantity: 0,
  //     rating: 0,
  //     inventoryStatus: "INSTOCK",
  //   },
  //   image_path: "assets/demo/images/item",
  //   f_api_get_items_promise: undefined,
  //   f_api_save_edit_item: undefined,
  //   f_api_save_new_item: undefined,
  //   f_api_delete_single_item: undefined,
  //   f_api_delete_many_items: undefined,
  // };

  // const f_api_get_items_promise = function (props) {
  //   return new Promise(); // jdg-note: return 'promise' here, let caller resolve it.
  // };

  // const f_api_save_edit_item = function ({ items, item }) {
  //   let _items = [];
  //   return _items;
  // };

  // const f_api_save_new_item = function ({ items, item }) {
  //   let _items = [];
  //   return _items;
  // };

  // const f_api_delete_single_item = function ({ items, item }) {
  //   let _items = [];
  //   return _items;
  // };

  // const f_api_delete_many_items = function ({ items, selected_items }) {
  //   let _items = [];
  //   return _items;
  // };

  // meta_prop.f_api_get_items_promise = f_api_get_items_promise;
  // meta_prop.f_api_save_edit_item = f_api_save_edit_item;
  // meta_prop.f_api_save_new_item = f_api_save_new_item;
  // meta_prop.f_api_delete_single_item = f_api_delete_single_item;
  // meta_prop.f_api_delete_many_items = f_api_delete_many_items;

  // // const socket_io = useContext(SocketContext);

  // // useEffect(() => {
  // //   //
  // //   update_api_tokens();
  // //   //
  // //   socket_io.on("api_do_refresh", () => {
  // //     console.log("socket_io.msg = api_do_refresh");
  // //     update_api_tokens();
  // //   });
  // // // }, []);
  // // }, [socket_io]);

  // // const api_url = "";
  // // const api_url = ".";
  // // const api_url = "http://ubuntu-vm-imac19.i.dgt-bv.com:8052";
  // const api_url = window._env_.API_BASE_URL;
  // //
  // // /api/reports?start=1646033418&end=1646934419&limit=1230&ffield=token_id&fvalue=token-a71&select=_lw_rssi_0
  // const url_reports = api_url + "/api/reports";
  // //
  // const url_tokens = api_url + "/api/tokens";
  // const url_tokens_v3 = api_url + "/api/tokens";

  // const [token_table, set_token_table] = useState(null);
  // const [token_filter, set_token_filter] = useState("");
  // const [token_loading, set_token_loading] = useState(true);
  // const [token_show_id, set_token_show_id] = useState(false);
  // const [token_checkbox_show_id, set_token_checkbox_show_id] = useState([]);

  // // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/now
  // // "returns the number of milliseconds elapsed since January 1, 1970 00:00:00 UTC."
  // const [interval_time, set_interval_time] = useState(Date.now());

  // let update_interval_s = 2;

  // useEffect(() => {
  //   //
  //   update_api_tokens();
  //   //
  //   const interval = setInterval(() => {
  //     set_interval_time(Date.now());
  //     update_api_tokens();
  //   }, update_interval_s * 1000);
  //   //
  //   // return a function that is to be run at 'componentWillUnmount':
  //   return () => {
  //     clearInterval(interval); // undo 'setInterval()'
  //   };
  //   //
  //   // eslint-disable-next-line
  // }, []);

  // const click_show_api_tokens = () => {
  //   window.open(url_tokens_v3);
  // };
  // const update_api_tokens = () => {
  //   // set_token_loading(true);
  //   axios
  //     .get(url_tokens_v3, {}, { timeout: 3000 })
  //     .then((data) => {
  //       // const tokens = data.data.tokens || [];
  //       const tokens = data.data && data.data.tokens ? data.data.tokens : null;
  //       // const tokens_filtered = tokens.filter();
  //       set_token_table(tokens);
  //       set_token_loading(false);
  //     })
  //     .catch((err) => {
  //       set_token_table(null);
  //       set_token_loading(false);
  //     });
  // };
  // const token_on_checkbox_showid_change = (e) => {
  //   let selectedValue = [...token_checkbox_show_id];
  //   if (e.checked) selectedValue.push(e.value);
  //   else selectedValue.splice(selectedValue.indexOf(e.value), 1);
  //   //
  //   set_token_checkbox_show_id(selectedValue);
  //   set_token_show_id(selectedValue.indexOf("true") !== -1);
  // };
  // //
  // const token_table_header = (
  //   <div className="table-header">
  //     <h5>Tokens</h5>
  //     {/* <div className="field-checkbox">
  //           <Checkbox inputId="checkShowIDs_tokens" name="option" value="true" checked={token_checkbox_show_id.indexOf('true') !== -1} onChange={token_on_checkbox_showid_change} />
  //           <label htmlFor="checkShowIDs_tokens">show IDs</label>
  //       </div> */}
  //     {/* <Button label="/api/tokens" onClick={click_show_api_tokens} className="p-button-raised p-button-warning mr-2 mb-2" /> */}
  //     {/* <Button label="refresh" onClick={update_api_tokens} className="p-button-raised p-button-warning mr-2 mb-2" /> */}
  //     <span className="">Last API update: {date_to_nicetime(interval_time)}</span>
  //     <span className="p-input-icon-left">
  //       <i className="pi pi-search" />
  //       <InputText
  //         value={token_filter}
  //         onChange={(e) => set_token_filter(e.target.value)}
  //         placeholder="Global Search"
  //       />
  //     </span>
  //   </div>
  // );

  // const f_object_lookup_default = function ({ obj, field, dflt }) {
  //   const ret_value = f_is_object(obj) && obj.hasOwnProperty(field) ? obj[field] : dflt;
  //   return ret_value;
  // };

  // const body_template = (data, props) => {
  //   return (
  //     <>
  //       <span className="p-column-title">{props.header}</span>
  //       {f_object_lookup_default({ obj: data, field: props.field, dflt: "" })}
  //     </>
  //   );
  // };

  // const lw_freq_template = (data, props) => {
  //   // const field = props.field;
  //   // const field = "lw";
  //   // const data_obj = f_object_lookup_default({ obj: data, field: field, dflt: {} });
  //   const data_obj = data.aux && data.aux.lw ? data.aux.lw : {};
  //   //
  //   const bandwidth = data_obj.bandwidth || "undef";
  //   const spreading_factor = data_obj.spreading_factor || "undef";
  //   const data_formatted = `bw=${bandwidth},sf=${spreading_factor}`;
  //   return (
  //     <>
  //       <span className="p-column-title">{props.header}</span>
  //       {data_formatted}
  //     </>
  //   );
  // };

  // const lw_gw_template = (data, props) => {
  //   // const field = props.field;
  //   // const field = "lw";
  //   // const data_obj = f_object_lookup_default({ obj: data, field: field, dflt: {} });
  //   const data_obj = data.aux && data.aux.lw ? data.aux.lw : {};
  //   //
  //   const gw_obj_list = data_obj.gateways || [];
  //   const gw_list = gw_obj_list.map((obj) => `gw_id=${obj.gateway_id},rssi=${obj.rssi},snr=${obj.snr}`);
  //   return (
  //     <>
  //       <span className="p-column-title">{props.header}</span>
  //       {gw_list.map((obj) => {
  //         return (
  //           <>
  //             {obj} <br />
  //           </>
  //         );
  //       })}
  //     </>
  //   );
  // };

  // const uptime_template = (data, props) => {
  //   const uptime = f_object_lookup_default({ obj: data, field: props.field, dflt: "" });
  //   //
  //   // const max_age = 5 * 60;
  //   // const now_seconds = Math.floor( Date.now() / 1000 );
  //   // const age = now_seconds - data['rep_time'];
  //   // let niceUptime = "";
  //   // if (true) {
  //   // // if (age < max_age) {
  //   //   let DHMS = convert_seconds_to_DHMS(uptime);
  //   //   // niceUptime = `${DHMS.days} days, ${nr_to_padzero_string(DHMS.hours,2)}:${nr_to_padzero_string(DHMS.minutes,2)}:${nr_to_padzero_string(DHMS.seconds,2)} (h:m:s) `;
  //   //   niceUptime = `${DHMS.days} days, ${nr_to_padzero_string(DHMS.hours,2)}:${nr_to_padzero_string(DHMS.minutes,2)}:${nr_to_padzero_string(DHMS.seconds,2)}`;
  //   // } else {
  //   //   niceUptime = "(not up)";
  //   // }
  //   //
  //   const time = data.time || 0;
  //   // const time = data["cloud_time"];
  //   //
  //   let time_date_obj = new Date(time * 1000);
  //   let date_now = Date.now();
  //   let result_string = "";
  //   //
  //   const is_not_too_old = time_date_obj > date_now - max_age;
  //   if (time_date_obj > date_now - max_age) {
  //     let DHMS = convert_seconds_to_DHMS(uptime);
  //     const niceUptime = `${DHMS.days} days, ${nr_to_padzero_string(DHMS.hours, 2)}:${nr_to_padzero_string(DHMS.minutes, 2)}:${nr_to_padzero_string(DHMS.seconds, 2)}`;
  //     result_string = niceUptime;
  //   } else {
  //     result_string = "(down)";
  //   }
  //   //
  //   if (data["_uplink_tech"] === "lorawan") {
  //     result_string = "-";
  //   }
  //   //
  //   return (
  //     <>
  //       <span className="p-column-title">{props.header}</span>
  //       {result_string}
  //     </>
  //   );
  // };

  // const reptime_template = (data, props) => {
  //   // const field = props.field;
  //   // const data_field = data[field];
  //   const time = data.time || 0;
  //   const time_str = data.time_str || "";
  //   //
  //   let result_string = "";
  //   //
  //   let time_data = new Date(time * 1000);
  //   let time_now = Date.now();
  //   const is_old = time_data < time_now - max_age;
  //   //
  //   // let nice_time = date_to_nicetime(time_data);
  //   // result_string = nice_time;
  //   // //
  //   // if (false) {
  //   //   const data_hex = data["_anchor_hextime"];
  //   //   if (data_hex) {
  //   //     result_string = nice_time + " (" + time + "s, 0x" + data_hex + ")";
  //   //   } else {
  //   //     result_string = nice_time + " (" + time + "s)";
  //   //   }
  //   // }
  //   //
  //   // if (!is_not_too_old) {
  //   //   result_string = result_string + " (old)";
  //   // }
  //   //
  //   return (
  //     <>
  //       <span className="p-column-title">{props.header}</span>
  //       <span className={is_old ? "span_grey" : "span_bold_blue"}>{time_str}</span>
  //     </>
  //   );
  // };

  // const anchors_template = (data, props) => {
  //   const anchors = f_object_lookup_default({ obj: data, field: "anchors", dflt: {} });
  //   const anchor_ids = Object.keys(anchors);
  //   anchor_ids.sort(); // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
  //   //
  //   let result_string = anchor_ids.join(",");
  //   //
  //   return (
  //     <>
  //       <span className="p-column-title">{props.header}</span>
  //       {result_string}
  //     </>
  //   );
  // };

  // const sensors_template = (data, props) => {
  //   // const sensors = f_object_lookup_default({ obj: data, field: "aux", dflt: {} });
  //   const sensors = f_object_lookup_default({ obj: data, field: "sensors", dflt: {} });
  //   //
  //   let result_string = f_convert_sensors_to_display_string({ sensors });
  //   //
  //   return (
  //     <>
  //       <span className="p-column-title">{props.header}</span>
  //       {result_string}
  //     </>
  //   );
  // };

  // const location_template = (data, props) => {
  //   const presence_alist = data.location.presence_alist;
  //   const t_time = data.time || 0;
  //   const a_list = presence_alist.filter((obj) => obj.time >= t_time).map((obj) => obj.token_id);
  //   // const tokens = presence_tlist.find(obj => obj.time == time).tokens;
  //   // let token_list_str = "Tokens:" + presence_alist.join(", ");
  //   let token_list_str = "Tokens:" + a_list.join(", ");
  //   return (
  //     <>
  //       <span className="p-column-title">{props.header}</span>
  //       {token_list_str}
  //     </>
  //   );
  // };

  // const fixes_template = (data, props) => {
  //   const fixes = data.fixes;
  //   const t_time = data.time || 0;
  //   let fixes_list = [];
  //   fixes.forEach((element) => {
  //     const tid = element.token_id;
  //     const presence_alist = element.location.presence_alist;
  //     const a_list = presence_alist.filter((obj) => obj.time >= t_time).map((obj) => obj.token_id);
  //     let new_fix_str = "";
  //     if (a_list.length > 0) {
  //       new_fix_str = `T:${tid}->Tokens:` + a_list.join(",");
  //     } else {
  //       new_fix_str = `T:${tid}->Gone`;
  //     }
  //     fixes_list.push(new_fix_str);
  //   });
  //   const fixes_str = fixes_list.join("; ");
  //   return (
  //     <>
  //       <span className="p-column-title">{props.header}</span>
  //       {fixes_str}
  //     </>
  //   );
  // };

  // const [sortField, setSortField] = useState("token_id");
  // const [sortOrder, setSortOrder] = useState(1);
  // const onSort = (e) => {
  //   setSortField(e.sortField);
  //   setSortOrder(e.sortOrder);
  // };

  // // const [multiSortMeta, setMultiSortMeta] = useState(
  // //   [
  // //     {field: '_uplink_tech',  order: -1},
  // //     {field: 'token_id',     order: 1}
  // //   ]
  // // );

  // // _uplink_tech:    { type: String,   required: false},
  // // _uplink_prov:    { type: String,   required: false},
  // // _lw_framecount:  { type: Number,   required: false},
  // // _lw_timestamp:   { type: String,   required: false},
  // // _lw_rssi:        { type: Number,   required: false},
  // // _lw_lorasnr:     { type: Number,   required: false},

  // return (
  //   <div className="grid table-demo">
  //     <div className="col-12">
  //       <div className="card">
  //         {/* https://www.primefaces.org/primereact/datatable/ */}
  //         <DataTable
  //           className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
  //           header={token_table_header}
  //           value={token_table}
  //           dataKey="_id"
  //           loading={token_loading}
  //           emptyMessage="No items found."
  //           globalFilter={token_filter}
  //           // scrollable scrollHeight="600px"
  //           //
  //           sortField={sortField}
  //           sortOrder={sortOrder}
  //           onSort={onSort}
  //           //
  //           // sortMode="multiple"
  //           // multiSortMeta={multiSortMeta} onSort={(e) => setMultiSortMeta(e.multiSortMeta)}
  //           //
  //           // paginator rows={24}
  //           rowHover
  //           // showGridlines={true} // <== jdg:already set with: className="p-datatable-gridlines"
  //           size="small"
  //           // columnResizeMode="fit"
  //           autoLayout="true"
  //           resizableColumns
  //           //
  //         >
  //           {token_show_id && (
  //             <Column
  //               field="id"
  //               header="ID"
  //               sortable
  //               body={body_template}
  //             ></Column>
  //           )}
  //           <Column
  //             field="token_id"
  //             header="Token-ID"
  //             sortable
  //             body={body_template}
  //           ></Column>
  //           <Column
  //             field="display_name"
  //             header="Display Name"
  //             sortable
  //             body={body_template}
  //           ></Column>
  //           {/* <Column
  //             field="_uplink_tech"
  //             header="Uplink:Tech"
  //             sortable
  //             body={body_template}
  //           ></Column>
  //           <Column
  //             field="_uplink_prov"
  //             header="Uplink:Prov"
  //             sortable
  //             body={body_template}
  //           ></Column> */}
  //           {/* <Column
  //             field="_uplink_health"
  //             header="Uplink:Health"
  //             sortable
  //             body={body_template}
  //             // style={{ whiteSpace: "normal" }} // undo:App.scss : .p-datatable-tbody { white-space: nowrap;}
  //           ></Column> */}
  //           <Column
  //             field="time_str"
  //             header="Last update"
  //             sortable
  //             body={reptime_template}
  //           ></Column>
  //           <Column
  //             field="anchors"
  //             header="Anchors"
  //             sortable
  //             body={anchors_template}
  //             style={{ width: "40%" }}
  //           ></Column>
  //           <Column
  //             field="sensors"
  //             header="Sensors"
  //             sortable
  //             body={sensors_template}
  //             // style={{ width: "40%" }}
  //           ></Column>
  //         </DataTable>

  //         {/* - - - - - - = = = - - - - - - */}
  //         {/* <CrudTable meta_prop={meta_prop} /> */}
  //         {/* - - - - - - = = = - - - - - - */}
  //       </div>
  //     </div>
  //   </div>
  // );

  return (
    <div className="grid table-demo">
      <div className="col-12">
        <div className="card">
          <TableToken show_items_limit="all" />
        </div>
      </div>
    </div>
  );
}; // \export const OverviewPage = () => {

//-eof
