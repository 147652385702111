//= pages/Overview.js

// eslint-disable-next-line
import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import { Rating } from 'primereact/rating';
import { Button } from "primereact/button";
// import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";
// import { ProgressBar } from 'primereact/progressbar';
// import { Checkbox } from 'primereact/checkbox';

import axios from "axios"; // npm i axios --save

import momentjs from "moment"; // npm i moment --save
// const date_to_nicetime = (date) => {
//   return momentjs(date).format('YYYY-MMM-DD HH:mm:ss');
// };

import { TokenMapMatrix } from "../components/TokenMapMatrix";

import { f_max_age_ms, f_get_max_age_both } from "../config/config";

// import { CustomerService } from '../service/CustomerService';
// (is:)
// import axios from 'axios'
// export class CustomerService {
//     getCustomersMedium() {
//         return axios.get('assets/demo/data/customers-medium.json')
//             .then(res => res.data.data);
//     }
//     getCustomersLarge() {
//         return axios.get('assets/demo/data/customers-large.json')
//                 .then(res => res.data.data);
//     }
// }

// eslint-disable-next-line
const customers_medium_json = {
  data: [
    { id: 1000, name: "James Butt", country: { name: "Algeria", code: "dz" }, company: "Benton, John B Jr", date: "2015-09-13", status: "unqualified", activity: 17, representative: { name: "Ioni Bowcher", image: "ionibowcher.png" } },
    { id: 1001, name: "Josephine Darakjy", country: { name: "Egypt", code: "eg" }, company: "Chanay, Jeffrey A Esq", date: "2019-02-09", status: "proposal", activity: 0, representative: { name: "Amy Elsner", image: "amyelsner.png" } },
    { id: 1002, name: "Art Venere", country: { name: "Panama", code: "pa" }, company: "Chemel, James L Cpa", date: "2017-05-13", status: "qualified", activity: 63, representative: { name: "Asiya Javayant", image: "asiyajavayant.png" } },
  ],
};

const convert_seconds_to_DHMS = function (secs) {
  // DHMS = Days, Hourse, Minutes, Seconds
  // let seconds = parseInt(secs, 10); // in case of string
  let seconds = secs;
  let days = Math.floor(seconds / (3600 * 24));
  seconds -= days * 3600 * 24;
  let hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;
  let minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;
  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
};

function nr_to_padzero_string(nr, digits) {
  var str = "00000" + nr;
  return str.substr(-1 * digits);
}

const date_to_nicetime = (date) => {
  return momentjs(date).format("YYYY-MMM-DD HH:mm:ss");
};

let max_age = f_max_age_ms();

// eslint-disable-next-line
const anchor_matrix_seed1 = [
  { label: "1a01", label_pos: "top", anr: 1, width: "300", height: "200", posx: "0", posy: "0" },
  { label: "1a02", label_pos: "top", anr: 2, width: "300", height: "200", posx: "300", posy: "0" },
  { label: "1a03", label_pos: "top", anr: 3, width: "300", height: "200", posx: "600", posy: "0" },
  { label: "1a04", label_pos: "bot", anr: 4, width: "300", height: "200", posx: "0", posy: "200" },
  { label: "1a05", label_pos: "bot", anr: 5, width: "300", height: "200", posx: "300", posy: "200" },
  { label: "1a06", label_pos: "bot", anr: 6, width: "300", height: "200", posx: "600", posy: "200" },
];

// eslint-disable-next-line
const anchor_matrix_seed2 = [
  { label: "anchor-a66", label_pos: "top", anr: 1, width: "300", height: "200", posx: "0", posy: "0" },
  { label: "anchor-a68", label_pos: "top", anr: 2, width: "300", height: "200", posx: "300", posy: "0" },
  { label: "anchor-a69", label_pos: "top", anr: 3, width: "300", height: "200", posx: "600", posy: "0" },
  { label: "anchor-a71", label_pos: "bot", anr: 4, width: "300", height: "200", posx: "0", posy: "200" },
  { label: "anchor-a75", label_pos: "bot", anr: 5, width: "300", height: "200", posx: "300", posy: "200" },
  { label: "anchor-a82", label_pos: "bot", anr: 6, width: "300", height: "200", posx: "600", posy: "200" },
];

const c_no_id2 = "f00f";
const c_no_id = "ffff";

const filter_anchor_list_no_id = function (anchor_list) {
  //
  return anchor_list.filter((a) => {
    // filter list on 'true' condition in func
    const test_no_id = a.anchor_id === "" || a.anchor_id === "00" || a.anchor_id === c_no_id || a.anchor_id === c_no_id2;
    return !test_no_id;
  });
};

const sort_anchor_list = function (anchors) {
  //
  let date_now = Date.now();
  //
  const anchor_list = anchors.filter((a) => {
    // filter list on 'true' condition in func
    //
    if (a.anchor_id === "" || a.anchor_id === "00" || a.anchor_id === c_no_id || a.anchor_id === c_no_id2) {
      return false;
    }
    //
    const time = a.time || 0;
    let time_date_obj = new Date(time * 1000);
    const is_not_too_old = time_date_obj > date_now - max_age;
    return is_not_too_old;
    //
  });
  //
  let anchor_list_sorted = anchor_list.sort((a, b) => {
    // const a_tech = a._uplink_tech || "00";
    // const b_tech = b._uplink_tech || "00";
    const a_id = a.anchor_id || c_no_id;
    const b_id = b.anchor_id || c_no_id;
    //
    // let  comp = a_tech.localeCompare(b_tech); // return -1,0,1
    // if (comp === 0) {
    //   const comp = a_id.localeCompare(b_id);
    //   // console.log(`# at=${at} bt=${bt} comp=${comp} .`);
    // }
    // return comp;
    //
    return a_id.localeCompare(b_id);
  });
  return anchor_list_sorted;
  //
  // // { label: "1a01", label_pos: "top", anr: 1, width: "300", height: "200", posx: "0",   posy: "0"   },
  // let anchor_list_processed = anchor_list_sorted.map((an, i) => {
  //   let i_mod = i % c_anchors_per_matrix;
  //   const anchor_pos = anchor_pos_seed[i_mod];
  //   const new_an = Object.assign(an, anchor_pos);
  //   new_an.label = an.anchor_id;
  //   return new_an;
  // });
  // return anchor_list_processed;
  //
  //
}; // \const sort_anchor_list = function(anchors) {}

export const DashboardPage = () => {
  // const api_url = "";
  // const api_url = ".";
  // const api_url = "http://ubuntu-vm-imac19.i.dgt-bv.com:8052";
  const api_url = window._env_.API_BASE_URL;
  //
  const url_anchors = api_url + "/anchors";
  // const url_anchors = api_url + "/api/anchors?test=true";
  const url_tokens = api_url + "/tokens";
  // const url_timepoints = api_url + '/api/timepoints';
  // const url_maps = api_url + '/api/maps';
  // url_anchors, url_tokens, url_timepoints, url_maps

  // const [matrix_list, set_matrix_list] = useState([]);

  const [anchor_table, set_anchor_table] = useState(null);
  const [anchor_token_table, set_anchor_token_table] = useState([]);
  // const [anchor_token_table1, set_anchor_token_table1] = useState(null);
  // const [anchor_token_table2, set_anchor_token_table2] = useState(null);
  // const [anchor_filter, set_anchor_filter] = useState('');
  const [anchor_loading, set_anchor_loading] = useState(true);
  const [anchor_show_id, set_anchor_show_id] = useState(false);
  // const [anchor_checkbox_show_id, set_anchor_checkbox_show_id] = useState([]);

  const [token_table, set_token_table] = useState(null);
  const [token_filter, set_token_filter] = useState("");
  const [token_loading, set_token_loading] = useState(true);
  const [token_show_id, set_token_show_id] = useState(false);
  const [token_checkbox_show_id, set_token_checkbox_show_id] = useState([]);

  // const [timepoint_table, set_timepoint_table] = useState(null);
  // const [timepoint_filter, set_timepoint_filter] = useState('');
  // const [timepoint_loading, set_timepoint_loading] = useState(true);
  // const [timepoint_show_id, set_timepoint_show_id] = useState(false);
  // const [timepoint_checkbox_show_id, set_timepoint_checkbox_show_id] = useState([]);

  // const [map_table, set_map_table] = useState(null);
  // const [map_filter, set_map_filter] = useState('');
  // const [map_loading, set_map_loading] = useState(true);
  // const [map_show_id, set_map_show_id] = useState(false);
  // const [map_checkbox_show_id, set_map_checkbox_show_id] = useState([]);

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/now
  // "returns the number of milliseconds elapsed since January 1, 1970 00:00:00 UTC."
  const [interval_time, set_interval_time] = useState(Date.now());
  useEffect(() => {
    //
    update_api_anchors();
    update_api_tokens();
    //
    const interval = setInterval(() => {
      set_interval_time(Date.now());
      update_api_anchors();
      update_api_tokens();
    }, 1000);
    //
    // to run a func at unmount, return a func here:
    return () => {
      clearInterval(interval);
    };
    //
    // eslint-disable-next-line
  }, []);

  //   useEffect(() => {
  //     //
  //     axios.get(url_anchors, {}, {timeout: 3000})
  //     .then(data => { set_anchor_table(data.data.anchors); set_anchor_loading(false); })
  //     .catch(error => { set_anchor_table(null); set_anchor_loading(false) });
  //     //
  //     axios.get(url_tokens, {}, {timeout: 3000})
  //     .then(data => { set_token_table(data.data.tokens); set_token_loading(false); })
  //     .catch(error => { set_token_table(null); set_token_loading(false) });
  //     //
  //     axios.get(url_timepoints, {}, {timeout: 3000})
  //     .then(data => { set_timepoint_table(data.data.timepoints); set_timepoint_loading(false); })
  //     .catch(error => { set_timepoint_table(null); set_timepoint_loading(false) });
  //     //
  //     axios.get(url_maps, {}, {timeout: 3000})
  //     .then(data => { set_map_table(data.data.maps); set_map_loading(false); })
  //     .catch(error => { set_map_table(null); set_map_loading(false) });
  //     //
  // }, []);

  // =======================================================================================
  const update_api_anchors = () => {
    // set_anchor_loading(true);
    axios
      .get(url_anchors, {}, { timeout: 3000 })
      .then((data) => {
        const anchor_list = data.data.anchors || [];
        const anchor_list_no_id = filter_anchor_list_no_id(anchor_list);
        //
        set_anchor_table(anchor_list_no_id);
        set_anchor_loading(false);
        //
        // // Note: _matrix and _anr are set in TokenMe-API-Server/src/routes/anchors.js
        //
        // // https://www.w3schools.com/jsref/jsref_filter.asp  // "The filter() method creates a new array filled with elements that pass a test provided by a function."
        // let anchor_token_table_1 = anchors.filter(a_ftr => (a_ftr._matrix && a_ftr._matrix === 1) && (a_ftr._anr && a_ftr._anr >= 1 && a_ftr._anr <= 6) );
        // anchor_token_table_1 = anchor_token_table_1.map(aiter => {
        //   const add_obj = anchor_matrix_seed1.find(alookup => alookup.anr === aiter._anr);
        //   return Object.assign(aiter, add_obj);
        // });
        // set_anchor_token_table1(anchor_token_table_1);
        // //
        // let anchor_token_table_2 = anchors.filter(a_ftr => (a_ftr._matrix && a_ftr._matrix === 2) && (a_ftr._anr && a_ftr._anr >= 1 && a_ftr._anr <= 6) );
        // anchor_token_table_2 = anchor_token_table_2.map(aiter => {
        //   const add_obj = anchor_matrix_seed2.find(alookup => alookup.anr === aiter._anr);
        //   return Object.assign(aiter, add_obj);
        // });
        // set_anchor_token_table2(anchor_token_table_2);
        //
        // set_anchor_token_table1(anchor_matrix_seed1);
        // set_anchor_token_table2(anchor_matrix_seed2);
        //
        const anchor_list_sorted = sort_anchor_list(anchor_list_no_id);
        set_anchor_token_table(anchor_list_sorted);
        //
      })
      .catch((err) => {
        // set_anchor_table(null);
        set_anchor_loading(false);
        // set_anchor_token_table1(anchor_matrix_seed1);
        // set_anchor_token_table2(anchor_matrix_seed2);
        set_anchor_token_table([]);
      });
  };
  const [anchor_sort_field, set_anchor_sort_field] = useState("anchor_id");
  const [anchor_sort_order, set_anchor_sort_order] = useState(1);
  const on_anchor_sort = (e) => {
    // set_anchor_sort_field(e.anchor_sort_field);
    // set_anchor_sort_order(e.anchor_sort_order);
  };
  // eslint-disable-next-line
  const anchor_table_header = (
    <div className="table-header">
      <h5>Anchors</h5>
      {/* <span className="">Last API update: {date_to_nicetime(interval_time)}</span>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={anchor_filter} onChange={(e) => set_anchor_filter(e.target.value)} placeholder="Global Search" />
      </span> */}
    </div>
  );

  // =======================================================================================
  const update_api_tokens = () => {
    // set_anchor_loading(true);
    axios
      .get(url_tokens, {}, { timeout: 3000 })
      .then((data) => {
        const anchors = data.data.tokens || [];
        set_token_table(anchors);
        set_token_loading(false);
      })
      .catch((err) => {
        // set_token_table(null);
        set_token_loading(false);
      });
  };
  const [token_sort_field, set_token_sort_field] = useState("token_id");
  const [token_sort_order, set_token_sort_order] = useState(1);
  const on_token_sort = (e) => {
    // set_token_sort_field(e.token_sort_field);
    // set_token_sort_order(e.token_sort_order);
  };
  // eslint-disable-next-line
  const click_show_api_tokens = () => {
    window.open(url_tokens);
  };
  // eslint-disable-next-line
  const click_refresh_api_tokens = () => {
    axios
      .get(url_tokens, {}, { timeout: 3000 })
      .then((data) => {
        set_token_table(data.data.tokens);
        set_token_loading(false);
      })
      .catch((error) => {
        set_token_table(null);
        set_token_loading(false);
      });
  };
  // eslint-disable-next-line
  const token_on_checkbox_showid_change = (e) => {
    let selectedValue = [...token_checkbox_show_id];
    if (e.checked) selectedValue.push(e.value);
    else selectedValue.splice(selectedValue.indexOf(e.value), 1);
    //
    set_token_checkbox_show_id(selectedValue);
    set_token_show_id(selectedValue.indexOf("true") !== -1);
  };
  //
  const token_table_header = (
    <div className="table-header">
      <h5>Tokens</h5>
      <span className="">Last API update: {date_to_nicetime(interval_time)}</span>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={token_filter} onChange={(e) => set_token_filter(e.target.value)} placeholder="Global Search" />
      </span>
    </div>
  );

  // =======================================================================================

  const body_template = (data, props) => {
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {data[props.field]}
      </>
    );
  };

  // eslint-disable-next-line
  const uptime_template = (data, props) => {
    const uptime = data[props.field];
    const time = data["time"];
    let time_date_obj = new Date(time * 1000);
    let date_now = Date.now();
    let result_string = "";
    if (time_date_obj > date_now - max_age) {
      let DHMS = convert_seconds_to_DHMS(uptime);
      const niceUptime = `${DHMS.days} days, ${nr_to_padzero_string(DHMS.hours, 2)}:${nr_to_padzero_string(DHMS.minutes, 2)}:${nr_to_padzero_string(DHMS.seconds, 2)}`;
      result_string = niceUptime;
    } else {
      result_string = "(down)";
    }
    if (data["_uplink_tech"] === "lorawan") {
      result_string = "-";
    }
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {result_string}
      </>
    );
  };

  const reptime_template = (data, props) => {
    let result_string = "";
    //
    const time = data.time || 0;
    let time_date_obj = new Date(time * 1000);
    let date_now = Date.now();
    const is_not_too_old = time_date_obj > date_now - max_age;
    //
    let nice_time = date_to_nicetime(time_date_obj);
    result_string = nice_time;
    //
    if (false) {
      const data_hex = data["_anchor_hextime"];
      if (data_hex) {
        result_string = nice_time + " (" + time + "s, 0x" + data_hex + ")";
      } else {
        result_string = nice_time + " (" + time + "s)";
      }
    }
    //
    if (!is_not_too_old) {
      result_string = result_string + " (old)";
    }
    //
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {result_string}
      </>
    );
  };

  // eslint-disable-next-line
  const token_time_template = (data, props) => {
    const time = data[props.field];
    let time_date_obj = new Date(time * 1000);
    let result_string = "";
    let nice_time = date_to_nicetime(time_date_obj);
    result_string = nice_time;
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {result_string}
      </>
    );
  };

  // eslint-disable-next-line
  const tokenlist_template = (data, props) => {
    const presence_tlist = data[props.field];
    const time = data["time"];
    let time_date_obj = new Date(time * 1000);
    let date_now = Date.now();
    let result_string = "";
    if (time_date_obj > date_now - max_age) {
      let token_list_str = presence_tlist.join(",");
      result_string = token_list_str;
    } else {
      result_string = "-";
    }
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {result_string}
      </>
    );
  };

  // eslint-disable-next-line
  const location_template = (data, props) => {
    let anchor_list_str = "";
    //
    const time = data.time || 0;
    let time_date_obj = new Date(time * 1000);
    let date_now = Date.now();
    const is_not_too_old = time_date_obj > date_now - max_age;
    //
    if (is_not_too_old) {
      const presence_alist = data.location.presence_alist;
      const t_time = data.time || 0;
      const a_list = presence_alist.filter((obj) => obj.time >= t_time).map((obj) => obj.anchor_id);
      // const tokens = presence_tlist.find(obj => obj.time === time).tokens;
      // let anchor_list_str = "Anchors:" + presence_alist.join(", ");
      // let anchor_list_str = "Anchors:" + a_list.join(", ");
      anchor_list_str = a_list.join(", ");
    } else {
      anchor_list_str = "-";
    }
    //
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {anchor_list_str}
      </>
    );
  };

  // eslint-disable-next-line
  const fixes_template = (data, props) => {
    const fixes = data.fixes;
    const t_time = data.time;
    let fixes_list = [];
    fixes.forEach((element) => {
      const tid = element.token_id;
      const presence_alist = element.location.presence_alist;
      const a_list = presence_alist.filter((obj) => obj.time >= t_time).map((obj) => obj.anchor_id);
      let new_fix_str = "";
      if (a_list.length > 0) {
        new_fix_str = `T:${tid}->Anchors:` + a_list.join(",");
      } else {
        new_fix_str = `T:${tid}->Gone`;
      }
      fixes_list.push(new_fix_str);
    });
    const fixes_str = fixes_list.join("; ");
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        {fixes_str}
      </>
    );
  };

  // const anchor_mongoose_schema = new mongoose.Schema({
  //   anchor_id:        { type: String, required: true},
  //   time:             { type: Number,   required: true},
  //   // presence_tlist:   { type: [String], required: false},
  //   presence_tlist:   { type: [String], required: false},
  //   distance_tlist:   { type: [token_distance_mongoose_schema], required: false},
  //   map_id:           { type: String, required: true},
  //   anchor_loc:       { type: location_mongoose_schema, required: true},
  //   _raw_string:      { type: String,   required: true},
  //   _conv_string:     { type: String,   required: true},
  //   _valid_report:    { type: Boolean,  required: true},
  //   _string_version:  { type: Number,   required: true},
  //   //
  //   _token_list       : {type: [token_report_mongoose_schema] },
  //   _anr              : { type: Number},
  //   _matrix           : { type: Number},
  //   //
  //   // _anchor_seq:      { type: Number,   required: true},
  //   _anchor_seq:      { type: String,   required: true}, // TEMPORARY, need parsing Hex
  //   _anchor_time:     { type: Number,   required: true},
  //   _anchor_hextime:  { type: String,   required: true},
  //   _anchor_uptime:   { type: Number,   required: true},
  //   //
  //   _uplink_tech:    { type: String,   required: false},
  //   _uplink_prov:    { type: String,   required: false},
  //   _lw_framecount:  { type: Number,   required: false},
  //   _lw_timestamp:   { type: String,   required: false},
  //   //
  //   _lw_gateway_id_0: { type: String, required: false },
  //   _lw_eui_0:        { type: String, required: false },
  //   _lw_rssi_0:       { type: Number, required: false },
  //   _lw_snr_0:        { type: Number, required: false },
  //   //
  //   _lw_gateway_id_1: { type: String, required: false },
  //   _lw_eui_1:        { type: String, required: false },
  //   _lw_rssi_1:       { type: Number, required: false },
  //   _lw_snr_1:        { type: Number, required: false },
  //   //
  //   _lw_gateway_id_2: { type: String, required: false },
  //   _lw_eui_2:        { type: String, required: false },
  //   _lw_rssi_2:       { type: Number, required: false },
  //   _lw_snr_2:        { type: Number, required: false },
  //   //
  //   _uplink_health:  { type: String,   required: false},
  //   //
  // },

  // eslint-disable-next-line
  function hexShortStringToInt(token_id) {
    let num = parseInt(token_id, 16);
    return num;
  }

  function intTohexShortString(nr) {
    let token_id = nr.toString(16);
    if (nr < 16) {
      token_id = "0" + nr.toString(16);
    }
    return token_id;
  }

  function generate_token_list(nr_tokens) {
    //
    // const nr_tokens = 15;
    let tokens = [];
    for (let tnr = 0; tnr < nr_tokens; tnr = tnr + 1) {
      const token_id = intTohexShortString(tnr);
      tokens.push({
        token_id: token_id,
        seq_nr: tnr,
      });
    }
    return tokens;
  }
  const token_icons = generate_token_list(15);

  // // console.log("# OverviewPage: anchor_token_table=", anchor_token_table);
  // const nr_matrix = anchor_token_table ? (anchor_token_table.length % c_anchors_per_matrix) : 1;
  // // console.log("# OverviewPage: nr_matrix=", nr_matrix);
  // let matrix_slice1 = anchor_token_table.slice(0,5);
  // // console.log("# OverviewPage: matrix_slice=", matrix_slice1);
  // let matrix_slice2 = anchor_token_table.slice(6,12);
  // // console.log("# OverviewPage: matrix_slice=", matrix_slice2);

  // if (anchor_token_table) {
  //   for (let i=0; i<nr_matrix ; i=i+1) {
  //     my_matrix_list.push( anchor_token_table.slice( (i)*c_anchors_per_matrix, (i+1)*c_anchors_per_matrix-1 ) );
  //   }
  // }
  // set_matrix_list(my_matrix_list);

  const [sortField, setSortField] = useState("anchor_id");
  const [sortOrder, setSortOrder] = useState(1);
  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
  };

  const action_handler = (id, ip, cmd) => {
    //
    // console.log(`# action_handler: id=${id}, ip=${ip}, cmd=${cmd}.`);
    //
    // http://10.212.100.81:9922/hooks/tokenme-anchor-halt
    if (ip) {
      const webhook_url = `http://${ip}:9922/hooks/tokenme-anchor-${cmd}`;
      // console.log(`# action_handler: webhook_url=${webhook_url}.`);
      //
      axios.get(webhook_url);
      //
      // axios.post(url_sys_rpc, {
      //     "proc_name": `status_${cmd}`,
      // })
      // .then(response => {
      //   // console.log(response.data);
      //   const result_obj = {
      //     status: response.data.proc_output.status,
      //     result: response.data.proc_output.values.result,
      //   };
      //   let msg_detail = JSON.stringify(result_obj.result);
      //   // msg_detail = msg_detail.replace(/(?:\r\n|\r|\n)/g, '<br>');
      //   msg_detail = msg_detail.replace(/(?:,)/g, ', ');
      //   toast.current.show({ severity: 'success', summary: 'Clear Success', detail: msg_detail, life: 9123 });
      // })
      // .catch(error => {
      //   const msg_detail = JSON.stringify(error);
      //   toast.current.show({ severity: 'error',   summary: 'Clear Failed', detail: msg_detail, life: 9123 });
      // });
      //
    }
    //
  };

  const action_template = (data, props) => {
    const anchor_id = data["anchor_id"];
    const anchor_ip = data["_anchor_ip"] || "";
    return (
      <>
        <span className="p-column-title">{props.header}</span>
        <Button label="halt" onClick={() => action_handler(anchor_id, anchor_ip, "halt")} className="p-button-sm p-button-raised p-button-warning mr-2 mb-1 p-1" />
        <Button label="reboot" onClick={() => action_handler(anchor_id, anchor_ip, "reboot")} className="p-button-sm p-button-raised p-button-alert mr-2 mb-1 p-1" />
      </>
    );
  };

  return (
    <>
      <div className="grid table-demo">
        <div className="col-12">
          <div className="card">
            {/* 
          <TokenMapMatrix width="900" height="400" anchors={anchor_matrix_seed1} token_icons={token_icons} /> 
          &nbsp; &nbsp; &nbsp;
          <TokenMapMatrix width="900" height="400" anchors={anchor_matrix_seed2} token_icons={token_icons} /> 
           */}
            {/* 
          <TokenMapMatrix width="900" height="400" anchors={anchor_token_table1} /> 
          &nbsp; &nbsp; &nbsp;
          <TokenMapMatrix width="900" height="400" anchors={anchor_token_table2} /> 
           */}
            {/*
          <TokenMapMatrix anchors={anchor_token_table} />  
          */}
            {/* 
          <TokenMapMatrix map_width={c_map_width} map_height={c_map_height} matrix_cols={c_matrix_cols} matrix_rows={c_matrix_rows} anchor_list={matrix_slice1} /> 
          &nbsp; &nbsp; &nbsp;
          <TokenMapMatrix map_width={c_map_width} map_height={c_map_height} matrix_cols={c_matrix_cols} matrix_rows={c_matrix_rows} anchor_list={matrix_slice2} /> 
          */}
            <TokenMapMatrix anchors={anchor_token_table} />
          </div>
        </div>
      </div>

      <div className="grid table-demo">
        <div className="col-4 h-full">
          <div className="card">
            {/* https://www.primefaces.org/primereact/datatable/ */}
            <DataTable
              className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
              // header={anchor_table_header}
              value={anchor_table}
              dataKey="_id"
              loading={anchor_loading}
              emptyMessage="No items found."
              // globalFilter={anchor_filter}
              scrollable
              scrollHeight="200px"
              //
              // sortField={sortField} sortOrder={sortOrder} onSort={onSort}
              //
              // sortMode="multiple"
              // multiSortMeta={multiSortMeta} onSort={(e) => setMultiSortMeta(e.multiSortMeta)}
              //
              // paginator rows={24}
              rowHover
              // showGridlines={true} // <== jdg:already set with: className="p-datatable-gridlines"
              size="small"
              columnResizeMode="fit"
              // autoLayout="true"
              resizableColumns
              //
            >
              {anchor_show_id && <Column field="_id" header="ID" body={body_template}></Column>}
              <Column field="anchor_id" header="Anchor-ID" body={body_template} style={{ width: "6rem" }}></Column>
              <Column field="time" header="Latest report" body={reptime_template}></Column>
              <Column field="anchor_id" header="Actions" body={action_template} style={{ width: "8rem" }}></Column>
            </DataTable>
          </div>
        </div>

        <div className="col-8 h-full">
          <div className="card">
            {/* https://www.primefaces.org/primereact/datatable/ */}
            <DataTable
              className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
              // header={anchor_table_header}
              value={anchor_table}
              dataKey="_id"
              loading={anchor_loading}
              emptyMessage="No items found."
              // globalFilter={anchor_filter}
              scrollable
              scrollHeight="200px"
              //
              // sortField={sortField} sortOrder={sortOrder} onSort={onSort}
              //
              // sortMode="multiple"
              // multiSortMeta={multiSortMeta} onSort={(e) => setMultiSortMeta(e.multiSortMeta)}
              //
              // paginator rows={24}
              rowHover
              // showGridlines={true} // <== jdg:already set with: className="p-datatable-gridlines"
              size="small"
              // columnResizeMode="fit"
              autoLayout="true"
              resizableColumns
              //
            >
              {anchor_show_id && <Column field="_id" header="ID" body={body_template}></Column>}
              <Column field="anchor_id" header="Anchor-ID" body={body_template}></Column>
              <Column field="time" header="Latest report" body={reptime_template}></Column>
              <Column field="anchor_id" header="Actions" body={action_template}></Column>
            </DataTable>
          </div>
        </div>
      </div>

      {/* <div className="col-12">
        <div className="card">
          <DataTable 
            className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" 
            header={timepoint_table_header} 
            value={timepoint_table} dataKey="_id" 
            loading={timepoint_loading} emptyMessage="No items found." 
            globalFilter={timepoint_filter} 
            // scrollable scrollHeight="600px" 
            paginator rows={6} 
            rowHover 
            // showGridlines={true} // <== jdg:already set with: className="p-datatable-gridlines"
            columnResizeMode="fit" autoLayout="true" >
            { timepoint_show_id && <Column field="_id"       header="ID"       sortable body={body_template}></Column> }
            <Column field="time"  header="Timepoint"  sortable body={reptime_template}></Column>
            <Column field="fixes" header="Fixes"      sortable body={fixes_template}></Column>
          </DataTable>
        </div>
      </div> */}

      {/* <div className="col-12">
        <div className="card">
          <DataTable 
            className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" 
            header={map_table_header} 
            value={map_table} dataKey="_id" 
            loading={map_loading} emptyMessage="No items found." 
            globalFilter={map_filter} 
            // scrollable scrollHeight="600px" 
            paginator rows={6} 
            rowHover 
            // showGridlines={true} // <== jdg:already set with: className="p-datatable-gridlines"
            columnResizeMode="fit" autoLayout="true" >
            { map_show_id && <Column field="_id"       header="ID"       sortable body={body_template}></Column> }
            <Column field="map_id"  header="Map_id"  sortable body={body_template}></Column>
          </DataTable>
        </div>
      </div> */}
    </>
  );
}; // \export const OverviewPage = () => {

//-EOF
