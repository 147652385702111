import React, { Component } from "react";
// import proc_img from "../layout/images/TokenMe--API-v3-Data-Processing--v1.3.svg";
// import proc_img from "../layout/images/TokenMe--API-v3-Data-Processing--v1.4.svg";
import proc_img from "../layout/images/TokenMe--API-v3-Data-Processing--v1.5.svg";
import tms_img from "../layout/images/TokenMe--Cloud-Design-DTAP-TMS--v1.6.svg";

export const DesignPage = () => {
  return (
    <div className="grid table-demo">
      <div className="col-12">
        <div className="card">
          <img
            className="mr-3"
            src={proc_img}
            alt="proc_img"
          />
        </div>
        <div className="card">
          <img
            className="mr-3"
            src={tms_img}
            alt="tms_img"
          />
        </div>
      </div>
    </div>
  );
};
