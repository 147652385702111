//= ./src/pages/Maintenance.jsx

// eslint-disable-next-line

import React, { useState, useEffect, useRef } from "react";

// - - - - - - = = = - - - - - -
const mod_name = "Maintenance";
const func_name = `${mod_name}:anonymous`; // <== default, needs to be locally overwritten

export const Maintenance = (props) => {
  //
  // - - - - - - = = = - - - - - -
  // Render logic:
  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">This site is temporarily closed for maintenance.</div>
      </div>
    </div>
    // - - - - - - = = = - - - - - -
  ); // \return ()
  // - - - - - - = = = - - - - - -
  //
}; // \export const Maintenance = (props) => {}

//-eof
