//= ./src/config/config.js

// JDG-TODO: make this configurable from ENV
// let max_age = 60; // in seconds
// let max_age = 10; // d221116-0900 wens Hans
let max_age = 20; // d221116-1540 wens Hans
// let max_age_anchor = 30;
// let max_age_token = 20;
// d221206
let max_age_anchor = 120;
let max_age_token = 120;

export function f_max_age_ms() {
  return max_age * 1000; // in milliseconds
}

export function f_get_max_age_both() {
  return { max_age_anchor, max_age_token };
}

//-eof
