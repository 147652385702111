//= ./components/TokenMap.jsx

import { checkPropTypes } from 'prop-types';
import React, { Component, useState, useContext, useEffect } from 'react';

import { TokenIcon } from './TokenIcon';

// https://stackoverflow.com/questions/58704990/calculate-pixel-width-of-text-without-knowing-font-in-react-javascript
function getTextWidth(text, font) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font || getComputedStyle(document.body).font;
  return context.measureText(text).width;
}

function hexShortStringToInt(token_id) {
  let num = parseInt(token_id, 16);
  return num;
}

function intTohexShortString(nr) {
  let token_id = nr.toString(16);
  if (nr < 16) {
    token_id = '0' + nr.toString(16);
  }
  return token_id;
}

function get_position_from_seqnr(seq_nr, label_pos) {
  let seq_nr_mod = Math.floor(seq_nr % 15);
  const maxPerX = 5;
  const maxPerY = 3;
  const startX = 40;
  let startY = 50;
  if (label_pos === 'bot') {
    startY = 40;
  }
  const factorX = 55;
  const factorY = 55;
  let posX = Math.floor(seq_nr_mod % maxPerX);
  let posY = Math.floor(seq_nr_mod / maxPerX);
  let localX = posX * factorX + startX;
  let localY = posY * factorY + startY;
  return {
    x: localX,
    y: localY,
  };
}

const generate_grid = function (width_start, width_end, width_step, height_start, height_end, height_step, path_id) {
  let line = '';
  for (var i = height_start; i < height_end; i = i + height_step) {
    line = line + 'M' + width_start + ',' + i + ' L' + width_end + ',' + i + ' '; // M0,10 L300,10
  }
  for (i = width_start; i < width_end; i = i + width_step) {
    line = line + 'M' + i + ',' + height_start + ' L' + i + ',' + height_end + ' '; // M10,0 L10,150
  }
  return line;
};
// const grid_data = generate_grid(0, 300, 20, 0, 200, 20);

const grid_space = 20;

export const TokenMap = (props) => {
  // useEffect(() => {
  //   grid_data = generate_grid(0, props.width, grid_space, 0, props.height, grid_space);
  // }, []);

  // const textWidth = getTextWidth(props.label);
  // const textPosX  = Math.floor( (300/2) - textWidth) ;
  //
  // const textPosX = 110;
  // const textPosY = props.label_pos == 'top' ? 17 : 193;
  const textPosX = Math.round(props.width / 2);
  const textPosY = props.label_pos === 'top' ? 17 : 193;

  const rect_w = 44;
  const rect_h = 18;
  const rect_x = Math.round(textPosX - rect_w / 2);
  const rect_y = Math.round(textPosY - rect_h / 2) - 1;

  const grid_data = generate_grid(0, props.width, grid_space, 0, props.height, grid_space);

  // const stroke_color = "darkgrey";
  const stroke_color = 'orange';

  return (
    <svg width={props.width} height={props.height} x={props.posx} y={props.posy} viewBox="0 0 300 200">
      {/* <rect x="1" y="1" width="598" height="398" fill="white" stroke="darkgrey" strokeWidth="2" />
      <path id="gridId" fill="none" stroke="lightgrey" strokeWidth="0.4" d="" /> */}
      {/* <path fill="none" stroke="lightgrey" strokeWidth="0.4" d={grid_data} /> */}
      <rect x="1" y="1" width="298" height="199" fill="transparent" stroke={stroke_color} strokeWidth="2" />
      {/* <rect x="300" y="1"   width="299" height="199" fill="transparent" stroke="darkgrey" strokeWidth="2" />
      <rect x="600" y="1"   width="299" height="199" fill="transparent" stroke="darkgrey" strokeWidth="2" />
      <rect x="1"   y="200" width="299" height="199" fill="transparent" stroke="darkgrey" strokeWidth="2" /> 
      <rect x="300" y="200" width="299" height="199" fill="transparent" stroke="darkgrey" strokeWidth="2" />
      <rect x="600" y="200" width="299" height="199" fill="transparent" stroke="darkgrey" strokeWidth="2" /> */}
      {
        //  (props.label_pos == "top")
        //   ? <text x={textPosX}  y="17"  stroke="black" fill="black" className='mono'>{props.label}</text>
        //   : <text x={textPosX}  y="193" stroke="black" fill="black" className='mono'>{props.label}</text>
      }
      <rect x={rect_x} y={rect_y} width={rect_w} height={rect_h} rx="4" ry="4" fill="lightyellow" stroke="black" strokeWidth="1" />
      {/* <text x={textPosX} y={textPosY} stroke="black" fill="black" className="mono">
        {props.label}
      </text> */}
      {/* https://newbedev.com/how-to-place-and-center-text-in-an-svg-rectangle */}
      {/* <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">
        {props.label}
      </text> */}
      <text x={textPosX} y={textPosY} dominantBaseline="middle" textAnchor="middle" stroke="black" fill="black" className="mono">
        {props.label}
      </text>

      {/* <text x="6"   y="15"  class="mono" stroke="black" fill="black">Anchor-1</text>
      <text x="352" y="15"  class="mono" stroke="black" fill="black">Anchor-2</text>
      <text x="832" y="15"  class="mono" stroke="black" fill="black">Anchor-3</text>
      <text x="6"   y="393" class="mono" stroke="black" fill="black">Anchor-4</text>
      <text x="352" y="393" class="mono" stroke="black" fill="black">Anchor-5</text>
      <text x="832" y="393" class="mono" stroke="black" fill="black">Anchor-6</text> */}
      {/* <g id="groupTokensId" Xtransform="scale(1.0)">
        <g id="templTokenId" _class="classTokenIcon" transform="translate(0,0)">
          <circle id="circleTokenId" cx="0" cy="0" r="20" fill="transparent" />
          <text id="textTokenId" x="-8" y="5" class="mono" stroke="white" fill="white"></text>
        </g>
      </g> */}
      {props.token_icons &&
        props.token_icons.map((t_obj) => {
          const pos = get_position_from_seqnr(t_obj.seq_nr, props.label_pos);
          return <TokenIcon key={t_obj.seq_nr} posx={pos.x} posy={pos.y} token_id={t_obj.token_id} />;
        })}
    </svg>
  );
};
// export default TokenMap;

// class ApiStatus extends Component {
//   // state = {  }
//   render() {
//     return (
//       sockStatus
//       ? <li><Tag className="mr-2" icon="pi pi-check" severity="success" >API-connected</Tag></li>
//       : <li><Tag className="mr-2" icon="pi pi-exclamation-triangle" severity="warning" >API-trying</Tag></li>
//     );
//   }
// }
// export default ApiStatus;

//-EOF
